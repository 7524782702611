export const constants: Constants = {
    DashboardChildComponentNames: {
      UpcomingTasks: "UpcomingTasks",
      FamilyMembers: "FamilyMembers",
      AvailableServices: "AvailableServices",
    },
    TaskType: {
      Appointment: "Appointment",
      Assessment: "Assessment",
      Recommendation: "RECOMMENDATION",
      Education: "Education",
      TeamTask: "Team Task"
    },
    AppointmentTaskName: {
      Covid19Round1: "Vaccination (ROUND 1)",
      Covid19Round2: "Vaccination (ROUND 2)",
      Covid19Round3: 'Vaccination (ROUND 3)',
      Covid19RoundBooster: 'Vaccination (ROUND Booster)',
      Covid19RoundTwoBooster: 'Vaccination (ROUND Second Booster)',
      Covid19AdditionalDose:  'Vaccination (ROUND Additional Dose)',
      Flu: 'Flu Shot',
      PickUpFromHome: 'Pickup (Home)',
      PickUpFromAppointment: 'Pickup (Appointment)',
      DropOffToHome: 'Drop-off (Home)',
      DropOffToAppointment: 'Drop-off (Appointment)',
      Testing: 'COVID-19 TESTING',
      TestingCarePlanName: 'COVID-19 Testing On Site',
      TestingServiceName: 'Covid-19 Testing On-Site',
      Covid19: 'COVID-19',
      FluTaskName: 'Vaccination FLU',
      FluInventoryName: 'Flu',
      Covid19Round2Booster: 'Vaccination (ROUND Booster 2)',
      FluStandard: 'Flu Standard',
      Flu65: 'Flu 65+'
    },
    LocationType:{
      HOME: 'Home'
    },
    AssessmentTaskName: {
      DayOfAssessmentPart: 'Day-Of Assessment'
    },
    LocalStorageKeys: {
      ImpersonatedFamilyPatientId: 'IMPERSONATED_FAMILY_PATIENT_ID',
      ADD_FAMILY_MEMBER: 'add-family-member',
      FAMILY_MEMBER_ID: 'family-member-id',
      PROVIDER_PATIENT_SEARCH: 'provider-patient-search'
    },
    ServiceNames: {
      Covid19VaccineService: 'COVID-19 Vaccine',
      Covid19BoosterDose: 'COVID-19 Vaccine Booster 1',
      Covid19AdditionalDose: 'COVID-19 Vaccine Additional Dose',
      FluShot: 'Flu Shot',
      BoosterSecondDose: 'COVID-19 Vaccine Booster 2',
      BoosterNewDose : 'COVID-19 Vaccine Booster'
    },
    ProfileChildComponentNames: {
      ProfileHead: 'ProfileHead',
      FamilyMembers: 'FamilyMembers',
      ProfileQuickAccess: 'ProfileQuickAccess',
      ProfileCompletenessScore: 0.9,
    },
    TaskStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      SCHEDULED: 'SCHEDULED',
      COMPLETED: 'COMPLETED',
      MISSED: 'MISSED',
      READY: 'READY',
      NOTAPPLICABLE: 'Not Applicable',
    },

    currentStatus:{
      BOOKAPPOINTMENT: "BOOK-APPOINTMENT",
      VACCINATIONCOMPLETED: "VACCINATION-COMPLETED"
    },
    TaskName:{
      COVID19TESTING: 'COVID-19 Testing'
    },
    GoalStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE'
    },
    VaccineTypeNames: {
      Pfizer: 'Pfizer',
      Comirnaty: 'Comirnaty',
      Moderna: 'Moderna',
      JohnsonAndJohnson: 'Johnson&Johnson'
    },
    ServiceRequestType: {
      Homebound:'Homebound',
      Rideshare:'Rideshare',
      Ride_Request:'Ride Request',
      Flu_Shot_Care_Plan:'Flu Shot Care Plan'
    },
    ServiceStatus: {
      APPROVED:'APPROVED',
      REQUESTED:'REQUESTED',
      ALLOCATED:'ALLOCATED',
      DUPLICATE:'DUPLICATE',
      CANCELLED:'CANCELLED',
      REJECTED:'REJECTED',
      COMPLETED:'COMPLETED'
    },
    FamilyDashboardQuickaccess:{
      Completed: 'COMPLETED'
    },
    Result: {
      POSITIVE:'POSITIVE',
      NEGATIVE:'NEGATIVE',
      INVALID:'INVALID',
      RESULT_AWAITED:'RESULT AWAITED',
    },
    RecordsStatus: {
      pending:'pending',
      scheduled:'scheduled',
      missed:'missed',
      complete:'complete',
    },
    statesForConsent: {
      SouthCarolina: 'SC',
      Wyoming: 'WY',
      Indiana: 'IN'
    },
    carePlanNames: {
      ltcfCarePlan: 'LTCF Booster Care Plan',
    },
    vaccinationCovid19Dose: {
      covid19BivalentBoosterDose : 'Vaccination Covid-19 Bivalent Booster Dose',
      covid19BivalentPrimaryDose : 'Vaccination Covid-19 Bivalent Primary Dose',
    }
  };

  export interface Constants {
    DashboardChildComponentNames: {
      UpcomingTasks: string;
      FamilyMembers: string;
      AvailableServices: string;
    };
    TaskType: {
      Appointment: string;
      Assessment: string;
      Recommendation:string;
      Education: string;
      TeamTask: string;
    };
    AppointmentTaskName:{
      Covid19Round1: string;
      Covid19Round2: string;
      Covid19Round3: string;
      Covid19RoundBooster: string;
      Covid19RoundTwoBooster: string;
      Covid19AdditionalDose: string;
      Flu: string;
      PickUpFromHome: string;
      PickUpFromAppointment: string;
      DropOffToHome: string;
      DropOffToAppointment;
      Testing: string;
      TestingCarePlanName: string;
      TestingServiceName: string;
      Covid19: string;
      FluTaskName: string;
      FluInventoryName: string;
      Covid19Round2Booster: string;
      FluStandard: string;
      Flu65: string;
    };
    LocationType:{
      HOME: string;
    }
    AssessmentTaskName: {
      DayOfAssessmentPart: string;
    };
    LocalStorageKeys: {
      ImpersonatedFamilyPatientId: string;
      ADD_FAMILY_MEMBER: string;
      FAMILY_MEMBER_ID: string;
      PROVIDER_PATIENT_SEARCH: string
    };
    ServiceNames: {
      Covid19VaccineService: string;
      Covid19BoosterDose: string;
      Covid19AdditionalDose: string;
      FluShot: string;
      BoosterSecondDose: string;
      BoosterNewDose: string;
    };
    ProfileChildComponentNames: {
      ProfileHead: string;
      FamilyMembers: string;
      ProfileQuickAccess: string;
      ProfileCompletenessScore: number;
    };
    TaskStatus: {
      PENDING: string;
      ACTIVE: string;
      COMPLETED: string;
      SCHEDULED: string;
      MISSED: string;
      READY: string;
      NOTAPPLICABLE: string;
    };
    currentStatus:{
      BOOKAPPOINTMENT: string;
      VACCINATIONCOMPLETED: string;
    };
    TaskName:{
      COVID19TESTING: string
    }
    GoalStatus: {
      ACTIVE: string;
      INACTIVE: string;
    };
    VaccineTypeNames: {
      Pfizer: string;
      Comirnaty: string;
      Moderna: string;
      JohnsonAndJohnson: string;
    };
    ServiceRequestType: {
      Homebound:string;
      Rideshare:string;
      Ride_Request:string;
      Flu_Shot_Care_Plan:string;
    };
    ServiceStatus: {
      APPROVED:string;
      REQUESTED:string;
      ALLOCATED:string;
      DUPLICATE:string;
      CANCELLED:string;
      REJECTED:string;
      COMPLETED:string;
    };
    FamilyDashboardQuickaccess:{
      Completed: string;
    },
    Result: {
      POSITIVE:string;
      NEGATIVE:string;
      INVALID:string;
      RESULT_AWAITED:string;
    };
    RecordsStatus: {
      pending:string;
      scheduled:string;
      missed:string;
      complete:string;
    };
    statesForConsent: {
      SouthCarolina: string,
      Wyoming: string,
      Indiana: string,
    };
    carePlanNames: {
      ltcfCarePlan: string;
    };
    vaccinationCovid19Dose: {
      covid19BivalentBoosterDose : string,
      covid19BivalentPrimaryDose : string,
    }
  }

  export const DialogDataconstant = {
    name: 'Profile Image'
  }

  export interface DialogData {
    profileImagePath: string;
    name: string;
    patientId: string;
    username: any;
  }


  // for provider
  export interface ScheduleDialogInterface {
    cancelButtonLabel: string;
    confirmButtonLabel: string;
    dialogHeader: string;
    dialogContent?: string;
    returnCallbackMethod?: (action: number) => void;
    callbackMethod?: () => void;
  }
