import { Component } from '@angular/core';

export const ORGANIZATION_TAB_ID = 'organization-tab-id';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  host: {class: 'd-block ml-4 pl-2 h-100 app_organizations'},
})
export class OrganizationsComponent {
}
