import {Injectable} from '@angular/core';

interface Script {
  name: string;
  src: string;
  loaded: boolean;
}
const googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBFgigMgpJtToj98aLwvTCQfZnCl_RB2gI';
const googleMapsLibraryUrl = (library = '') => `${googleMapsUrl}${library ? `&libraries=${library}` : ''}`;

export const ScriptStore: Script[] = [
  {name: 'google', loaded: false, src: googleMapsLibraryUrl('drawing')},
  {name: 'googleMapsDrawing', loaded: false, src: googleMapsLibraryUrl('drawing')},
  {name: 'googleMapsGeometry', loaded: false, src: googleMapsLibraryUrl('geometry')},
  {name: 'googleMapsLocalContext', loaded: false, src: googleMapsLibraryUrl('localContext')},
  {name: 'googleMapsPlaces', loaded: false, src: googleMapsLibraryUrl('places')},
  {name: 'googleMapsVisualization', loaded: false, src: googleMapsLibraryUrl('visualization')},
];

declare var document: any;

@Injectable()
export class ScriptService {
  private scripts: any = ScriptStore;

  load(...scripts: string[]) {
    const promises: Promise<Script>[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string): Promise<Script> {
    return new Promise((resolve) => {
      const scriptIndex = this.scripts.findIndex((s) => s.name === name);

      if (scriptIndex >= 0) {
        if (this.scripts[scriptIndex].loaded) {
          this.scripts[scriptIndex].status = 'Already Loaded';
          resolve(this.scripts[scriptIndex]);
        } else {
          const script = document.createElement('script');

          script.type = 'text/javascript';
          script.src = this.scripts[scriptIndex].src;

          if (script.readyState) {
            // IE
            script.onreadystatechange = () => {
              if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                resolve(this.setScriptStore(scriptIndex));
              }
            };
          } else {
            // Others
            script.onload = () => {
              resolve(this.setScriptStore(scriptIndex));
            };
          }
          script.onerror = () => {
            resolve(this.setScriptStore(scriptIndex, false));
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    });
  }

  setScriptStore(scriptIndex, loaded = true) {
    const result = this.scripts[scriptIndex];

    result.loaded = !!loaded;
    result.status = 'Loaded';

    return result;
  }
}
