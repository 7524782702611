import {LanguageType} from './language.enum';
import {LanguageModel} from './language.model';
import data from './resources/english.json';

export class EnglishLanguageModel extends LanguageModel {
  public constructor() {
    super(data);

    this.type = LanguageType.english;
  }
}
