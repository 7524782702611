import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  private map = new Map();

  public sendData<T>(msg: T, channel: string) {
    const subject = this.getSubject<T>(channel);

    if (subject) {
      subject.next(msg);
    }
  }

  public getData<T>(channel: string): Observable<T> {
    let subject;

    // not currently registered?
    if (!this.map.has(channel)) {
      subject = new Subject<T>();
      this.map.set(channel, subject);
    } else {
      subject = this.map.get(channel);
    }

    return subject.asObservable();
  }

  public unsubscribeData<T>(channel: string) {
    const subject = this.getSubject<T>(channel);

    if (subject) {
      subject.unsubscribe();
    }
  }

  private getSubject<T>(channel: string): Subject<T> {
    let subject;

    if (this.map.has(channel)) {
      subject = this.map.get(channel);
    }

    return subject;
  }

  public unsubscribeAll() {
    this.map.clear();
  }
}
