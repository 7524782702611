import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../op-confirm-dialog/op-confirm-dialog.component';

@Component({
  selector: 'app-global-error-handler-modal',
  templateUrl: './global-error-handler-modal.component.html',
  styleUrls: ['./global-error-handler-modal.component.scss']
})
export class GlobalErrorHandlerModalComponent implements OnInit {

  public errorMessage: string[] = [];

  constructor(  
    public dialogRef: MatDialogRef<GlobalErrorHandlerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    if(this.data?.fieldErrors && this.data?.errors?.length > 0) {
      this.errorMessage = this.data.errors;
    }
  }

}
