import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-version-check-dialog',
  templateUrl: './version-check-dialog.component.html',
  styleUrls: ['./version-check-dialog.component.scss'],
})
export class VersionCheckDialogComponent {
  constructor(public dialogRef: MatDialogRef<VersionCheckDialogComponent>) {}

  onOkClick(): void {
    this.dialogRef.close({ok: true});
  }

  onNoClick(): void {
    this.dialogRef.close({});
  }
}
