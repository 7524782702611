import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {AuthToken} from '../../models/auth-token.model';
import {Observable, throwError} from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {UserSessionService} from '../user-session/user-session.service';
import {LanguageService} from '../language/language.service';
import {environment} from '../../../../environments/environment';
import {HttpUrlEncodingCodec} from './http-url-encoding-codec';
import {OperationUserSessionService} from '../user-session/operation-user-session.service';
import {NetworkService} from '../network/network.service';
import {Router} from '@angular/router';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {AssessmentsService} from '../../../modules/assessments/assessments.service';
import {DateUtility} from '../../utils/date-utility';
import { PatientDataShareService } from 'src/app/modules/patient-overview/shared/services/patient-data-share.service';

interface LoginModel {
  username: string;
  password?: string;
  smsCode?: string;
}

export interface CustomHttpError {
  status: number;
  statusText: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private token: string;
  private username: string;

  static handleError(error: HttpErrorResponse) {
    let errorMessage;

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${error.error.code}, body was: ${error.error.message}`;
    }
    console.error(errorMessage);
    // return an observable with a user-facing error message
    return throwError(error.error);
  }

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private session: UserSessionService,
    private languageService: LanguageService,
    private operationUserSessionService: OperationUserSessionService,
    private networkService: NetworkService,
    private router: Router,
    private dataBrokerService: DataBrokerService,
    private assessmentsService: AssessmentsService,
    private patientDataShareService: PatientDataShareService,
  ) {}

  public submitLogin({username, password, smsCode}: LoginModel): Observable<AuthToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let body = new HttpParams({encoder: new HttpUrlEncodingCodec()})
      .set('grant_type', 'password')
      .set('username', username)
      .set('client_id', 'public');

    if (password) body = body.set('password', password);
    if (smsCode) body = body.set('smsCode', smsCode);

    return this.http
      .post<AuthToken>(ApiService.getAuthorizationLogin(), body.toString(), {headers: headers})
      .pipe(shareReplay(), catchError(AuthorizationService.handleError));
  }

  // If sms string is empty, it will REQUEST an SMS code to the assigned number for the entered account
  public submitSmsLogin(username: string, sms: string): Observable<AuthToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    // TODO: Get the header name
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('smsCode', sms)
      .set('client_id', 'public');

    return this.http
      .post<AuthToken>(ApiService.getAuthorizationLogin(), body.toString(), {headers})
      .pipe(catchError(AuthorizationService.handleError));
  }

  public updatePassword({newPassword, oldPassword}): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.session.token.access_token,
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-Role': 'doctor',
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
    });

    const url = environment.apiUrl + `/api/accounts/${this.session.getSession().accountId}/password`;

    return this.http.post(url, {oldPassword, newPassword}, {headers});
  }

  public updatePasswordForAnotherUser(userId, body): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.session.token.access_token,
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-Role': 'doctor',
    });

    const url = environment.apiUrl + `/api/accounts/${userId}/password-without-check`;

    return this.http.post(url, body, {headers, observe: 'response'}).pipe(catchError(AuthorizationService.handleError));
  }

  // public logout(): Observable<any> {
  //   // let headers = new HttpHeaders({
  //   //   "Content-Type":"application/json",
  //   //   "Authorization":`Bearer: ${this.session.token}`,
  //   // }).set(globals.SKIP_HEADER, '');
  //
  //   this.themeService.resetTheme();
  //
  //   return this.http.delete(this.api.invalidateSession())
  //     .pipe(catchError(AuthorizationService.handleError));
  // }

  public forgotPassword(body: {user: string}): Observable<HttpResponse<any>> {
    const link = environment.apiUrl + '/api/accounts/forgot-password';

    return this.http
      .post<HttpResponse<any>>(link, body, {observe: 'response'})
  }

  public updatePasswordNew(body): Observable<HttpResponse<any>> {
    const link = environment.apiUrl + '/api/accounts/password';

    return this.http
      .post<HttpResponse<any>>(link, body, {observe: 'response'})
      .pipe(catchError(AuthorizationService.handleError));
  }

  public login(username, password): Observable<AuthToken> {
    return this.submitLogin({username, password}).pipe(
      tap((res) => {
        this.saveAuthToken(res);
      })
    );
  }

  public ssoLogin(authorizationCode: string, redirectUri: string): Observable<AuthToken> {
    return this.getSSOToken(authorizationCode, redirectUri).pipe(
      tap((res) => {
        this.saveAuthToken(res);
        this.session.isSSOLogin = true;
      })
    );
  }

  private saveAuthToken(authToken: AuthToken) {
    this.session.token = authToken;
    this.session.setIsLoggedIn(true);
    localStorage.setItem('keycloak', JSON.stringify(authToken));
  }

  public getSSOToken(authorizationCode: string, redirectUri: string): Observable<AuthToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams({encoder: new HttpUrlEncodingCodec()})
      .set('grant_type', 'authorization_code')
      .set('code', authorizationCode)
      .set('client_id', 'account')
      .set('redirect_uri', redirectUri)
      .set('user_experience', 'web');

    return this.http
      .post<AuthToken>(ApiService.getSSOTokenUrl(), body.toString(), {headers: headers})
      .pipe(shareReplay(), (err) => err);
  }

  public setParams(param) {
    this.token = param.token;
    this.username = param.username;
  }

  public getParams() {
    return {
      token: this.token,
      username: this.username,
    };
  }

  logout(navigateToLogin: boolean = true) {
    // Hey Dima -- commented out below works on covid19.compositeapps.net
    //                but not on covid19.othena.com or my local
    // const url = environment.apiUrl + '/api/accounts/logout';
    const url = '/api/accounts/logout';

    return this.networkService.postResource(url, {}).pipe(
      tap(() => {
        const isSSOLogin: boolean = this.session.isSSOLogin;
        this.session.invalidateSession();
        this.dataBrokerService.unsubscribeAll();
        this.resetWebAppResources();
        this.assessmentsService.clean();
        this.patientDataShareService.resetData();
        if (isSSOLogin) {
          this.performSSOLogout();
        } else {
          if(navigateToLogin){
            this.router.navigate(['/login']).then();
          }
        }
      })
    );
  }

  public performSSOLogout() {
    window.location.href = environment.ssoLogoutUrl + window.location.origin + '/login';
  }

  public resetWebAppResources() {
    const saveGridPersistane = localStorage.getItem('saveGridPersistane');
    const saveGridPersistaneFilter = localStorage.getItem('saveGridPersistaneFilter');

    localStorage.clear();
    localStorage.setItem('saveGridPersistane', saveGridPersistane);
    localStorage.setItem('saveGridPersistaneFilter', saveGridPersistaneFilter);
  }

    public bruteForceStatus(username): Observable<any> {
      const url = environment.apiUrl + `/api/accounts/brute-force-status/${username}`;
      return this.http.get(url);
    }

    public loginWithOutSessionUpdate(username, password, isOrgNewPatiet: boolean): Observable<AuthToken> {

      return this.submitLogin({username, password}).pipe(
        tap((res) => {
          if (isOrgNewPatiet && isOrgNewPatiet == true) {
            // Do nothing.. continue with org session
          } else {
            this.session.token = res;
            this.session.setIsLoggedIn(true);
            localStorage.setItem('keycloak', JSON.stringify(res));
          }

        })
      );
    }
}
