import { MatSnackBar } from '@angular/material/snack-bar';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, merge} from 'rxjs';
import {VersionCheckService} from './core/services/common/version-check.service';
import {environment} from '../environments/environment';
import config from '../config';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Router, RoutesRecognized} from "@angular/router";
import {SessionEmailService} from "./core/services/session/session-email.service";
import { OperationUserSessionService } from './core/services/user-session/operation-user-session.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  checkTimeout;

  TIMEOUT_VERSION_CHECK: number = config.TIMEOUT_VERSION_CHECK;

  constructor(
    private versionCheckService: VersionCheckService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionEmailService: SessionEmailService,
    private operationUserSessionService:OperationUserSessionService,
    private matSnackBar: MatSnackBar
  ) {
    this.checkTimeout = new Date().getTime();

    this.checkLocalStorageExists()
    this.lazyLoadAgGrid().then(r => {});
  }

  async lazyLoadAgGrid() {
    const { LicenseManager } = await import('ag-grid-enterprise');
    LicenseManager.setLicenseKey("CompanyName=Composite Apps Inc.,LicensedGroup=Development Team,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=3,AssetReference=AG-030262,SupportServicesEnd=18_August_2023_[v2]_MTY5MjMxMzIwMDAwMA==202dc95933c83e81b6c9e7daf6df6f15");
  }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        if (val.state.root.firstChild.queryParams.act === 'cancel' && val.state.root.queryParams.id) {
          let apptId = val.state.root.firstChild.queryParams.id;
          this.sessionEmailService.session='?act=' + val.state.root.firstChild.queryParams.act + '&id=' + apptId;
        }else if (val.state.root.firstChild.queryParams.act === 'consent' && val.state.root.queryParams.id) {
          this.sessionEmailService.session='?act=' + val.state.root.firstChild.queryParams.act;
        }else  if (val.state.root.firstChild.queryParams.act === 'assessment' && val.state.root.queryParams.id) {
          let apptId = val.state.root.firstChild.queryParams.id;
          let surveyId = val.state.root.firstChild.queryParams.surveyId;
          this.sessionEmailService.session='?act=' + val.state.root.firstChild.queryParams.act + '&id=' + apptId+'&surveyId='+surveyId;
        }
      }
    });
    const keypress$ = fromEvent(document, 'keypress');
    const mouseUp$ = fromEvent(document, 'mouseup');

    merge(keypress$, mouseUp$)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const now = new Date().getTime();
        const timeout = this.TIMEOUT_VERSION_CHECK * 60 * 1000;

        if (this.checkTimeout + timeout < now) {
          this.checkTimeout = now;

          if (environment.versionCheckURL) {
            this.versionCheckService.initVersionCheck(environment.versionCheckURL);
          }
        }
      });

    this.displayWelcomeBackMessage()
  }

  // Some devices don't have local storage, but it is required to log in and maintain login state
  // Create a temp in-memory db for them.
  checkLocalStorageExists(){
    if (!window.localStorage) {
      Object.defineProperty(window, 'localStorage', {
        _data       : {},
        setItem     : function(id, val) { return this._data[id] = String(val); },
        getItem     : function(id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined; },
        removeItem  : function(id) { return delete this._data[id]; },
        clear       : function() { return this._data = {}; }
      } as any)
    }
  }

  displayWelcomeBackMessage(){
    setTimeout(() => {
      this.operationUserSessionService.getUserSession().subscribe({
        next: (data) => {
          if(!data) return console.log('User not logged in.');
          if(data.firstName && data.lastName && !(data?.isGuest === true)){
            this.matSnackBar.open(`Welcome back, ${data.firstName} ${data.lastName}!`, '', {
              duration: 3000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ['wg-message-snack-bar']
            });
          }
        },
        error: (e) => {
          console.log('User not logged in.');
        }
      })
    }, 3000)
  }
}
