import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { PatientModel } from 'src/app/core/models/patient.model';
import { UserSessionService } from 'src/app/core/services/user-session/user-session.service';
import { PatientService } from 'src/app/modules/patients/services/patient.service';
import { constants } from '../constants';
import {
  AppointmentForClinic
} from '../../../organizations/org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';
import {IAppointmentExtended} from '../../appointments/schedule/schedule.component';

@Injectable({
  providedIn: 'root'
})
export class PatientDataShareService {
  private _patient = new BehaviorSubject<PatientModel>(null);
  private _impersonateFamilyPatient = new BehaviorSubject<PatientModel>(null);
  onParentPatientUpdated = new EventEmitter<PatientModel>();
  notifyParentOnChildDataLoaded = new EventEmitter<any>();
  onViewAppointmentDetails = new EventEmitter<any>(null);
  onForceViewAppointmentDetails = new EventEmitter<any>(null);
  showHideOnScreenLoader = new EventEmitter<boolean>();
  onFamilyAccountSwitched = new EventEmitter<PatientModel>();
  onLanguageChanged = new EventEmitter<string>();
  onConfirmedFamilyMember = new EventEmitter();
  consentSubmittedForUnConfMember = new EventEmitter<string>();
  onPatientTaskDataUpdated = new EventEmitter<any>();
  onPatientConsentSubmitted = new EventEmitter<any>();
  isRegistrationUpdateProfile=false;
  isMemberConsent=false;
  isFamilyMemberViewing=false;

  private currentPatientBehaviorSubject = new BehaviorSubject<PatientModel>(undefined);

  constructor(
    private router: Router,
    private session: UserSessionService,
    private patientService: PatientService
    ) {
    this.onParentPatientUpdated.subscribe({
      next: (patient: PatientModel) => {
        if(patient){
          this.loggedInPatient = patient;
        }
      }
    });
   }

  set loggedInPatient(value){
    this._patient.next(value);
  }

  get loggedInPatient() {
    return this._patient.getValue();
  }

  set impersonateFamilyPatient(value){
    if(value){
      localStorage.setItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId, value.id);
    }
    this._impersonateFamilyPatient.next(value);
  }

  get impersonateFamilyPatient(){
    return this._impersonateFamilyPatient.getValue();
  }

  resetData(){
    this.removeImpersonateFamilyPatient();
    this.loggedInPatient = null;
    this.impersonateFamilyPatient = null;
    this.clearCurrentPatient();
  }

  navigateToUrl(url: string){
    this.router.navigateByUrl(url).then();
  }

  navigateToDashboard(){
    this.router.navigateByUrl('/curapatient/patient/dashboard').then();
  }

  navigateToAddNewFamilyMember(){
    this.router.navigateByUrl('/curapatient/patient/add-family').then();
  }

  navigateToLogin(){
    this.router.navigateByUrl('/login').then();
  }

  navigateToLoginAndRedirectToService(){
    this.router.navigateByUrl('/login?r=ps').then();
  }

  reloadCurrentRoute(){
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
  reloadForceCancelRoute(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/curapatient/patient']);
    });
  }

  navigateToBookService(serviceIdToBook: any, serviceName: string, surveyId?: any){
    this.router.navigateByUrl('/curapatient/patient/service-centre',
      {state: {serviceId: serviceIdToBook, serviceName: serviceName, surveyId: surveyId}}).then();
  }

  navigateToConfirmFamilyMemberAssessment(serviceIdToBook: any, serviceName: string,
    patientId: string, surveyId: string, orgId: string, isConfirmFamilyMember: boolean){
    this.router.navigateByUrl('/curapatient/patient/service-centre',
      {
        state: {
          serviceName: serviceName,
          patientId: patientId,
          isConfirmFamilyMember: isConfirmFamilyMember,
          familyOrganizationId: orgId,
          surveys: {
            surveyId: surveyId,
            organizationSurveyId: serviceIdToBook
          }
        }}).then();
  }

  navigateToServiceBooking(){
    this.router.navigateByUrl('/curapatient/patient/service-centre',
      {state: { bookService: true }}).then();
  }

  navigateToPatientProfile(){
    this.router.navigateByUrl('/curapatient/patient/profile').then();
  }

  navigateToEditPatientProfile(patientId?: string, submitConsent = false, consentData?: any){
    let param = {};
    let state = {};
    if(patientId) param = {patientId: patientId};
    if(consentData && submitConsent) state = consentData;
    this.router.navigate(['/curapatient/patient/profile/edit'], {queryParams: param, state}).then();
  }
  gotoEducationCenter(){
    this.router.navigateByUrl('/curapatient/patient/education-center').then();
  }

  navigateToRescheduleAppointment(appointmentTask: any, secondaryAppointmentTask?: any,
    returnUrlIfNotDashboard?: string, selectedPatientId?: string){
    this.router.navigateByUrl('/curapatient/patient/schedule-appointment', {
        state: {
          appointmentTask: appointmentTask,
          secondaryAppointmentTask: secondaryAppointmentTask,
          isReschedule: true,
          returnUrl: returnUrlIfNotDashboard,
          selectedPatient: selectedPatientId
        }}).then();
  }

  navigateToScheduleAppointment(appointmentTask: any, secondaryAppointmentTask?: any){
    this.router.navigateByUrl('/curapatient/patient/schedule-appointment', {
        state: {
          appointmentTask: appointmentTask,
          secondaryAppointmentTask: secondaryAppointmentTask,
          isReschedule: false
        }}).then();
  }

  navigateToCompleteAssessment(assessmentId: string, taskId: string, patientId: string, completeAssessment?: boolean, consentAssessmentTaskId?: string){
    let url = `/curapatient/patient/appointment/assessment/${assessmentId}?patientId=${patientId}&taskId=${taskId}&completeAssessment=${completeAssessment}&consentAssessmentTaskId=${consentAssessmentTaskId}`;
    this.router.navigateByUrl(url).then();
  }

  navigateToViewFamilyMembers(){
    this.router.navigateByUrl('/curapatient/patient/family', {
      state: { isFamilyDetailView: true }}).then();
  }

  navigateToFamilyRegistrationServices(){
    this.router.navigateByUrl('/curapatient/patient/patient-registration/services');
  }
  navigateToAppointments(){
    this.router.navigateByUrl('/curapatient/patient/appointments');
  }

  navigateToCurrentOrganizationDashboard(){
    this.router.navigate(['/curapatient/organizations/organization']).then();
  }

  isPatientUserLoggedIn(): boolean{
    return <boolean>JSON.parse(localStorage.getItem('isUserPatient'));
  }

  removeImpersonateFamilyPatient(){
    localStorage.removeItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId);
  }

  getImpersonateFamilyPatientId(): string{
    return localStorage.getItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId);
  }

  getCurrentPatient(): Observable<PatientModel> {
    if (this.getImpersonateFamilyPatientId()) {
      if (this.impersonateFamilyPatient) {
        this.currentPatientBehaviorSubject.next(this.impersonateFamilyPatient);
        return of(this.impersonateFamilyPatient);
      }
      return this.patientService.getFamilyMemberPatient(
        this.getImpersonateFamilyPatientId()
      )
      .pipe(
        tap(patient => {
          this.currentPatientBehaviorSubject.next(patient);
        })
      );
    } else {
      if (this.loggedInPatient) {
        this.currentPatientBehaviorSubject.next(this.loggedInPatient);
        return of(this.loggedInPatient);
      }
      return this.patientService.getCurrentPatient()
      .pipe(
        tap(patient => {
          this.currentPatientBehaviorSubject.next(patient);
        })
      );
    }
  }

  getCurrentPatientBehaviour(){
    if(this.currentPatientBehaviorSubject.value === undefined){
      this.getCurrentPatient();
    }
    return this.currentPatientBehaviorSubject;
  }

  clearCurrentPatient(){
    this.currentPatientBehaviorSubject.next(undefined);
    this.loggedInPatient = undefined;
    this.impersonateFamilyPatient = undefined;
  }

  overrideCurrentPatient(patient: PatientModel){
    if(!patient) return;

    if(this.getImpersonateFamilyPatientId() === patient.id){
      this.impersonateFamilyPatient = patient;
    } else{
      this.loggedInPatient = patient;
    }
  }

  setCurrentPatientIfNotAlready(patient: PatientModel){
    if(!patient) return;

    if(this.getImpersonateFamilyPatientId() && !this.impersonateFamilyPatient &&
    this.getImpersonateFamilyPatientId() == patient.id){
      this.impersonateFamilyPatient = patient;
    } else if(!this.loggedInPatient){
      this.loggedInPatient = patient;
    }
  }

  checkAuthorization(doNotKickOutUnauthorized: boolean = false): boolean{
    if (!this.session.getIsLoggedIn() || !this.session.token ||
      !this.session.token.access_token || !this.isPatientUserLoggedIn()) {
      if(!doNotKickOutUnauthorized){
        this.navigateToLogin();
      }
      return false;
    }
    return true;
  }

  filterLanesBasedOnAppointmentTask(appointmentTaskName: string, laneArray: AppointmentForClinic[], appointment?: IAppointmentExtended): AppointmentForClinic[]{
    appointmentTaskName = appointmentTaskName.toLowerCase();
    let filteredLanes:AppointmentForClinic[] = [];
    let covidAppointmentTaskNames = [
      constants.AppointmentTaskName.Covid19Round1.toLowerCase(),
      constants.AppointmentTaskName.Covid19Round2.toLowerCase(),
      constants.AppointmentTaskName.Covid19Round3.toLowerCase(),
      constants.AppointmentTaskName.Covid19RoundBooster.toLowerCase(),
      constants.AppointmentTaskName.Testing.toLowerCase(),
      constants.AppointmentTaskName.FluTaskName.toLowerCase(),
      constants.AppointmentTaskName.Covid19RoundTwoBooster.toLowerCase(),
      constants.AppointmentTaskName.Covid19AdditionalDose.toLowerCase(),
      constants.AppointmentTaskName.Covid19Round2Booster.toLowerCase(),
    ];
    const isCovidVaccine = covidAppointmentTaskNames.includes(appointmentTaskName);
    const isFlu = appointmentTaskName?.toLowerCase() === constants.AppointmentTaskName.FluTaskName.toLowerCase();
    if(isCovidVaccine || isFlu || appointment?.inventoryName){
      laneArray.forEach(lane => {
        if(isCovidVaccine && lane && (!lane.inventoryType || lane.inventoryType?.toLowerCase().includes('covid-19'))){
          if((appointmentTaskName !== constants.AppointmentTaskName.Covid19Round2.toLowerCase() &&
            appointmentTaskName !== constants.AppointmentTaskName.Covid19Round3.toLowerCase()) ||
            !lane.inventoryName?.toLowerCase().includes(constants.VaccineTypeNames.JohnsonAndJohnson.toLowerCase())){
              filteredLanes.push(lane);
            }
        } else if(isFlu && lane?.inventoryType?.toLowerCase().includes(constants.AppointmentTaskName.FluInventoryName.toLowerCase())){
          filteredLanes.push(lane);
        } else if(appointment?.inventoryName && lane?.inventoryType?.toLowerCase().includes(appointment?.inventoryName?.toLowerCase())) {
          filteredLanes.push(lane);
        }else if(appointment?.inventoryName && lane?.inventoryName?.toLowerCase() === appointment?.inventoryName?.toLowerCase()) {
          filteredLanes.push(lane);
        }
      });
    }
    return filteredLanes;
  }

  modifyingInventoryName(inventory: any){
    if(inventory){
      const { name = '', type = '' } = inventory;

      if(name && type){
        if(type.toLowerCase().includes(constants.AppointmentTaskName.FluTaskName.toLowerCase()) || type.toLowerCase().includes(constants.AppointmentTaskName.FluInventoryName.toLowerCase())) {
          if(name.toLowerCase().includes('standard')){
            return constants.AppointmentTaskName.FluStandard;
          } else if(name.toLowerCase().includes('65+')){
            return constants.AppointmentTaskName.Flu65;
          } else {
            return name;
          }
        } else {
          return name;
        }
      }
    }
  }

  distanceCalculate(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    } else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344
      }
      if (unit == "N") {
        dist = dist * 0.8684
      }
      return dist;
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

   checkPatientProfileCompleteness(){
    if(this.loggedInPatient?.profileCompleteness < constants.ProfileChildComponentNames.ProfileCompletenessScore) {
      this.isRegistrationUpdateProfile=true;
    }
  }

  checkFamilyProfileCompleteness(currentPatient){
    if(currentPatient?.profileCompleteness < constants.ProfileChildComponentNames.ProfileCompletenessScore) {
      this.isRegistrationUpdateProfile=true;
    }
  }
}
