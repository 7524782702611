import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MasterDataModel, MetaDataModel} from '../../models/metadata.model';
import {NetworkService} from '../network/network.service';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {defaultIfEmpty} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {UserSessionService} from '../user-session/user-session.service';
import globals from '../../../../config';
import {HttpParams} from '@angular/common/http';
import {InventoryModel, ProductModel, StateRuleModel} from '../../models/inventory.model';

export enum MasterDataTypes {
  masterData = 'Master Data',
  appointmentTherapyType = 'Appointment Therapy Type',
  educationTherapyType = 'Education Therapy Type',
  medicationTherapyType = 'Medication Therapy Type',
  exerciseTherapyType = 'Exercise Therapy Type',
  foodTherapyType = 'Food Therapy Type',
  serviceType = 'Service Type',
  inventoryType = 'Inventory Type',
  protocolPrescribersType = 'State NPI',
  productType = 'Product Name',
  manufacturerName = 'Manufacturer Name',
  stateRuleEngine = 'State Rule Engine',
}

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  private masterData: MetaDataModel;
  private masterDataType: string;
  private content: any;

  public globalsTaskType = globals.TASKTYPES;

  private readonly _masterDataSelection = new BehaviorSubject<any>(null);

  readonly masterDataSelection$ = this._masterDataSelection.asObservable();

  static getAttachmentType(file): string {
    const imagePattern = /image-*/;
    const videoPattern = /video-*/;
    const documentPattern = /application-*/;

    if (file.type.match(imagePattern)) {
      return 'IMAGE';
    } else if (file.type.match(videoPattern)) {
      return 'VIDEO';
    } else if (file.type.match(documentPattern)) {
      return 'DOCUMENT';
    } else {
      return null;
    }
  }

  constructor(
    private network: NetworkService,
    private router: Router,
    private data: DataBrokerService,
    private session: UserSessionService
  ) {}

  public getAllMasterData(): Array<any> {
    const organizationId =
      this.session.sessionAccount && this.session.sessionAccount.organizationId
        ? this.session.sessionAccount.organizationId
        : null;
    let httpParams = new HttpParams();

    if (organizationId) httpParams = httpParams.set('organizationId', organizationId);

    return [
      {
        id: 0,
        type: 'Goal Observation Type',
        description: 'Goal Observation Type is a type of observation.',
        restUrl: '/api/meta/observation',
        editModalType: 'master-data-observation-type',
      },
      {
        id: 1,
        type: 'Privilege',
        description: 'Privilege is the privilege of a user.',
        restUrl: '/api/meta/privilege',
      },
      {
        id: 2,
        type: 'Role',
        description: 'Role is the role of a user.',
        restUrl: '/api/meta/role',
      },
      {
        id: 3,
        type: MasterDataTypes.serviceType,
        description: 'Service Type is a type of service.',
        restUrl: '/api/meta/service',
        editModalType: 'master-data-service-type',
      },
      {
        id: 4,
        type: 'Specialty',
        description: 'Specialty is a specialty of provider.',
        restUrl: '/api/specialties',
        params: httpParams,
        editModalType: 'master-data-specialty',
      },
      {
        id: 5,
        type: 'Task Type',
        description: 'Task Type is a type of task.',
        restUrl: '/api/meta/task',
      },
      {
        id: 6,
        type: MasterDataTypes.masterData,
        description: 'Master Data is a Master Data',
        restUrl: '/api/masterData/other',
        deleteUrl: '/api/masterData',
        editModalType: 'master-data-master-data',
      },
      {
        id: 7,
        name: 'Appointment',
        type: MasterDataTypes.appointmentTherapyType,
        description: 'Appointment Therapy Type is a type of therapy.',
        restUrl: '/api/meta/therapy',
        taskTypeId: this.globalsTaskType.APPOINTMENT,
        editModalType: 'master-data-therapy',
      },
      {
        id: 8,
        name: 'Education',
        type: MasterDataTypes.educationTherapyType,
        description: 'Education Therapy Type is a type of therapy.',
        restUrl: '/api/meta/therapy',
        taskTypeId: this.globalsTaskType.EDUCATION,
        editModalType: 'master-data-therapy',
      },
      {
        id: 9,
        name: 'Medication',
        type: MasterDataTypes.medicationTherapyType,
        description: 'Medication Therapy Type is a type of therapy.',
        restUrl: '/api/meta/therapy',
        taskTypeId: this.globalsTaskType.MEDICATION,
        editModalType: 'master-data-therapy',
      },
      {
        id: 10,
        name: 'Exercise',
        type: MasterDataTypes.exerciseTherapyType,
        description: 'Exercise Therapy Type is a type of therapy.',
        restUrl: '/api/meta/therapy',
        taskTypeId: this.globalsTaskType.EXERCISE,
        editModalType: 'master-data-therapy',
      },
      {
        id: 11,
        name: 'Food',
        type: MasterDataTypes.foodTherapyType,
        description: 'Food Therapy Type is a type of therapy.',
        restUrl: '/api/meta/therapy',
        taskTypeId: this.globalsTaskType.FOOD,
        editModalType: 'master-data-therapy',
      },
      {
        id: 12,
        type: MasterDataTypes.inventoryType,
        description: 'Inventory type name',
        restUrl: '/api/masterData/searchByType?typeName=INVENTORY',
        deleteUrl: '/api/masterData',
        subtype: 'inventory',
        editModalType: 'master-data-inventory-type',
      },
      {
        id: 13,
        name: 'State NPI',
        type: MasterDataTypes.protocolPrescribersType,
        description: 'State NPIs name',
        restUrl: '/api/stateNPIs',
        deleteUrl: '/api/stateNPIs',
        editModalType: 'master-data-protocol-prescribers-type',
      },
      {
        id: 14,
        type: MasterDataTypes.productType,
        description: MasterDataTypes.productType,
        restUrl: '/api/masterData/product',
        deleteUrl: '/api/masterData',
        subtype: 'product',
        editModalType: 'master-data-product-name',
      },
      {
        id: 15,
        type: MasterDataTypes.manufacturerName,
        description: MasterDataTypes.manufacturerName,
        restUrl: '/api/masterData/manufacturer',
        deleteUrl: '/api/masterData',
        subtype: 'manufacturer',
        editModalType: 'master-data-manufacturer-name',
      },
      {
        id: 16,
        type: MasterDataTypes.stateRuleEngine,
        description: MasterDataTypes.stateRuleEngine,
        restUrl: '/api/masterData/stateRules',
        deleteUrl: '/api/masterData',
        subtype: 'stateruleengine',
        editModalType: 'md-data-state-rule',
      },
    ];
  }

  setMasterData(data: MetaDataModel) {
    this.masterData = data;
  }

  getMasterData(): MetaDataModel {
    return this.masterData;
  }

  setMasterDataType(type: string) {
    this.masterDataType = type;
  }

  public get masterDataSelection(): any {
    return this._masterDataSelection.getValue();
  }

  public set masterDataSelection(val: any) {
    this._masterDataSelection.next(val);
  }

  getMasterDataType(): string {
    return this.masterDataType;
  }

  clearData() {
    this.masterData = null;
    this.content = null;
  }

  attachFiles(files, id) {
    const fileList = [];

    files.forEach((file) => {
      if (file.id) return false;
      const attachment = new FormData();
      attachment.append('file', file);
      attachment.append('therapyTypeId', id);
      attachment.append('attachmentType', MasterDataService.getAttachmentType(file));

      fileList.push(this.network.postResource('/api/attachments/therapy-type', attachment));
    });

    return forkJoin(fileList).pipe(defaultIfEmpty([]));
  }

  public editTherapy(therapy, id): Observable<MetaDataModel> {
    return this.network.putResource<any>(`/api/meta/therapy/${id}`, therapy);
  }

  public createTherapy(therapy): Observable<MetaDataModel> {
    return this.network.postResource<any>(ApiService.addMetaData('therapy'), therapy);
  }

  public deleteTherapy(therapyId): Observable<MetaDataModel> {
    return this.network.deleteResource<any>(`/api/meta/therapy/${therapyId}`);
  }

  public createMasterData(masterData): Observable<MasterDataModel> {
    return this.network.postResource<any>(`/api/masterData`, masterData);
  }

  public editMasterData(masterData): Observable<MasterDataModel> {
    return this.network.putResource<any>(`/api/masterData/${masterData.id}`, masterData);
  }

  public getMasterDataByType(type: string): Observable<any[]> {
    const encodedType = encodeURIComponent(type);
    const url = '/api/masterData/searchByType';
    const httpParams = new HttpParams().set('typeName', encodedType);

    return this.network.fetchResource(url, {params: httpParams});
  }

  public getInventories(removeDisabled=false): Observable<InventoryModel[]> {
    const url = `/api/masterData/inventories`;
    const params = new HttpParams().set('removeDisabledItems', removeDisabled);
    return this.network.fetchResource(url, {params});
  }

  public getProducts(): Observable<ProductModel[]> {
    const url = '/api/masterData/product';

    return this.network.fetchResource(url);
  }

  public getStateRules(): Observable<StateRuleModel[]> {
    const url = '/api/masterData/stateRules';

    return this.network.fetchResource(url);
  }

  public getMasterDataByName(name: string): Observable<any> {
    const url = `/api/masterData/searchByName?name=${name}`;

    return this.network.fetchResource(url);
  }

  public navigateToMasterData(type) {
    this.router.navigate(['/curapatient/masterdata/overview']).then(() => {
      this.setMasterDataType(type.type);
      this.masterDataSelection = type;
      localStorage.setItem('master-data-selection', JSON.stringify(type));
      this.data.publish<any>(type, 'master-data-selection');
    });
  }

  public navigateToMasterDataByName(name) {
    const masterDataType = this.getAllMasterData().find((md) => md.type === name);

    this.navigateToMasterData(masterDataType);
  }

  public deleteMasterData(masterDataId, option?: any) {
    const url = (option.deleteUrl || option.restUrl || '/api/masterData') + `/${masterDataId}`;

    return this.network.deleteResource(url, {params: option.params ? option.params : null});
  }
}
