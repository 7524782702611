import {Injectable} from '@angular/core';
import {Observable, Subject, throwError, of as observableOf} from 'rxjs';
import {NetworkService} from '../../../core/services/network/network.service';
import {catchError, map, tap} from 'rxjs/operators';
import {StoreState} from '../../../core/store/store-state';
import {ObservableStore} from '@codewithdan/observable-store';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  BranchModel,
  ExtendedOrganizationModel,
  Organization,
  OrganizationModel,
} from '../../../core/models/organization.model';
import {ExtendedClinic, OrganizationService} from '../../../core/services/organization/organization.service';
import {UserSessionService} from '../../../core/services/user-session/user-session.service';
import {
  AppointmentForClinic
} from '../org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';
import {InventoryModel} from '../org-overview/org-middle-dashboard/org-inventory/org-inventory.component';
import * as fileSaver from 'file-saver';
import {UntilDestroy} from '@ngneat/until-destroy';
import {HourlyCovidVaccinationCount} from 'src/app/shared/components/calendar/calendar.component';
import {ClinicAnalyticsInfo} from '../org-overview/org-middle-dashboard/org-clinic-list/org-clinic-list.component';
import {environment} from '../../../../environments/environment';
import {OrganizationTypes} from '../../../shared/enums/organization';
import {StoreList} from 'src/app/core/models/store-list.model';
import {EventsForClinic} from '../../../core/models/events-for-clinic';
import {PrimaryStores} from 'src/app/core/models/primary-stores.model';

export const ORGANIZATION_ID = 'organization-id';
export const ORGANIZATION_ID_REGISTER_NEW_PATIENT = 'organization-id-register-new-patient';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OrgOverviewService extends ObservableStore<StoreState> {
  private _invitationSend = new Subject<string[]>();
  public invitationSend$ = this._invitationSend.asObservable();
  private _clinicAdded = new Subject<Organization>();
  public clinicAdded$ = this._clinicAdded.asObservable();

  constructor(
    private networkService: NetworkService,
    private organizationService: OrganizationService,
    private session: UserSessionService,
    private http: HttpClient
  ) {
    super({});

    this.setState({performerOrganization: null}, 'init_performer_org');
  }

  fetchOrgOverview(orgId?: string, doNotSetInSate?: boolean): Observable<OrganizationModel> {
    const performerOrgId = this.session.sessionAccount.organizationId;
    if (!orgId) orgId = localStorage.getItem(ORGANIZATION_ID) || performerOrgId;

    return this.organizationService.fetchOrgOverview(orgId).pipe(
      map((res) => {
        if (!doNotSetInSate) {
          this.setState({performerOrganization: res}, OrgOverviewStateActions.PerformerOrganization);
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getOrgOverview(orgId?: string): Observable<OrganizationModel> {
    const {performerOrganization} = this.getState();

    if (performerOrganization) {
      return observableOf(performerOrganization);
    }

    return this.fetchOrgOverview(orgId);
  }

  // getOrgBaiscOverview(orgId?: string): Observable<OrganizationModel> {
  //   const {performerOrganization} = this.getState();
  //
  //   if (performerOrganization) {
  //     return observableOf(performerOrganization);
  //   }
  //
  //   return this.fetchOrgBasicOverview(orgId);
  // }

  clearOrgOverview() {
    this.setState({performerOrganization: null}, OrgOverviewStateActions.ClearOrgOverview);
  }

  // fetchOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
  //   return this.organizationService.getOrgProviders(orgId).pipe(
  //     map((res) => {
  //       this.setState(
  //         (prevState) => ({
  //           performerOrganization: {
  //             ...prevState.performerOrganization,
  //             performers: res,
  //           },
  //         }),
  //         OrgOverviewStateActions.AddPerformerOrgPerformers
  //       );
  //       return res;
  //     })
  //   );
  // }

  // getOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
  //   if (!orgId) orgId = localStorage.getItem(ORGANIZATION_ID) || this.session.sessionAccount.organizationId;
  //
  //   return this.fetchOrgProviders(orgId);
  // }

  exportPatients(
    organization: ExtendedOrganizationModel,
    {
      ask = null,
      orderType = null,
      name = null,
      carePlanStatus = null,
      occupation = null,
      priority = null,
      ageGroups = '',
      ethnicity = '',
      postal_code = '',
      races = '',
      patientCustomIdentifiers = '',
      gender = '',
      eventId = '',
      clinicId = '',
    } = {}
  ): Observable<any> {
    const url = `/api/organizations/${organization.id}/patients/csv/download`;
    let httpParams = new HttpParams();

    if (ask) httpParams = httpParams.set('ask', ask);
    if (orderType) httpParams = httpParams.set('orderType', orderType);
    if (name) httpParams = httpParams.set('name', name);
    if (carePlanStatus) httpParams = httpParams.set('carePlanStatus', carePlanStatus);
    if (occupation) httpParams = httpParams.set('occupation', occupation);
    if (priority) httpParams = httpParams.set('priority', priority);
    if (ageGroups) httpParams = httpParams.set('ageGroups', ageGroups);
    if (ethnicity) httpParams = httpParams.set('ethnicity', ethnicity);
    if (postal_code) httpParams = httpParams.set('postal_code', postal_code);
    if (races) httpParams = httpParams.set('races', races);
    if (patientCustomIdentifiers) httpParams = httpParams.set('patientCustomIdentifiers', patientCustomIdentifiers);
    if (gender) httpParams = httpParams.set('gender', gender);
    if (eventId) httpParams = httpParams.set('eventId', eventId);
    if (clinicId) httpParams = httpParams.set('clinicId', clinicId);

    return this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'});
  }

  exportPGVaccinationsPatients(
    organization: ExtendedOrganizationModel,
    {
      name = null,
      ethnicity = '',
      postal_code = '',
      races = '',
      gender = '',
      eventId = '',
      clinicId = '',
    } = {}
  ): Observable<any> {
    const url = `/api/organizations/${organization.id}/patients/csv/export`;
    let httpParams = new HttpParams();

    if (name) httpParams = httpParams.set('searchString', name);
    if (ethnicity) httpParams = httpParams.set('ethnicity', ethnicity);
    if (postal_code) httpParams = httpParams.set('postal_code', postal_code);
    if (races) httpParams = httpParams.set('races', races);
    if (gender) httpParams = httpParams.set('gender', gender);
    if (eventId) httpParams = httpParams.set('eventId', eventId);
    if (clinicId) httpParams = httpParams.set('clinicId', clinicId);

    return this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'});
  }

  exportPatientTemplateForEvent(eventId: string): Observable<any> {
    const url = `/api/organizations/${eventId}/patient-per-event-template/download`;
    return this.networkService.fetchResource(url, { responseType: 'blob'});
  }

  exportHomeBound(orgId: string, {pending, approved, rejected}) {
    const url = `/api/organizations/${orgId}/housebound/csv/download`;
    let httpParams = new HttpParams();

    if (pending) httpParams = httpParams.set('pending', pending);
    if (approved) httpParams = httpParams.set('approved', approved);
    if (rejected) httpParams = httpParams.set('rejected', rejected);

    return this.networkService
      .fetchResource<BlobPart>(url, {params: httpParams, responseType: 'blob'})
      .pipe(
        map((file) => {
          const blob = new Blob([file], {type: 'text/csv'});
          fileSaver.saveAs(blob, `housebound.csv`);
        })
      );
  }

  exportVaccination(startDate: any, endDate: any, org: any) {
    const url = `/api/organizations/${org.id}/vaccinations/excel/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      fileSaver.saveAs(blob, `${org.name}.csv`);
    });
  }

  exportAppointment(startDate: any, endDate: any, org: any) {
    const url = `/api/organizations/${org.id}/appointments/excel/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      fileSaver.saveAs(blob, `${org.name}_appointments.csv`);
    });
  }

  exportPatientsRecords(exportDate: any, org: any, noEncounterId: any, eventId: any, clinicLaneIds: any) {
    const url = `/api/organizations/${org.id}/patient-export`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('exportDate', exportDate);
    httpParams = httpParams.append('noEncounterId', noEncounterId);
    if (eventId) httpParams = httpParams.append('eventId', eventId);
    if (clinicLaneIds) httpParams = httpParams.append('clinicLaneIds', clinicLaneIds);

    //console.log("noEncounterId : "+noEncounterId);
    //console.log("NotSumbitedWalgreens EventId : "+eventId);
    //console.log("NotSumbitedWalgreens clinicLaneIds : "+clinicLaneIds);
    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      const exportDateFormatted = exportDate.replace(/\//g, '_');
      fileSaver.saveAs(blob, `patients_${org.employerIdNumber}_${exportDateFormatted}.xlsx`);
    });
  }

  patientVarExport(org: any, patientName: any, patientId: any, dateStr: any, taskid: any) {
    const url = `/api/var/${org.id}/${patientId}/patient/pdf/download`;
    const patientNameBase = patientName.replace('[^a-zA-Z ]', '_');
    let httpParams = new HttpParams();
    httpParams = httpParams.append('date', dateStr);
    if (taskid) httpParams = httpParams.append('taskdId', taskid);

    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/pdf'});
      const exportDateFormatted = dateStr.replace(/\//g, '_');
      fileSaver.saveAs(blob, `${patientNameBase}_var_${exportDateFormatted}.pdf`);
    });
  }

  clinicVarsExportPromise(org: any, dateStr: any, startHour: any, endHour: any, type: any, eventId?: string) {
    const url = `/api/var/${org.id}/patients-for-clinic/pdf/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('date', dateStr);
    httpParams = httpParams.append('startHour', startHour);
    httpParams = httpParams.append('endHour', endHour);
    httpParams = httpParams.append('type', type);
    if (eventId) {
      httpParams = httpParams.append('event_id', eventId);
    }
    return this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'});
  }

  exportFailedRows(uploadLink: string, uploadName: string) {
    const url = uploadLink + `/download`;

    this.networkService.fetchResource(url, {responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'text/csv'});
      fileSaver.saveAs(blob, `${uploadName}-failed.csv`);
    });
  }

  addedClinic(clinic?: Organization) {
    this._clinicAdded.next(clinic);
  }

  getAppointmentsForClinic(orgId) {
    const url = `/api/organizations/${orgId}/appointments-for-clinic`;

    return this.networkService.fetchResource<AppointmentForClinic[]>(url);
  }

  getClinicAppointments(orgId) {
    const url = `/api/organizations/${orgId}/clinic-appointments`;
    // const url = `/api/organizations/organizationublicClinics/${orgId}`;

    return this.networkService.fetchResource<AppointmentForClinic[]>(url);
  }

  getClinicAppointmentsByDateRange(orgId, startDate, endDate) {
    const url = `/api/organizations/${orgId}/clinic-appointments`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);

    if (endDate) {
      httpParams = httpParams.append('endDate', endDate);
    }

    return this.networkService.fetchResource<AppointmentForClinic[]>(url, {params: httpParams});
  }

  getAllHosts(orgId, startDate) {
    const url = `/api/organizations/${orgId}/community-group/events`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('date', startDate);

    return this.networkService.fetchResource<Organization[]>(url, {params: httpParams});
  }

  getAllActiveClinicAppointment(orgId) {
    const url = `/api/organizations/clinic-patient-groups/${orgId}/clinic-appointment`;
    return this.networkService.fetchResource<AppointmentForClinic[]>(url);
  }

  getAllActiveCommunityAppointment(orgId) {
    const url = `/api/organizations/clinic-patient-groups/${orgId}/community-appointment`;
    return this.networkService.fetchResource<AppointmentForClinic[]>(url);
  }

  getAllCommunityClinicAppointments(parentOrgId: string, startDate?: string, endDate?: string) {
    const url = `/api/organizations/clinic-patient-groups/${parentOrgId}/all`;
    let httpParams = new HttpParams();
    if (startDate) {
      httpParams = httpParams.append('start-date', startDate);
    }

    if (endDate) {
      httpParams = httpParams.append('end-date', endDate);
    }
    return this.networkService.fetchResource<AppointmentForClinic[]>(url, {params: httpParams});
  }

  /*getAllMobileVaccineServiceClinicStats(startDate: string, endDate: string) {
    const url = `/api/organizations/clinics-vaccination-status/with-date-range`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    return this.networkService.fetchResource<AppointmentForClinic[]>(url, {params: httpParams});
  }*/

  getAllMobileVaccineServiceClinicStatsByOrg(startDate: string, endDate: string, parentOrgId: string) {
    const url = `/api/organizations/clinics-vaccination-status/with-date-range/${parentOrgId}`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    return this.networkService.fetchResource<AppointmentForClinic[]>(url, {params: httpParams});
  }

  scheduleClinicAppointment(body: any, clinicId?: string, isLTCFDateChange?: any, changedDate?: any) {
    let httpParams = new HttpParams();
    if (isLTCFDateChange) httpParams = httpParams.append('isLTCFDateChange', isLTCFDateChange);
    if (changedDate) httpParams = httpParams.append('changedDate', changedDate);
    const url = `/api/organizations/${clinicId ? clinicId : environment.defaultPODClinicId}/clinic-appointment`;
    return this.networkService.putResource(url, body, {params: httpParams});
  }

  /*getClinicStats(clinicId: string, startDate: string, endDate: string) {
    const url = `/api/organizations/${clinicId}/clinic-appointments`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    return this.networkService.fetchResource<any>(url, {params: httpParams});
  }*/

  createOrganization(body: any) {
    const url = `/api/organizations`;
    return this.networkService.postResource<any>(url, body);
  }

  changeOrganizationApprovalStatus(orgId: any, body: any) {
    const url = `/api/organizations/${orgId}/change-status`;
    return this.networkService.putResource(url, body);
  }

  getClinicAnalytics(orgId, startDate) {
    const url = `/api/organizations/${orgId}/clinics-analytics`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);

    return this.networkService.fetchResource<ClinicAnalyticsInfo[]>(url, {params: httpParams});
  }

  getBranches(orgId: string, params?: { size?: number; page?: number; types?: string | string[] }): Observable<any[]> {
    const url = `/api/organizations/${orgId}/branches`;
    let httpParams = new HttpParams();

    if (params) {
      const {size = -1, page = -1, types = ''} = params;

      if (size) httpParams = httpParams.append('size', size.toString());
      if (page >= 0) httpParams = httpParams.append('page', page.toString());
      if (types) {
        if (Array.isArray(types)) {
          (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
        } else {
          const type = types as string;

          httpParams = httpParams.append('type', type);
        }
      }
    }

    return this.networkService.fetchResource<BranchModel[]>(url, { params: httpParams })
      .pipe(
        map(branches => {
          // Branch grouped by same appointment dates, if appointments are different for same branch, create new branch of same branch with only new apt.
          const branchesByStartDate: { [date: string]: BranchModel } = {}
          branches.forEach(branch => {
            branch?.clinicAppointments?.forEach(appointment => {
              const key = `${appointment.startDate}-${appointment.startDate}`;  // Group branch based on start and end date.
              branchesByStartDate[key] ||= { ...branch, clinicAppointments: [] } // Create branch object if it already has not been created.
              branchesByStartDate[key].clinicAppointments.push(appointment) // Add current appointment to the branch apt group.
            })
          })
          return Object.values(branchesByStartDate); // return "branches" separated by appointment groupings.
        })
      );
  }

  getBranchesByClinicType(orgId: string, params?: { size?: number; page?: number; types?: string | string[]; clinicType?: string }): Observable<any[]> {
    const url = `/api/organizations/${orgId}/branches`;
    let httpParams = new HttpParams();

    if (params) {
      const {size = -1, page = -1, types = '', clinicType} = params;

      if (clinicType) httpParams = httpParams.append('clinicType', clinicType);
      if (size) httpParams = httpParams.append('size', size.toString());
      if (page >= 0) httpParams = httpParams.append('page', page.toString());
      if (types) {
        if (Array.isArray(types)) {
          (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
        } else {
          const type = types as string;

          httpParams = httpParams.append('type', type);
        }
      }
    }

    return this.networkService.fetchResource<BranchModel[]>(url, {params: httpParams});
  }

  getBranchesByLocationNumber(
    orgId: string,
    params: { size?: number; page?: number; types?: string; name?: string, orderType?: string, asc?: boolean }
  ): Observable<{ totalOrgsCount: number; organizationList: Organization[] }> {
    const url = `/api/organizations/${orgId}/branches/page`;
    let httpParams = new HttpParams()

    if (params) {
      const {size = 20, page = 0, types = '', name = '', orderType = '', asc} = params;

      if (size) httpParams = httpParams.append('size', size.toString());
      if (page >= 0) httpParams = httpParams.append('page', page.toString());
      if (types) {
        if (types.split(',').length > 1) {
          types.split(',').forEach((t) => (httpParams = httpParams.append('type', t)));
        } else {
          const type = types as string;

          httpParams = httpParams.append('type', type);
        }
      }
      if (name) httpParams = httpParams.append('name', name);
      if (orderType) httpParams = httpParams.append('orderType', orderType);
      if(asc.toString()) httpParams = httpParams.append('asc', asc.toString());
    }
    let locationNumber = this.session.sessionAccount.locationNumber;
    let locationType = this.session.sessionAccount.locationType;
    if (locationNumber) httpParams = httpParams.append('locationNumber', locationNumber);
    if (locationType) httpParams = httpParams.append('locationType', locationType);

    return this.networkService.fetchResource(url, {params: httpParams});
  }

  getAllMobileVaccineServicePodTypeClinics(parentOrgId?: string) {
    const url = `/api/organizations/clinics/pod-type/mobile-vaccine-service`;
    let httpParams = new HttpParams();
    if (parentOrgId) {
      httpParams = httpParams.append('parentOrgId', parentOrgId);
    }
    return this.networkService.fetchResource<any>(url, {params: httpParams});
  }

  // getClinicIds(date): Observable<any> {
  //   const url = `/api/organizations/${date}/getClinicIds`;
  //   return this.networkService.fetchResource<any>(url);
  // }

  getHourlyCovidVaccinationCount(orgId, startDate, endDate): Observable<HourlyCovidVaccinationCount[]> {
    // let sample: HourlyCovidVaccinationCount []
    // = [{start:startDate, end: moment(startDate).add(1, "h").toDate(), meta: {
    //   scheduled: 5, completed:5, capacity: 20}},
    //   {start:moment(startDate).add(1, "h").toDate(), end: moment(startDate).add(2, "h").toDate(), meta: {
    //     scheduled: 10, completed:10, capacity: 40}}];
    // return sample;
    const url = `/api/organizations/${orgId}/hourly-clinic-counts/${startDate}/${endDate}`;
    return this.networkService.fetchResource<HourlyCovidVaccinationCount[]>(url);
  }

  getQueues(organizationId: string): Observable<any[]> {
    const url = `/api/organizations/${organizationId}/queues`;

    return this.networkService.fetchResource(url);
  }

  public getOrgClinics(orgId): Observable<any[]> {
    const url = `/api/organizations/parent-clinics/${orgId}`;
    return this.networkService.fetchResource(url);
  }

  getInventories(clinicId: string): Observable<InventoryModel[]> {
    const url = `/api/inventories/byOrganization/${clinicId}`;

    return this.networkService.fetchResource(url);
  }

  getPatientsDepartmentsList(orgId: string): Observable<string[]> {
    const url = `/api/organizations/${orgId}/patients-departments`;

    return this.networkService.fetchResource(url);
  }

  getOrganizationCounts(orgId: string): Observable<any> {
    const url = `/api/organizations/${orgId}/status-aggregation`;
    return this.networkService.fetchResource(url);
  }

  getOrganizationOverviewCounts(orgId: string): Observable<any> {
    const url = `/api/organizations/${orgId}/overview-card-analytics`;
    return this.networkService.fetchResource(url);
  }

  updateCommunityGroupClinic(organizationId: any, newClinicId: any) {
    const url = `/api/organizations/${organizationId}/update-clinicId?clinic_id=${newClinicId}`;
    return this.networkService.putResource(url, {});
  }

  // updateCommunityGroupVaccineLot(organizationId: any, vaccineLot: string) {
  //   const url = `/api/organizations/${organizationId}/update-vaccineLot?vaccineLot=${vaccineLot}`;
  //   return this.networkService.putResource(url, {}, {responseType: 'blob'});
  // }

  updateCommunityVaccineDetails(
    organizationId: any,
    toggle: string,
    laneId: string,
    vaccineLot?: string,
    vaccineType?: string,
    clinicId?: string
  ) {
    const url = `/api/organizations/${organizationId}/clinic-vaccine-info/${laneId}`;
    let urlParams: string;
    switch (toggle) {
      case 'update':
        urlParams = `vaccineLot=${vaccineLot}&vaccineType=${vaccineType}&clinicId=${clinicId}`;
        break;
      case 'remove':
        urlParams = `remove=true`;
        break;
    }
    const requestUrl = url + '?' + urlParams;

    return this.networkService.putResource(requestUrl, {});
  }

  updateCommunityClinicTeam(organizationId: string, laneId: string, clinicId: string) {
    const url = `/api/organizations/${organizationId}/clinic-vaccine-info/${laneId}?clinicId=${clinicId}`;
    return this.networkService.putResource(url, {});
  }

  // updateCommunityGroupVaccineType(organizationId: any, vaccineType: string) {
  //   const url = `/api/organizations/${organizationId}/update-vaccineLot?vaccineType=${vaccineType}`;
  //   return this.networkService.putResource(url, {}, {responseType: 'blob'});
  // }

  handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(() => errMessage);
    }
    return throwError(() => error || 'Server error');
  }

  uploadCommunityGroupImages(organizationId: any, images: any[]) {
    const url = `/api/attachments/organization/${organizationId}`;
    return this.networkService.postResource(url, images, {responseType: 'text'});
  }

  getCommunityGroupImages(organizationId: any) {
    const url = `/api/attachments/organization/${organizationId}`;
    return this.networkService.fetchResource(url);
  }

  public extendOrganizationOverview(selectedClinicIds: string[], parentId: string, stateForFilter: string, params): Observable<ExtendedClinic[]> {
    return this.getBranchesByClinicType(parentId, params).pipe(
      map((res) => {
        const openPodClinic = {name: 'Open POD', type: 'openPod'} as BranchModel;
        const clinics = res.filter((b) => b.type === OrganizationTypes.Clinic && b.address?.state && stateForFilter == b.address.state && b.podType != 'Mobile Vaccine Service');
        const extendedClinics: ExtendedClinic[] = clinics.map((c) => {
          const extendedClinic = new ExtendedClinic(c);

          if (selectedClinicIds.includes(extendedClinic.id)) {
            extendedClinic.selected = true;
          }
          return extendedClinic;
        });

        extendedClinics.unshift(new ExtendedClinic(openPodClinic));

        return extendedClinics;
      })
    );
  }

  changePatientStatus(patientId: string, newStatus: string): Observable<any> {
    const url = `/api/patient-requests/${patientId}/status?status=${newStatus}`;
    return this.networkService.putResource<any>(url, {});
  }

  getOrgGroupedStats(orgId: string): Observable<any> {
    const url = `/api/patient-requests/organization/${orgId}/grouped-stats`;
    return this.networkService.fetchResource<any>(url);
  }

  // getOrganizationOverview(selectedClinicIds: string[], parentId): Observable<ExtendedClinic[]> {
  //   return this.organizationService.getOrganizationOverview(parentId).pipe(
  //     map((res) => {
  //       const openPodClinic = {name: 'Open POD', type: 'openPod'} as BranchModel;
  //       const clinics = res.branches.content.filter((b) => b.type === 'CLINIC');
  //       const extendedClinics: ExtendedClinic[] = clinics.map((c) => {
  //         const extendedClinic = new ExtendedClinic(c);
  //
  //         if (selectedClinicIds.includes(extendedClinic.id)) {
  //           extendedClinic.selected = true;
  //         }
  //         return extendedClinic;
  //       });
  //
  //       extendedClinics.unshift(new ExtendedClinic(openPodClinic));
  //
  //       return extendedClinics;
  //     })
  //   );
  // }

  getInHomeVaccinationRequests(requestype: string, orgId, startDate, endDate, status: string): Observable<any> {
    const url = `/api/patient-requests/organization/${orgId}`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('requestType', requestype);
    httpParams = httpParams.append('dateFrom', startDate);
    if (endDate) {
      httpParams = httpParams.append('dateTo', endDate);
    }
    httpParams = httpParams.append('status', status);
    return this.networkService.fetchResource<any>(url, {params: httpParams});
  }

  exportPatientRideRequests(orgId, patientIds: string[]): Observable<any> {
    const url = `/api/patient-requests/organization/${orgId}/csv/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('patientRequestIds', patientIds.join(','));

    return this.networkService.fetchResource<any>(url, {params: httpParams, responseType: 'text/csv'});
  }

  exportPatientRequestEnrollments(orgId, patientIds: string[]): Observable<any> {
    const url = `/api/patient-requests/organization/${orgId}/csv/download/enrollments`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('patientRequestIds', patientIds.join(','));

    return this.networkService.fetchResource<any>(url, {params: httpParams, responseType: 'text/csv'});
  }

  changeHomeBoungPatientStatus(patientRequestId: string, patient) {
    const url = `/api/patient-requests/${patientRequestId}`;
    return this.networkService.putResource<any>(url, patient);
  }

  getClinicDetails(date: string, vaccineType: string, lotNumber: string) {
    const url = `/api/organizations/clinicsLaneCheck`;
    let params = new HttpParams();
    params = params.append('date', date);
    params = params.append('VaccineType', vaccineType);
    params = params.append('lotNumber', lotNumber);
    return this.networkService.fetchResource<any>(url, {params: params});
  }

  exportGroupsIncentives(id: string, {startDate, endDate}, org: any) {
    const url = `/api/organizations/${id}/incentive/csv/download`;
    let httpParams = new HttpParams();

    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    httpParams = httpParams.append('type', 'COMMUNITYGROUP');

    return this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).pipe(
      tap((file: any) => {
        const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
        fileSaver.saveAs(blob, `${org.name}_incentive.csv`);
      })
    );
  }

  sendRejectEmailHomeBound(patientId: string, patientBody) {
    const url = `/api/patient-requests/${patientId}/homebound-request-status/send-email`;
    const defaultHeaders = {
      Authorization: 'Bearer ' + this.session.token.access_token,
    };
    const mergedHeaders = {...defaultHeaders};
    return this.http
      .post<any>(environment.apiUrl + url, patientBody, {headers: new HttpHeaders(mergedHeaders)})
      .pipe(catchError(NetworkService.handleError));
  }

  sendConsentLink(patientIds: string[]) {
    const url = `/api/consents/email`;
    return this.networkService.postResource<any>(url, patientIds);
  }

  submitPatientConsent(orgId: string, body: any) {
    const url = `/api/consents/${orgId}/submit-consent`;
    return this.networkService.postResource<any>(url, body);
  }

  public cancelClinicLanes(clinicId, appointments): Observable<any> {
    let url = `/api/organizations/${clinicId}/cancel-clinic-appointments`;
    return this.networkService.putResource(url, appointments);
  }

  getEventsForClinic(orgId: string): Observable<EventsForClinic[]> {
    const url = `/api/organizations/${orgId}/events-for-clinic`;

    return this.networkService.fetchResource(url);
  }

  exportMVSVaccination(startDate: any, endDate: any, org: any, eventId: any, clinicLaneIds: any) {
    const url = `/api/organizations/${org.id}/vaccinations/excel/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    if (eventId) httpParams = httpParams.append('eventId', eventId);
    if (clinicLaneIds) httpParams = httpParams.append('clinicLaneIds', clinicLaneIds);

    //console.log("MVSVaccination EventId : "+eventId);
    //console.log("MVSVaccination clinicLaneIds : "+clinicLaneIds);
    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      fileSaver.saveAs(blob, `${org.name}.csv`);
    });
  }

  exportMVSAppointment(startDate: any, endDate: any, org: any, eventId: any, clinicLaneIds: any) {
    const url = `/api/organizations/${org.id}/appointments/excel/download`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    if (eventId) httpParams = httpParams.append('eventId', eventId);
    if (clinicLaneIds) httpParams = httpParams.append('clinicLaneIds', clinicLaneIds);

    //console.log("MVSAppointment EventId : "+eventId);
    //console.log("MVSAppointment clinicLaneIds : "+clinicLaneIds);
    this.networkService.fetchResource(url, {params: httpParams, responseType: 'blob'}).subscribe((file: any) => {
      const blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      fileSaver.saveAs(blob, `${org.name}_appointments.csv`);
    });
  }

  removePatientRequest(patientRequestId: string) {
    const url = `/api/patient-requests/${patientRequestId}`;
    return this.networkService.deleteResource<any>(url);
  }

  getStoreHierarchyData(orgId: string, selectedDay, clinicType): Observable<StoreList[]> {
    let httpParams = new HttpParams();
    if (selectedDay) httpParams = httpParams.set('startDate', selectedDay);
    if (clinicType) httpParams = httpParams.set('clinicType', clinicType);
    const url = `/api/organizations/${orgId}/storeHierarchy`;
    return this.networkService.fetchResource(url, {params: httpParams});
  }

  fetchStores(orgId: string, val, type, locationNumbers, locationType) {
    const url = `/api/organizations/${orgId}/searchOrganizations`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchString', val);
    httpParams = httpParams.append('type', type);
    if (locationType) httpParams = httpParams.append('locationType', locationType);
    if (locationNumbers) httpParams = httpParams.append('locationNumbers', locationNumbers);

    return this.networkService.fetchResource<PrimaryStores>(url, {params: httpParams});
  }

  fetchOrgTimeZone(orgId: string) {
    const url = `/api/organizations/${orgId}/getOrganizationTimezone`;
    return this.networkService.fetchResource<any>(url);
  }

  getStateNpiData(state:string , zipCode:number){
    const url = `/api/stateNPIs/getNPIByStateAndZipcode?state=${state}&zipcode=${zipCode}`;
    return this.networkService.fetchResource<any>(url);
  }

  getClinicsList(orgId) {
    const url = `/api/organizations/${orgId}/getClinicMinDetailsByPatientGroup`;
    return this.networkService.fetchResource<any>(url);
  }

  getLocationNumbersList(orgId: string, type, searchKey?) {
    const url = `/api/organizations/${orgId}/searchOrganizations`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('type', type);
    httpParams = httpParams.append('searchString', searchKey);
    return this.networkService.fetchResource<PrimaryStores>(url, {params: httpParams});
  }

  getUserDetails(accountId) {

    const url = `/api/accounts/${accountId}`;

    return this.networkService.fetchResource<PrimaryStores>(url);
  }

}

export enum OrgOverviewStateActions {
  PerformerOrganization = 'add_performer_org_overview',
  ClearOrgOverview = 'clear_org_overview',
}

export class InHomeVaccinationModel {
  constructor(
    key: string,
    patientId: string,
    patientName: string,
    viechleNeeds: string,
    contactInfo: string,
    appointmentTime: string,
    pickLocation: string,
    dropLocation: string,
    communityGroupName: string,
    vaccineName: string,
    status: string,
    selected: boolean,
    lastExportDate: string,
    location?: any,
    patientRequestId?: string,
    dateTime?: string
  ) {
    this.key = key;
    this.patientId = patientId;
    this.patientName = patientName;
    this.viechleNeeds = viechleNeeds;
    this.contactInfo = contactInfo;
    this.appointmentTime = appointmentTime;
    this.pickLocation = pickLocation;
    this.dropLocation = dropLocation;
    this.communityGroupName = communityGroupName;
    this.vaccineName = vaccineName;
    this.status = status;
    this.selected = selected;
    this.lastExportDate = lastExportDate;
    this.location = location;
    this.patientRequestId = patientRequestId;
    this.dateTime = dateTime;
  }

  patientId: string;
  key: string;
  communityGroupName: string;
  patientName: string;
  viechleNeeds: string;
  contactInfo: string;
  appointmentTime: string;
  dropLocation: string;
  pickLocation: string;
  vaccineName: string;
  status: string;
  selected: boolean;
  lastExportDate: string;
  vaccineTeam: string;
  lotNumber: string;
  location: any;
  dateTime: string;
  time: string;
  patientRequestId: string;
}
