import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { GlobalErrorHandlerModalComponent } from "src/app/shared/modals/global-error-handler-modal/global-error-handler-modal.component";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
      public router: Router,
      private dialog: MatDialog) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status == 422) {
            if(error.error.fieldErrors && error.error.fieldErrors.length){
                this.dialog
                .open(GlobalErrorHandlerModalComponent, {
                  data: {errors: error.error.fieldErrors, fieldErrors: true}
                });
            }
          }
        return throwError(error);
      })
    );
  }
}