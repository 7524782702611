import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import globals from '../../../config';
import {addMinutes, setHours, startOfDay} from 'date-fns';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

@Injectable({
  providedIn: 'root',
})
export class DateUtility {
  public today: Moment = moment().startOf('day');

  constructor() {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: function (s) {
          if (s !== 'just now') {
            return s + ' ago';
          } else {
            return s;
          }
        },
        s: 'just now',
        ss: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });
  }

  /**
   * gen time list
   * @param start start time
   * @param end end time
   * @param duration minutes
   */
  static genTimeList(start: number, end: number, duration = 15): Date[] {
    let startTime = setHours(startOfDay(new Date()), start)
    let curr = start
    let timeList: Date[] = [startTime]
    let currTime = startTime
    while (curr < end) {
      currTime = addMinutes(currTime, duration)
      timeList.push(currTime)
      curr += duration / 60
    }
    return timeList
  }

  static getMonthByDate(
    date: Date,
    format: string = 'YYYY-MM-DD'
  ): { startOfMonth: Date | string; endOfMonth: Date | string } {
    const startOf = moment(date).startOf('month');
    const endOf = moment(date).endOf('month');
    const startOfMonth = format ? startOf.format(format) : startOf.toDate();
    const endOfMonth = format ? endOf.format(format) : endOf.toDate();

    return {
      startOfMonth,
      endOfMonth,
    };
  }

  static getNumberOfWeek(date: Date): number {
    return moment(date).week();
  }

  static firstDayOfWeek(): number {
    return moment.localeData('en-us').firstDayOfWeek();
  }

  static getDayOfWeek(date: Date): number {
    return moment(date).day();
  }

  static dateByWeekNumber(weekNumber): Date {
    return moment().day(this.firstDayOfWeek()).week(weekNumber).toDate();
  }

  /**
   * Return time in iso8601 format
   * @param fullTime {string}
   */
  static timeInIso8601(fullTime: string = '00:00 AM'): string {
    const [time, timeOfDay] = fullTime.split(' ');

    if (timeOfDay === 'PM') {
      const [hour, minutes] = time.split(':');

      if (+hour !== 12) {
        return `${+hour + 12}:${minutes}`;
      }
    }

    return time;
  }

  static isSame(date1, date2, type) {
    return moment(date1).isSame(date2, type);
  }

  static getDiffByTwoDates(date1: Date, date2: Date, type: DurationConstructor): number {
    return Math.abs(moment(date1).diff(date2, type));
  }

  public isOffTrack(date: Date): boolean {
    return this.today > moment(date);
  }

  public getTimeZoneOffSetByTimeZoneId(date, timeZoneId) {
    return moment.tz(date, timeZoneId).format('Z')
  }

  public getTimeZoneDisplayName(date, timeZoneId) {
    return moment.tz(date, timeZoneId).format('z')
  }

  public getTimeZoneList(country) {
    let timeZoneList = moment.tz.zonesForCountry(country)
    let timeZoneObjList = []
    timeZoneList.forEach((timeZone) => {
      timeZoneObjList.push({
        'value': timeZone,
        'title': timeZone
      })
    })

    return timeZoneObjList
  }

  public getAllTimeZoneNames(): string[] {
    return moment.tz.names();
  }

  /** Get Date Difference
   *
   * @param {Date, Moment} date the date that will be compared against today.
   *
   * @return {number} returns the days between now and the input date
   */
  public getDateDifferenceFromToday(date: any): number {
    if (typeof date === typeof 0) {
      date = new Date(date);
    }

    const diff = moment.duration(moment(this.today).diff(date));
    return Math.ceil(diff.asDays());
  }

  public getDateDifferenceFromTodayTimeZone(date: any, timezone: any): number {
    let today = moment().toDate();
    if (typeof date === typeof 0) {
      date = moment.tz(date, timezone);
    }
    const diff = moment.duration(moment(date).diff(today));
    return Math.ceil(diff.asDays());
  }

  /** Get Date Difference
   * Takes in 2 argument(s).
   *
   * @param {Date, Moment} date1 starting date.
   * @param {Date, Moment} date2 due date.
   *
   * @return {number} returns the days between now and the input date
   */
  public getDateDifference(date1: any, date2: any): number {
    if (typeof date1 === typeof 0) {
      date1 = new Date(date1);
    }

    if (typeof date2 === typeof 0) {
      date2 = new Date(date2);
    }

    return moment.duration(moment(date2).diff(date1)).asDays();
  }

  public getToday(): Moment {
    return this.today;
  }

  public formatDate(date: Date, format: string = 'MM/DD/YYYY', locale: string = 'en'): string {
    if (date) {
      if (locale) {
        return moment(date).locale(locale).format(format);
      }
      return moment(date).format(format);
    }
    return 'MM/DD/YY';
  }

  public formatDateUTC(date: Date, format: string = 'MM/DD/YYYY'): string {
    if (date) {
      return moment.utc(date).format(format);
    }
    return 'MM/DD/YY';
  }

  public convertToTimestamp(date: any): any {
    const type = typeof date;

    switch (type) {
      case typeof new Date():
        return moment(date).toDate().getTime();
      case typeof 0:
        return moment(new Date(date)).toDate().getTime();
      case typeof '':
        return moment(new Date(date)).toDate().getTime();
    }
  }

  public getAge(date: Date): number {
    return this.today.year() - moment(date).year();
  }

  public convertToMoment(date: any): Moment {
    const type = typeof date;
    switch (type) {
      case typeof new Date():
        return moment(date);
      case typeof 0:
        return moment(date);
      case typeof '':
        return moment(new Date(date));
    }
  }

  public convertTimetoTimestamp(time: string): any {
    return this.combineDateTime(this.today, time);
  }

  public combineDateTime(date: any, time: any): Moment {
    const typeDate = typeof date;
    const typeTime = typeof time;

    switch (typeDate) {
      case typeof new Date():
        date = this.formatDate(date, 'MM/DD/YYYY');
        break;
      case typeof 0:
        date = this.formatDate(new Date(date), 'MM/DD/YYYY');
        break;
      case typeof '':
        break;
      case null:
        date = this.today;
        break;
    }

    switch (typeTime) {
      case typeof new Date():
        date = this.convertToMoment(date).format('HH:mm');
        break;
      case typeof 0:
        date = this.convertToMoment(date).format('HH:mm');
        break;
      case typeof '':
        if (time === 'N/A') {
          time = '';
        }
        break;
      case null:
        time = '';
        break;
    }

    return this.convertToMoment(`${date} ${time}`);
  }

  public getTimeFromTimestamp(date: number): string {
    if (date) {
      return this.convertToMoment(date).format('hh:mm a');
    }
    return '';
  }

  public convertTimestampToDate(timestamp: number): Date {
    if (timestamp) {
      return new Date(timestamp);
    }
    return null;
  }

  public changeDate(type: string, oldDate, format: string = 'YYYY-MM-DD') {
    /*TODO: adding by one day might affect number of appointments based on time*/
    let newDate;
    switch (type) {
      case 'previous':
        newDate = moment(oldDate, format).subtract(1, 'day');
        break;
      case 'next':
        newDate = moment(oldDate, format).add(1, 'day');
        break;
      default:
        newDate = this.today;
    }
    return this.formatDate(newDate, format);
  }

  public getTimeZone(): string {
    const date = new Date();
    const offsetInHours = date.getTimezoneOffset() / 60;

    if (offsetInHours > 0) {
      return 'GMT-' + offsetInHours;
    } else if (offsetInHours === 0) {
      return 'GMT';
    } else {
      return 'GMT+' + offsetInHours * -1;
    }
  }

  public getFormattedExpiration(timestamp: number) {
    const tempDate = moment(new Date(timestamp));
    return `In ${tempDate.from(this.today)}`;
  }

  public getFrequencyDays(therapyType) {
    if (therapyType.weekDays && [globals.TASKTYPES.MEDICATION].indexOf(therapyType.taskTypeId) >= 0) {
      if (therapyType.weekDays.length < 7) {
        return therapyType.weekDays.map((wd) => globals.DAYSOFWEEK[wd]).join(',');
      } else {
        return 'Everyday';
      }
    } else if ([globals.TASKTYPES.ASSESSMENT].indexOf(therapyType.taskTypeId) >= 0) {
      return therapyType.weekly === 7 ? 'Everyday' : therapyType.weekly;
    } else {
      return '-';
    }
  }

  public getFrequency(task) {
    let frequency = '';
    const therapyType = task.therapyType;

    if ([globals.TASKTYPES.EXERCISE, globals.TASKTYPES.FOOD].indexOf(therapyType.taskTypeId) >= 0) {
      if (therapyType.weekly) {
        frequency += `D${therapyType.weekly}`;
      }
      if (therapyType.daily) {
        frequency += `/T${therapyType.daily}`;
      }
      if (therapyType.eachTime) {
        frequency += `/S${therapyType.eachTime}`;
      }
      if (therapyType.eachSet) {
        frequency += `/R${therapyType.eachSet}`;
      }
    } else if ([globals.TASKTYPES.MEDICATION, globals.TASKTYPES.ASSESSMENT].indexOf(therapyType.taskTypeId) >= 0) {
      frequency = this.getFrequencyDays(therapyType);
    } else if ([globals.TASKTYPES.APPOINTMENT].indexOf(therapyType.taskTypeId) >= 0) {
      frequency = `${this.formatDate(task['dueDate'])} at ${this.getTimeFromTimestamp(task['dueDate'])}`;
    }

    return frequency;
  }

  public fetchTimePassedByTimestamp(timestamp: number, withoutSuffix = false): string {
    const date = new Date(timestamp);
    return moment(date).fromNow(withoutSuffix);
  }

  public hourlyDiff(time): number {
    return moment().diff(this.convertToMoment(time), 'hours');
  }

  // new
  public newChangeDate(direction, date): Moment {
    const hash = {
      previous: 'subtract',
      next: 'add',
    };

    return hash[direction] ? moment(date)[hash[direction]](1, 'day') : this.today;
  }

  public checkIfToday(date) {
    return moment(date).startOf('day').diff(this.today, 'days') === 0;
  }

  public subtractDaysFromToday(days: number) {
    return this.today.subtract(days, 'day');
  }

  public getWeekInfo(offset: number) {
    offset = offset * 7;
    const weekStart = moment().startOf('week').add(offset, 'day');
    const weekEnd = moment()
      .startOf('week')
      .add(offset + 6, 'day');

    return {
      startDate: weekStart,
      endDate: weekEnd,
    };
  }
}
