import {Injectable} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {SnackbarComponent} from 'src/app/shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  startHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  topVerticalPosition: MatSnackBarVerticalPosition = 'top';
  private snackBarConf: MatSnackBarConfig = {
    duration: 0,
    horizontalPosition: this.startHorizontalPosition,
    verticalPosition: this.topVerticalPosition,
  };

  constructor(private snackBar: MatSnackBar) {}

  notify(message: string, duration = 3000): void {
    if (message) {
      this.snackBar.open(message, '', {...this.snackBarConf, duration});
    }
  }

  notifyWarning(warningMessage: string, duration = 3000){
    if (warningMessage) {
      this.snackBar.open(warningMessage, '', {...this.snackBarConf, duration, panelClass: ['warning-message']});
    }
  }

  notifyError(errorMessage: string, duration = 3000){
    if (errorMessage) {
      this.snackBar.open(errorMessage, '', {...this.snackBarConf, duration, panelClass: ['error-message']});
    }
  }

  openFromSnackbarComponent(dataToShow: any){
    if(dataToShow){
      this.snackBar.openFromComponent(SnackbarComponent, { ...this.snackBarConf, data: dataToShow})
    }
  }

  dismiss(){
    this.snackBar.dismiss();
  }
}
