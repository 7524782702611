import {Base} from './base.class';
import {Address} from './address.class';
import {DepartmentModel} from '../models/provider.model';
import {Performer} from '../models/performer.model';
import {BranchModel} from '../models/organization.model';
import {OrganizationTypes} from '../../shared/enums/organization';

export class Branch extends Base {
  address: Address;
  departments: DepartmentModel[];
  description: string;
  isHeadquarter: boolean;
  name: string;
  patientCount: number;
  providerCount: number;
  compliancyScore: number;
  vaccinationCount: number;
  shortName: string;
  type: OrganizationTypes;
  podType: string;
  orgTimezone : string;
  isOpen: boolean;
  myTurnLocationId: string;
  isOpenAtMyTurn: boolean;
  organizationAppointments: any[];
  _performers: Performer[];
  _stringPerformers: string;
  approvalStatus?: string;
  facilityDetails?: string;
  numOfPatients?: number;
  expectedTimeDuration?: number;
  haveFacility?: boolean;
  canRegisterIndividuals?: boolean;
  vaccine: string;
  applicationDate?: string;
  priority?: string;
  requestId?: string;
  billingContactEmail?: string;
  billingContactFn?: string;
  billingContactLn?: string;
  billingContactPhone?: string;
  childrenCommunities?: Branch[];
  gpsLongitude: number;
  gpsLatitude: number;
  clinicId: any;
  clinicName: any;
  clinicAddress: any;

  get stringPerformers() {
    return this._stringPerformers;
  }
  get performers() {
    return this._performers;
  }

  _status: BranchStatus;
  set status(value) {
    this._status = value;
  }
  get status() {
    return this._status;
  }
  get customStatus() {
    if (this.approvalStatus === 'PENDING' || this.approvalStatus === 'CONFIRMED' || this.approvalStatus === 'ONBOARD' || this.approvalStatus === 'DENIED') {
      return this.approvalStatus;
    }
    return this._status;
  }

  constructor({id = '', links = [], ...options}: BranchModel) {
    super({id, links});

    this.id = id;
    this.links = links;
    this.gpsLatitude = options.latitude || null;
    this.gpsLongitude = options.longitude || null;
    this.clinicId = options.clinicId;
    this.clinicName = options.clinicName;
    this.clinicAddress = options.address;
    this.address = new Address(options.address || {});
    this.departments = options.departments || [];
    this.description = options.description || '';
    this.isHeadquarter = options.isHeadquarter || false;
    this.myTurnLocationId = options.myTurnLocationId;
    this.isOpenAtMyTurn = options.isOpenAtMyTurn;
    this.name = options.name || '';
    this.patientCount = options.patientCount || 0;
    this.providerCount = options.providerCount || 0;
    this.vaccinationCount = options.vaccinationCount || 0;
    this.organizationAppointments = options.organizationAppointments || [];
    this._performers = [];
    this.applicationDate = options.applicationDate || '';
    if (options.compliancyScore) {
      this.compliancyScore = options.compliancyScore;
    }
    this.shortName = options.shortName || '';
    this.type = options.type;
    this._status = BranchStatus.NotScheduled;
    this._status = this.organizationAppointments.length ? BranchStatus.Planned : BranchStatus.NotScheduled;
    this.approvalStatus = options.approvalStatus || '';
    this.facilityDetails = options.facilityDetails || '';
    this.numOfPatients = options.numOfPatients;
    this.expectedTimeDuration = options.expectedTimeDuration;
    this.haveFacility = options.haveFacility;
    this.canRegisterIndividuals = options.canRegisterIndividuals;
    this.vaccine = options.vaccine || '';
    this.requestId = options.requestId || ''
    this.priority = options.priority || ''
    this.childrenCommunities = options.childrenCommunities
    
  }

  addPerformer(performer: Performer) {
    const index = this._performers.findIndex((p) => p.id === performer.id);

    if (index < 0) {
      this._performers.push(performer);
      this._stringPerformers = this._performers.map((p) => p.name).join(', ');
    }
  }

  hasGeo() {
    return this.gpsLatitude && this.gpsLongitude;
  }

}

export enum BranchStatus {
  NotScheduled = 'Not Yet Scheduled',
  Planned = 'Planned',
  Scheduled = 'Scheduled',
  Completed = 'Completed',
}
