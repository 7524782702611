import {Injectable} from '@angular/core';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {NetworkService} from '../network/network.service';
import {NoteModel} from '../../models/note.model';
import {Patient} from '../../models/patient.model';
import {LinkUtil} from '../../utils/link-util/link-util.service';
import {catchError, defaultIfEmpty, map, mergeMap} from 'rxjs/operators';
import {ImageService} from '../image/image.service';
import {forkJoin, of} from 'rxjs';

class NoteResponse {
  content: NoteModel[];
  links: any[];
}

class GetNoteRequest {
  patient: Patient;
  allSelected: boolean;
  performerName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(
    private data: DataBrokerService,
    private network: NetworkService,
    private linkUtil: LinkUtil,
    private imageService: ImageService
  ) {}

  public createNote(note) {
    const createNoteLink = '/api/note';

    return this.network.postResource(createNoteLink, note).pipe(mergeMap((res: NoteModel) => this.getImage(res)));
  }

  public getNotes({patient, allSelected, performerName}: GetNoteRequest) {
    const getNotesLink = `/api/note/patient/${patient.id}?creator=${allSelected ? 'all' : 'me'}${
      performerName ? '&performerName=' + performerName : ''
    }`;
    const $getNote = this.network.fetchResource<NoteResponse>(getNotesLink);

    return $getNote
      .pipe(
        mergeMap(({content: notes}: NoteResponse) => {
          const noteList = notes.map((note) => this.getImage(note));

          return forkJoin(noteList);
        })
      )
      .pipe(defaultIfEmpty([]));
  }

  public updateNote(note) {
    const updateNoteLink = this.linkUtil.parseLink(note.links, 'update');

    return this.network.putResource(updateNoteLink, note).pipe(mergeMap((res: NoteModel) => this.getImage(res)));
  }

  private getImage(note) {
    const imgUrl = this.linkUtil.parseLink(note.links, 'performerImg');

    return this.imageService.getImageNew(imgUrl, 100).pipe(
      map((img: string) => ({...note, performerIcon: img})),
      catchError(() => of(note))
    );
  }

  public deleteNote(note) {
    const deleteNoteLink = this.linkUtil.parseLink(note.links, 'delete');

    return this.network.deleteResource(deleteNoteLink);
  }
}
