import {Injectable, ErrorHandler} from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root',
})
export class GlobalExceptionHandlerService implements ErrorHandler {

  handleError(error: any) {
    try{
      console.error(error);

      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        if (confirm("A new version of the application is now available. Would you like to refresh the page?")) {
          window.location.href = window.location.pathname + '?t=' + new Date().getTime();
        }
      }
    }catch(e){};

    return Sentry.captureException({
      error
    })
  }
}
