import {Patient} from './patient.model';
import globals from '../../../config';
import {Link} from './link.model';
import {CareplanTaskModel} from './careplan-task-model';

export class UserSession {
  accountId: string;
  performerId: string;
  firstName: string;
  lastName: string;
  role: string;
  isPerformer: boolean;
  isUserProvider: boolean;
  patients: Array<Patient>;
  currPatient: any;
  organizationName: string;
  sessionAccount: any;
  organizationId: string;
  privilege: PrivilegeModel;
  locale: string;
  email: string;

  links = [];
  locationNumber: string;
  locationType:string;
  locationNumbers:any;
  organizationType: string;
  walgreenGroup: string;
  isGuest: boolean;

  get isAccountFromVAOrganization() {
    return this.organizationName === globals.VA.orgName;
  }
}

export class PrivilegeModel {
  description = '';
  id: string;
  isAdmin = false;
  links: any[];
  name: string;
  locationNumber: any;

  constructor(name: string, id: string) {
    this.name = name;
    this.id = id;
  }
}

export class MobileApiUserSession{
  accountId: string;
  accountList: Array<string>;
  accountListName: Array<string>;
  activeTasks?: {
    links: Link[],
    content: CareplanTaskModel[]
  };
  age: string;
  assessmentReports?: Array<any>;
  carePlanStatus: string;
  carePlans?: any;
  completedTasks?: any;
  dateRegistered: any;
  dob: string;
  email: string;
  firstName: string;
  genderIdentity: string;
  industry: string;
  insurance?: any
  lastName: string;
  links?: any;
  location?: any;
  mobileNumber: string;
  motherFirstName: string;
  observationList?: Array<any>;
  organizationId: string;
  organizationLocationId: string;
  organizationName: string;
  otherTasks?: CareplanTaskModel[];
  patientActivities?: Array<any>;
  patientId: string;
  pendingAppointments?: any;
  //privilege: Privilege;
  privilegeId: number;
  priority: string;
  profileCompleteness: any;
  race: string;
  recommendation: any;
  recommendations: Array<any>
  role: string;
  sex: string
  unconfirmedMembers?: any;
  missingConsentOrganizations?: any;
  isEnableSchedule: boolean;
}
