import { Injectable } from '@angular/core';
import moment from "moment/moment";
import {DatePipe} from "@angular/common";
import {AppInjector} from "../../../app.module";
import {
  AppointmentForClinic
} from '../../organizations/org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';

@Injectable({
  providedIn: 'root'
})
export class ScheduleAppointmentService {

  datePipe = AppInjector.get(DatePipe);

  constructor() { }

  extractSlots(site: AppointmentForClinic, bookingDate: Date) {
    const startTime = site.startDate;
    const endTime = site.endDate;
    const fullSlots = site.slots;
    const slotDuration = 600000;
    const numSlots = (endTime - startTime) / slotDuration;
    let totalSlots: number[] = [];
    for (let i = 0; i < numSlots; i++) {
      const slotTime = startTime + i * slotDuration;
      totalSlots.push(slotTime);
    }
    totalSlots.forEach((slotTime) => {
      fullSlots.forEach((targetTime) => {
        if (
            targetTime - slotTime < slotDuration / 3 &&
            targetTime - slotTime > -slotDuration / 3
        ) {
          totalSlots = totalSlots.filter((n) => {
            return n !== slotTime;
          });
        }
      });
    });
    let times: Date[] = [];
    totalSlots.forEach((slot) => {
      const date = new Date(slot);
      times.push(date);
    });
    const currentTime = new Date();
    times = times.filter(time => time > currentTime );
    let defaultTimes: moment.Moment[] = times.map((time) => {
      const hours = time.getHours();
      let minutes: string = time.getMinutes()?.toString();
      minutes = minutes === '0' ? `${minutes}0` : minutes;
      return moment(this.datePipe.transform(bookingDate, "yyyy-MM-dd") + " " + `${hours}:${minutes}`, "YYYY-MM-DD HH:mm");
    });

    return defaultTimes;
  }
}
