import { Injectable } from '@angular/core';
import {
  OnboardPatient,
  Patient,
  PatientModel,
  PatientParams,
  PatientStatsModel,
  PatientTeamMember,
  UpdatePatient,
} from '../../../core/models/patient.model';
import { Address } from '../../../core/models/address.model';
import { UserSessionService } from '../../../core/services/user-session/user-session.service';
import { NetworkService } from '../../../core/services/network/network.service';
import { DataBrokerService } from '../../../core/services/data-broker/data-broker.service';
import { LinkUtil } from '../../../core/utils/link-util/link-util.service';
import { ImageService } from '../../../core/services/image/image.service';
import { LanguageService } from '../../../core/services/language/language.service';
import { catchError, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { ActivityFeedModel, AssessmentReportResource } from '../../../core/models/activity.model';
import { PatientOverviewResponse } from '../../../core/models/patient-overview-model';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ActivitiesService } from '../../../core/services/activities/activities.service';
import {
  TracedContact
} from '../patient-overview/po-covid/components/traced-contacts-table/traced-contacts-table.component';
import { HttpUrlEncodingCodec } from 'src/app/core/services/authorization/http-url-encoding-codec';
import { ResponseWithLinks } from '../../../core/models/common.model';
import { IAppointmentTask } from 'src/app/core/models/appointment-task.model';
import { IFamilyMemberAppointments } from 'src/app/core/models/family-member-appointments.interface';
import {IAvailableLanesInfo} from "../../patient-registration/register-form/services/register-form.service";
import {
  AppointmentForClinic
} from "../../organizations/org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component";
import {Link} from '../../../core/models/link.model';
import {ClinicAppointmentsModel} from '../../../core/models/cliniclane.model';


export interface IAppointmentBookedMini{
  name: string;
  taskStatus: string;
  dueDate: number;
  patientName: string;
  links: Link[];
  id: string;
  message: string;
}

export interface IAppointmentBookingClinicDetails{
  clinicDetails: ClinicAppointmentsModel[],
  taskId: string
}

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  readonly quarantineContactUrl = '/api/quarantineContact';
  private _patientOverview: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _patientWithStats = new BehaviorSubject<PatientStatsModel[] | null>(null);
  private patientActivities$: Observable<ActivityFeedModel[]>;
  private _updatePatientList = new BehaviorSubject<boolean>(false);

  set patientWithStats(value) {
    this._patientWithStats.next(value);
  }
  get patientWithStats() {
    return this._patientWithStats.getValue();
  }
  public patientWithStats$ = this._patientWithStats.asObservable();

  set updatePatientList(value) {
    this._updatePatientList.next(value);
  }

  readonly dataStore: {
    patientOverview: any;
  };
  public patient: Patient;
  public address: Address;
  private patientCache: any = { res: new ReplaySubject(1) };
  private subscriptions = [];

  // language labels
  private diagnosisLabel: string;
  private cancerTypeLabel: string;
  private stageLabel: string;
  private treatmentStatusLabel: string;

  constructor(
    private session: UserSessionService,
    private networkService: NetworkService,
    private data: DataBrokerService,
    private linkUtil: LinkUtil,
    private imageService: ImageService,
    private languageService: LanguageService,
    private http: HttpClient,
    private activitiesService: ActivitiesService
  ) {
    this.updateLanguage();
    this.subscriptions.push(
      this.data.subscribe<any>('language-update').subscribe(() => {
        this.updateLanguage();
      })
    );

    this.dataStore = { patientOverview: null };
    this._patientOverview = new BehaviorSubject(null);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  get patientOverviewObservable(): Observable<any> {
    return this._patientOverview.getValue() ? this._patientOverview.asObservable() : this.getPatientWithImage();
  }

  setPatientOverview(patient) {
    this.dataStore.patientOverview = patient;
    this._patientOverview.next(Object.assign({}, this.dataStore).patientOverview);
  }

  get store() {
    return this.dataStore;
  }

  public getPatientWithImage(): Observable<PatientOverviewResponse> {
    const patientUrl = localStorage.getItem('current-patient');

    if (patientUrl) {
      return this.networkService.fetchResource(patientUrl).pipe(
        mergeMap((response: any) => {
          this.session.sessionAccount.currPatient = response;

          return of(response);
        }),
        map((patient: PatientOverviewResponse) => {
          this.setPatientOverview(patient);

          return patient;
        })
      );
    }

    return of(null);
  }

  public getPatientOverview(patientId: string): Observable<PatientOverviewResponse> {
    const url = `/api/patients/${patientId}/overview`;

    return this.networkService.fetchResource(url);
  }

  getAllOrganizations(patientId): Observable<any[]> {
    const url = `/api/patients/${patientId}/patientOrganization`;

    return this.networkService.fetchResource(url);
  }

  deleteOrganizations(patientId, organId): Observable<any> {
    const url = `/api/patients/${patientId}/removeOrganization?organizationId=${organId}`;

    return this.networkService.postResource<any>(url, {});
  }


  public getCurrentPatient(): Observable<PatientModel> {

    const isUserLoggedIn = !!this.networkService.getAuthToken();

    if(isUserLoggedIn === false){
      return this.patientCache.res;
    }

    if (!this.patientCache._lastCalled || Date.now() - this.patientCache._lastCalled > 500) {
      this.patientCache._lastCalled = Date.now();

      this.patientCache.res = this.networkService
        .fetchResource<PatientModel>('/api/patients/patient', { role: 'patient' })
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.patientCache.res;
  }

  public getPatientFamilyMembers(patientId: string) {
    return this.networkService.fetchResource<{ teamMember: PatientTeamMember[] }>(`/api/patients/${patientId}/family-members`, {
      role: 'patient',
    });
  }

  getFamilyAppointments(patientId): Observable<IFamilyMemberAppointments[]> {
    const url = `/api/tasks/${patientId}/family-member-appointments`;
    return this.networkService.fetchResource(url);
  }

  public getPatientFamilyMemberDetails(accountId: string) {
    return this.networkService.fetchResource<any>(`/api/session/${accountId}/family-member-details`, { role: 'patient' });
  }

  public getFamilyMemberPatient(familyMemberPatientId: string) {
    return this.networkService.fetchResource<any>(`/api/patients/${familyMemberPatientId}`, { role: 'patient' });
  }

  public getMasterDataByPatientVaccineManufacturer(patientId: string): Observable<any> {
    const link = `/api/patients/${patientId}/master-data-by-patient-vaccine-manufacturer`;

    return this.networkService.fetchResource<any>(link);
  }

  public deleteAccount(id): Observable<any> {
    const link = `/api/patients/${id}`;

    return this.networkService.deleteResource(link);
  }

  public removeFromPatientGroup(patientId: string): Observable<any> {
    const link = `/api/patients/${patientId}/reset-patient-group`;

    return this.networkService.putResource(link, {});
  }

  public resetVaccinationJourney({ patientId, isLTCF }: { patientId: string, isLTCF: boolean }): Observable<any> {
    const link = `/api/patients/${patientId}/resetJourney/${isLTCF}`;

    return this.networkService.postResource(link, {});
  }

  public purgeAccount(id): Observable<any> {
    const link = `/api/patients/purge/${id}`;
    return this.networkService.deleteResource(link);
  }

  public getTask(taskId): Observable<any> {
    const url = `/api/tasks/${taskId}`;
    return this.networkService.fetchResource(url);
  }

  dismissResultCard(id: string): Observable<PatientModel> {
    const url = `/api/observations/${id}/true/update-checked-status`;
    return this.networkService.patchResource(url, {});
  }

  public getPatientsWithStats(
    carePlanId,
    {
      page,
      size,
      age,
      gender,
      asc,
      orderType
    } = {
        page: 0,
        size: 20,
        age: '',
        gender: '',
        asc: '',
        orderType: ''
      }
  ): Observable<{ carePlanTemplateEngagementList: ResponseWithLinks<any>; totalCarePlanTemplateEngagementCount: number }> {
    const link = `/api/carePlans/${carePlanId}/patients-stats`;
    let httpParams = new HttpParams().set('page', page).set('size', size);

    if (age) httpParams = httpParams.append('age', age);
    if (gender) httpParams = httpParams.append('gender', gender);
    if (orderType) httpParams = httpParams.append('orderType', orderType);
    if (asc) httpParams = httpParams.append('asc', asc.toString());

    return this.networkService.fetchResource<{ carePlanTemplateEngagementList: ResponseWithLinks<PatientStatsModel>; totalCarePlanTemplateEngagementCount: number }>(link, { params: httpParams }).pipe(
      tap(({ carePlanTemplateEngagementList }) => {
        this.patientWithStats = carePlanTemplateEngagementList.content;
      })
    );
  }

  public getTaskByID(id): Observable<any> {
    return this.networkService.fetchResource<any>(`/api/tasks/${id}`);
  }

  public getAppointmentBookingClinicDetails(patientId: string, appointmentTaskIds: string[]): Observable<IAppointmentBookingClinicDetails[]> {
    let ids = appointmentTaskIds.join(',');
    const link = `/api/patients/${patientId}/clinic-vaccine-details?taskIds=${ids}`;
    return this.networkService.fetchResource<IAppointmentBookingClinicDetails[]>(link);
  }

  getAssessmentDetails(patientId: string): Observable<any> {
    const url = `/api/surveys/patient/${patientId}/assessment-reports`;
    return this.networkService.fetchResource(url);
  }

  public getPatientActivities(patientId: any, { page = 0, size = 10 }): Observable<ActivityFeedModel[]> {
    const url = `/api/patients/${patientId}/activities`;
    let params = new HttpParams();

    if (page || page === 0) params = params.set('page', page.toString());
    if (size) params = params.set('size', size.toString());

    return this.networkService.fetchResource(url, { params }).pipe(
      map(({ content: activitiesResponse }: { content: ActivityFeedModel[] }) => {
        return activitiesResponse.map(
          ({
            assessmentReportResource,
            assessmentReportResource: { score, dateCreated, extraValue = '' } = {},
            ...activity
          }) => {
            const conditionWarnings: any[] = this.activitiesService.parseExtraValue(extraValue.toString());
            const conditionWarningText = conditionWarnings.length ? conditionWarnings[0].text : '';
            const prevReport = activitiesResponse.find((a) => a.assessmentReportResource?.dateCreated < dateCreated)
              ?.assessmentReportResource;

            return {
              ...activity,
              assessmentReportResource: {
                ...assessmentReportResource,
                conditionWarnings,
                conditionWarningText,
                lastScoreChange: prevReport ? score - prevReport.score : 0,
              },
            };
          }
        );
      })
    );
  }

  // public getGeoCoordinates(address1, city, state): Observable<any> {
  //   let geoCodingLink = `https://maps.googleapis.com/maps/api/geocode/json?address=`;
  //   geoCodingLink += `${address1.replace(' ', '+')}`;
  //   geoCodingLink += `,${city.replace(' ', '+')}`;
  //   geoCodingLink += `,${state.replace(' ', '+')}`;
  //   // 1600 Amphitheatre Parkway Mountain View CA`;
  //   geoCodingLink += `&key=AIzaSyDCgfYfxQcOLhvaeOgiM0yrK8oTuG4R9VU`;
  //
  //   return this.http.get<any>(geoCodingLink);
  // }

  public getImagesForPatientActivities(activities) {
    const activitiesWithImage: Observable<ActivityFeedModel>[] = activities.map((activity: ActivityFeedModel) => {
      const arr = [this.imageService.addImage(activity.activityResource, 'actorImg', 100)];

      if (activity.observationResource) {
        arr.push(this.imageService.addImage(activity.observationResource, 'observationImage', 100));
      } else {
        arr.push(of(null));
      }

      if (activity.medicationReportResource) {
        const medicationTasks = activity.medicationReportResource.taskIds.map((id, i) => {
          const names = activity.medicationReportResource.medicationName.split('/');
          const url = `/api/meta/therapy/image/${id}`;

          return this.imageService.addImage({ taskImage: url, name: names[i] }, 'taskImage', 100);
        });

        arr.push(forkJoin(medicationTasks).pipe(map((tasks) => ({ ...activity.medicationReportResource, tasks }))));
      } else {
        arr.push(of(null));
      }

      if (activity.exerciseReportResource) {
        const exerciseTasks = activity.exerciseReportResource.setList.map((task: any) => {
          task.taskImage = `/api/meta/therapy/image/${task.taskId}`;

          return this.imageService.addImage(task, 'taskImage', 100);
        });

        arr.push(forkJoin(exerciseTasks).pipe(map((setList) => ({ ...activity.exerciseReportResource, setList }))));
      } else {
        arr.push(of(null));
      }

      return combineLatest(arr).pipe(
        map(([activityResource, observationResource, medicationReportResource, exerciseReportResource]) => ({
          ...activity,
          activityResource,
          observationResource,
          medicationReportResource,
          exerciseReportResource,
        }))
      );
    });

    return forkJoin(activitiesWithImage);
  }

  // Clear patientActivities$
  clearPatientActivityTasks() {
    this.patientActivities$ = null;
  }

  public getDailyAssessmentReports(id): Observable<AssessmentReportResource[]> {
    const link = `/api/surveys/patient/${id}/daily-assessment-reports`;

    return this.networkService.fetchResource(link);
  }

  public updatePatientGroup(patientId, newPatientGroupOrgId): Observable<any> {
    const link = `/api/patients/${patientId}/organization/${newPatientGroupOrgId}`;

    return this.networkService.putResource(link, {});
  }

  public getDailyAssessmentQuestions(id): Observable<any[]> {
    const link = `/api/surveys/patient/${id}/daily-assessment-questions`;

    return this.networkService.fetchResource(link);
  }

  public getQuarantineLocations(id): Observable<Address[]> {
    const link = `/api/locations/${id}/getAllPatientQuarantineLocations`;

    return this.networkService.fetchResource(link);
  }

  /* get traced contacts from the server */
  getQuarantineContacts(patientId: string): Observable<TracedContact[]> {
    const url = `${this.quarantineContactUrl}/${patientId}`;

    return this.networkService.fetchResource(url).pipe(catchError(this.handleError('getContacts'))) as Observable<TracedContact[]>;
  }

  /* New based on angular blog */
  public getPatients(
    performerId: string,
    {
      page = null,
      size = null,
      orderType = '',
      asc = true,
      name = '',
      treatmentStatus = '',
      carePlans = '',
      carePlanStatus = '',
      lastActiveTimeFrom = null,
      lastActiveTimeTo = null,
    }: PatientParams = {}
  ): Observable<any> {
    const url = `/api/performers/patients-with-metrics`;
    let params = new HttpParams();

    if (performerId) params = params.set('performerId', performerId);
    if (page || page === 0) params = params.set('page', page.toString());
    if (size) params = params.set('size', size.toString());
    if (orderType) params = params.set('orderType', orderType.toString());
    if (typeof asc === 'boolean') params = params.set('asc', asc.toString());
    if (name) params = params.set('name', name.toString());
    if (treatmentStatus) params = params.set('treatmentStatus', treatmentStatus.toString());
    if (carePlans) params = params.set('carePlans', carePlans.toString());
    if (carePlanStatus) params = params.set('carePlanStatus', carePlanStatus.toString());
    if (lastActiveTimeFrom) params = params.set('lastActiveTimeFrom', lastActiveTimeFrom.toString());
    if (lastActiveTimeTo || lastActiveTimeTo === 0) {
      params = params.set('lastActiveTimeTo', lastActiveTimeTo.toString());
    }

    return this.networkService.fetchResource(url, { params });
  }

  public getPatientWithMetricsCount(
    performerId: string,
    { carePlans = '', lastActiveTimeFrom = null, lastActiveTimeTo = null, name = '' }: PatientParams
  ) {
    const currentProviderId = this.session.sessionAccount.performerId;
    const url = `/api/performers/${currentProviderId}/patients-with-metrics-count`;
    let params = new HttpParams();

    if (performerId) params = params.set('performerId', performerId.toString());
    if (name) params = params.set('name', name.toString());
    if (carePlans) params = params.set('carePlans', carePlans.toString());
    if (lastActiveTimeFrom) params = params.set('lastActiveTimeFrom', lastActiveTimeFrom.toString());
    if (lastActiveTimeTo || lastActiveTimeTo === 0) {
      params = params.set('lastActiveTimeTo', lastActiveTimeTo.toString());
    }

    return this.networkService.fetchResource(url, { params });
  }

  public getPatientListSummaryCount(performerId: string, { carePlans, lastActiveTimeFrom, lastActiveTimeTo, name }) {
    return combineLatest([
      this.getPatientWithMetricsCount(performerId, { carePlans, lastActiveTimeFrom, name }),
      this.getPatientWithMetricsCount(performerId, { carePlans, lastActiveTimeTo, name }),
      this.getPatientWithMetricsCount(performerId, { carePlans, lastActiveTimeTo: 0, name }),
    ]).pipe(map(([active, inactive, neverActive]: [number, number, number]) => ({ active, inactive, neverActive })));
  }

  public createPatient(newPatient: OnboardPatient): Observable<Patient> {
    const url = '/api/patients';

    return this.networkService.postResource(url, newPatient);
  }

  public getPatient(patientId: string): Observable<Patient> {
    const url = `/api/patients/${patientId}`;

    return this.networkService.fetchResource(url);
  }

  // public getLastDailyAssessmentReport(patientId) {
  //   const url = `/api/surveys/patient/${patientId}/last-daily-assessment-report`;
  //
  //   return this.networkService.fetchResource(url).pipe(
  //     map((report: DailyAssessmentReport) => {
  //       if (report && report.extraValue) {
  //         report.extraValue = JSON.parse(report.extraValue as string)
  //           .filter((a) => a.type === 'Critical' || a.type === 'Warning')
  //           .sort((a, b) => (a.score >= b.score ? 1 : -1));
  //       } else {
  //         report.extraValue = [];
  //       }
  //
  //       return report;
  //     })
  //   );
  // }

  public setPatientIsView(patientId: string, activityDate: number): Observable<any> {
    const url = `/api/activties/patient/${patientId}/${activityDate}/view`;

    return this.networkService.putResource(url, {});
  }

  public getActivityFull(activityId: string): Observable<ActivityFeedModel> {
    const url = `/api/activties/${activityId}/full`;

    // resorting to setTimeout in websocket.service.ts (addNewActivity) and delay(2000)
    // in po-covid.component.ts constructor.
    // Fixes 404 error after creating new Note / activity but not a great solution tbh
    // original code below
    // Have to wait for Note to be created in DB before fetching
    return <Observable<ActivityFeedModel>>this.networkService.fetchResource(url);

    // return <Observable<ActivityFeedModel>>this.networkService.fetchResource(url).pipe(
    //   retryWhen((e$) =>
    //     e$.pipe(
    //       // try again after 1 seconds
    //       delay(1000),
    //       // stop trying after 5 times
    //       take(4)
    //     )
    //   )
    // );
  }

  updatePatient(patientId: string, data: UpdatePatient, { headers } = { headers: null }): Observable<Patient> {
    const url = `/api/patients/profile/${patientId}`;

    return this.networkService.putResource(url, data, { headers });
  }

  private updateLanguage() {
    this.diagnosisLabel = this.languageService.language.patient_list_diagnosisLabel;
    this.cancerTypeLabel = this.languageService.language.patient_list_cancerTypeLabel;
    this.stageLabel = this.languageService.language.patient_list_stageLabel;
    this.treatmentStatusLabel = this.languageService.language.patient_list_treatmentStatusLabel;
  }

  getAppointments(patientId): Observable<any[]> {
    const url = `/api/tasks/${patientId}/appointments`;

    return this.networkService.fetchResource(url);
  }

  getAppointmentsInActiveGoals(patientId): Observable<IAppointmentTask[]> {
    const url = `/api/tasks/${patientId}/appointments-in-active-goals`;

    return this.networkService.fetchResource(url);
  }

  patientInvitationCode(patientId, invitationCode) {
    const url = `/api/patients/${patientId}/patient-invite-code?organizationId=${invitationCode}`;

    return this.networkService.postResource(url, {});
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param operation - name of the operation that failed
   */
  private handleError<T>(operation = 'operation') {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body "${error.error}"`;

      // TODO: better job of transforming error for user consumption
      throw new Error(`${operation} failed: ${message}`);
    };
  }

  addCarePlanToPatient(body: { patientId: string; careplanId?: number; careplanName?: string, goalName?: string, organizationId?: string }): Observable<any[]> {
    const url = '/api/carePlans/addCarePlanToPatient';

    return this.networkService.postResource(url, body);
  }

  addMultipleCarePlanToPatient(body: { patientId: string; careplanIds?: number[]; careplanName?: string, goalName?: string, organizationId?: string }): Observable<any[]> {
    const url = '/api/carePlans/addMultipleCarePlanToPatient';
    return this.networkService.postResource(url, body);
  }

  updatePatientEmail(patientId: string, email: string): Observable<any> {
    const url = `/api/patients/${patientId}/email`;
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() }).set('email', email);

    return this.networkService.putResource(url, {}, { params });
  }

  changePatientOrganization(newOrganizationId: any, selectedPatientIdsList: string[]): Observable<any> {
    const url = `/api/organizations/move-patients-to-organization`;
    const httpParams = new HttpParams().set('newOrganizationId', newOrganizationId);

    return this.networkService.putResource(url, selectedPatientIdsList, { params: httpParams })
  }

  getPatientServices(patientId: string): Observable<any> {
    const url = `/api/patient-requests/patient/${patientId}`;
    return this.networkService.fetchResource(url);
  }

  public getPatientOpenPublicServices(patientId: String, headers, orgId?): Observable<any> {
    let url = `/api/meta/getAllPublicServiceType?targetId=${patientId}&fetchCarePlan=true`;
    if (orgId)
      url += `&orgId=` + orgId;
    return this.networkService.fetchResource<any>(url, { headers: headers });
  }

  public getAvailableLanes(
    params: {
      patientId?: string,
      organizationId?: string
    }): Observable<IAvailableLanesInfo[]> {

    let url = `/api/organizations/getAvailableLanes`;
    let httpParams = new HttpParams()

    if (params.patientId) {
      httpParams = httpParams.set('patientId', params.patientId)
    }

    if (params.organizationId) {
      httpParams = httpParams.set('organizationId', params.organizationId)
    }

    return this.networkService.fetchResource<any>(url, { params: httpParams });
  }

  updatePatientProfile(patientId: string, body: any): Observable<PatientModel> {
    const url = `/api/patients/profile/${patientId}`;
    return this.networkService.putResource(url, body);
  }

  getPatientPdf(patientId) {
    const url = `/api/patients/${patientId}/vaccination-card/pdf/download`;
    return this.networkService.fetchResource(url, { responseType: 'blob' });
  }

  public getPatientVaccinationInfo(patientId: string): Observable<any> {
    const url = `/api/session/${patientId}/vaccinationInfo`;
    return this.networkService.fetchResource(url);
  }

  cancelAppointmentTask(patientId: string, taskId: string): Observable<any> {
    let url = `/api/tasks/bookAppointment/cancel?patientId=${patientId}&taskId=${taskId}`;
    return this.networkService.putResource<any>(url, {});
  }

  public getTestResultPDF(taskId: string): any {
    const url = `/api/elis/order-result/${taskId}/pdf`;
    return this.networkService.fetchResource(url, { responseType: 'blob' });
  }

  bookAppointment(
    clinicSiteLocationId: string,
    clinicAppointmentId: string,
    bookingDateInTimes: number,
    appointmentTaskId: string,
    patientId: string,
    additionalLaneId?: string,
    clinicAppointmentIdToTargetClinic?: string
  ): Observable<IAppointmentBookedMini> {
    let body = {
      locationId: clinicSiteLocationId,
      dueDate: bookingDateInTimes,
      id: appointmentTaskId,
      patientId,
      clinicAppointmentId,
      clinicAppointmentIdToTargetClinic
    };
    if (additionalLaneId) {
      body['additionalLaneId'] = additionalLaneId;
    }
    return this.networkService.putResource('/api/tasks/bookAppointment/mini', body);
  }

  filterLanesBasedOnVaccineType(taskName, laneArray) {
    let vaccineType;
    let filteredLanes = [];
    if (taskName.includes('3') || taskName.includes('1') || taskName.includes('2') || taskName.includes('Booster'))
      vaccineType = 'COVID';
    if (taskName.toUpperCase().includes('FLU')) vaccineType = 'Flu';
    laneArray.forEach((lane) => {
      let siteType;
      if (lane && lane.inventoryType?.toLowerCase().includes('covid-19')) siteType = 'COVID';
      if (lane && !lane.inventoryType) siteType = 'COVID';
      if (lane && lane.inventoryType?.toLowerCase().includes('flu')) siteType = 'Flu';

      if (siteType == vaccineType) {
        if (taskName.includes('2') || taskName.includes('3')) {
          if (!lane.inventoryName.includes('Johnson&Johnson')) {
            filteredLanes.push(lane);
          } else {
            // Skipp
          }
        } else {
          filteredLanes.push(lane);
        }
      }
    });
    return filteredLanes;
  }

  public getAvailableBookingDates(
    patientId: string,
    organizationId: string,
    vaccineName: string[] = [],
    taskId: string,
    contentType?: string,
  ): Observable<string[]> {
    let url = `/api/organizations/${organizationId}/clinic-appointments/open-days?targetPatientId=${patientId}&taskId=${taskId}`;
    if (vaccineName && vaccineName.length && vaccineName.length > 0) {
      url = `/api/organizations/${organizationId}/clinic-appointments/open-days?targetPatientId=${patientId}&taskId=${taskId}&vaccineType=${encodeURIComponent(
        vaccineName.join()
      )}`;
    } else {
      if (contentType)
        url = `/api/organizations/${organizationId}/clinic-appointments/open-days?targetPatientId=${patientId}&taskId=${taskId}&contentType=${encodeURIComponent(
          contentType
        )}`;
    }
    return this.networkService.fetchResource<string[]>(url, {});
  }

  //formatted Date in "MM/dd/yyyy"
  public getAppointmentBookingClinicDetailsByDate(
    patientId: string,
    organizationId: string,
    formattedDateStr: string,
    taskid: string,
  ): Observable<AppointmentForClinic[]> {
    const url = `/api/organizations/organizationublicClinics/${organizationId}/web?date=${formattedDateStr}&targetPatientId=${patientId}&age=${0}&taskId=${taskid}`;
    return this.networkService.fetchResource(url);
  }

  public getAvailableVaccineLanes(orgId: String, patientId: String = null): Observable<any> {
    let url = `/api/organizations/${orgId}/getAvailableVaccineLanes`;
    if (patientId)
      url = `${url}?patientId=${patientId}`;

    return this.networkService.fetchResource<string[]>(url);
  }

  public getPatientMini(id: string): Observable<Patient> {
    const url = `/api/patients/${id}/mini`;

    return this.networkService.fetchResource(url);
  }

  sendConsent(signatureData: string, consentSignatureName: string, taskId) {
    const consentApiURL = `/api/attachments/consent/task/${taskId}`;
    return firstValueFrom(this.networkService.postResource(consentApiURL, { data: signatureData, patientName: consentSignatureName }, {
      responseType: 'blob'
    }))
  }
}
