import { NetworkService } from 'src/app/core/services/network/network.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import {ApiService} from '../api/api.service';
import {catchError, map} from 'rxjs/operators';
import {MobileApiUserSession, UserSession} from '../../models/user-session.model';
import {UserSessionService} from './user-session.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OperationUserSessionService {
  private _isPatient = new BehaviorSubject<boolean>(false);
  set isPatient(value) {
    this._isPatient.next(value);
  }
  get isPatient() {
    return this._isPatient.getValue();
  }
  private patientData = new Subject<any>();

  private userSessionBehaviorSubject = new BehaviorSubject<UserSession>(undefined);

  notifyToSetUserInfo = (info: any) => this.patientData.next(info);
  listenToSetUserInfo = (): Observable<any>  => this.patientData.asObservable();
  constructor(private network: NetworkService, private userSession: UserSessionService) {}

  getUserSession(fetch: boolean = false): Observable<UserSession> {
    const session = this.userSession.getSession();

    this.userSession.token = JSON.parse(localStorage.getItem('keycloak'));

    if (session && !fetch) {
      return of(session);
    }

    if(this.network.getAuthToken() === null){
      return of(null);
    }

    return this.network.fetchResource(ApiService.fetchSession()).pipe(
      map((userSession: UserSession) => {
        Number(userSession.privilege.id) !== 4 ? (this.isPatient = false) : (this.isPatient = !userSession.isPerformer);

        this.userSession.setAccountSession(userSession);
        this.userSession.setIsLoggedIn(true);
        this.userSessionBehaviorSubject.next(userSession);
        return userSession;
      }),
      catchError(e => throwError(e.error))
    );
  }

  getUserSessionSubject(){
    if(this.userSessionBehaviorSubject.value === undefined){
      this.getUserSession(true);
    }
    return this.userSessionBehaviorSubject;
  }



  fetchMobileApiSessionForPatientWithoutLogin(headers): Observable<MobileApiUserSession>{
    headers['X-Role'] = 'patient';
    let apiUrl = '/api/session/mobile';
    return this.network.fetchResource(apiUrl, {headers});
  }

  //familyAccountId: pass value in this parameter if needed family member session data, else pass null
  //fetchFresh: if true, the cached session data will be refreshed
  getMobileApiSessionForPatient(familyAccountId: string, fetchFresh: boolean = false, contentLanguage?: string): Observable<MobileApiUserSession>{
    if(!this.isPatient){
      return of(null);
    }
    const session = this.userSession.getMobileApiSession();
    if(session && !fetchFresh){
      return of(session);
    }
    let headers: {[key: string]: any} = {};
    headers['X-Role'] = 'patient';
    if(contentLanguage) headers['Content-Language'] = contentLanguage;
    let apiUrl = '/api/session/mobile';
    if(familyAccountId){
      apiUrl += `?accountId=${familyAccountId}`;
    }
    return this.network.fetchResource(apiUrl, {headers}).pipe(
      map((session: MobileApiUserSession) => {
        this.userSession.setMobileApiAccountSession(session);
        return session;
      }),
      catchError(e => throwError(e.error))
    );
  }

  getVaccineNameMapping() {
    let apiUrl = '/api/masterData/getVaccineNameMapping';
    return this.network.fetchResource(apiUrl);
  }
}
