import {Injectable} from '@angular/core';
import {Link} from '../../models/link.model';

@Injectable({
  providedIn: 'root',
})
export class LinkUtil {

  public parseLink(links: any[], name: string): string {
    if (links && links.length) {
      let result = '';

      links.forEach((link) => {
        const linkRef = link.rel;

        if (linkRef === name) {
          result = link.href;
        }
      });

      return result;
    }

    return '';
  }

  public updateLink(links: Link[] = [], name: string, link: Link) {
    const linkIndex = links.findIndex((l) => l.rel === name);

    if (linkIndex >= 0) {
      links[linkIndex] = link;
    } else {
      links.push(link);
    }

    return links;
  }
}
