import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: any[] = [];
  @Output() modalData = new EventEmitter<any>();

  closed: Subject<any> = new Subject<any>();

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.getModal().id !== id);
  }

  // takes id and data as parameters
  // data can be empty and doesn't have to be passed
  // opens the modal
  open(id: string, data?: any) {
    // open modal specified by id
    if (data !== null && data !== undefined) this.modalData.emit(data);

    const modal: any = this.modals.filter((x) => x.getModal().id === id)[0];
    modal.open(id);
  }

  close(id: string) {
    // close modal specified by id
    const modal: any = this.modals.filter((x) => x.getModal().id === id)[0];
    modal.close();
  }
}
