import {Injectable} from '@angular/core';
import {ObservableStore} from '@codewithdan/observable-store';
import {Assessment} from '../../core/models/assessment.model';
import {Observable, of as observableOf, Subject, throwError} from 'rxjs';
import {AssessmentService} from '../../core/services/assessment/assessment.service';
import {catchError, map} from 'rxjs/operators';
import {SSAssessmentDashboard, StoreState} from '../../core/store/store-state';
import {
  AssessmentOverviewService,
  AssessmentOverviewStateActions,
} from './assessment-overview/assessment-overview.service';

const initiateState: SSAssessmentDashboard = {
  assessments: null,
  totalAssessmentCount: null,
  ownerFilter: null,
};

@Injectable({
  providedIn: 'root',
})
export class AssessmentsService extends ObservableStore<StoreState> {
  assessmentDeleted = new Subject<void>();

  constructor(
    private assessmentService: AssessmentService,
    private assessmentOverviewService: AssessmentOverviewService
  ) {
    super({});
    this.setState({assessmentDashboard: initiateState}, AssessmentOverviewStateActions.InitState, false);
  }

  fetchAssessments(organizationId: string, params): Observable<any> {
    return this.assessmentService.getAssessments(organizationId, params).pipe(
      map((res) => {
        const {
          assessmentList: {content: assessments},
          ownerFilter,
          totalAssessmentCount,
        } = res;
        const assessmentDashboard = {
          assessments,
          totalAssessmentCount,
          ownerFilter: Object.keys(ownerFilter).map((k) => ({id: k, name: ownerFilter[k]})),
        };

        this.setState({assessmentDashboard}, AssessmentsStateActions.GetAssessments);

        return assessmentDashboard;
      }),
      catchError(this.handleError)
    );
  }

  getAssessments(organizationId: string, params) {
    const {assessmentDashboard} = this.getState();

    if (assessmentDashboard && assessmentDashboard.assessments) {
      return observableOf(assessmentDashboard);
    } else {
      return this.fetchAssessments(organizationId, params);
    }
  }

  getAssessmentsFromStore(): Assessment[] | null {
    const state = this.getState();

    if (state && state.assessmentDashboard && state.assessmentDashboard.assessments) {
      return this.getState().assessmentDashboard.assessments;
    }

    return null;
  }

  addAssessment(assessment: Assessment): void {
    const state = this.getState();
    if (!assessment['lastEdited']) {
      assessment['lastEdited'] = Date.now();
    }

    if (state.assessmentDashboard && state.assessmentDashboard.assessments) {
      state.assessmentDashboard.assessments.push(assessment);
      this.setState({assessmentDashboard: state.assessmentDashboard}, AssessmentsStateActions.AddAssessment);
    }
  }

  editAssessment(assessment: Assessment) {
    const state = this.getState();

    if (state) {
      this.assessmentOverviewService.updateAssessment(assessment);

      if (state.assessmentDashboard && state.assessmentDashboard.assessments) {
        const index = state.assessmentDashboard.assessments.findIndex((a) => a.id === assessment.id);
        assessment['lastEdited'] = Date.now();
        if (index >= 0) {
          state.assessmentDashboard.assessments[index] = assessment;
          this.setState({assessmentDashboard: state.assessmentDashboard}, AssessmentsStateActions.EditAssessments);
        }
      }
    }
  }

  deleteAssessment(assessment: Assessment) {
    const state = this.getState();

    if (state && state.assessmentDashboard && state.assessmentDashboard.assessments) {
      const index = state.assessmentDashboard.assessments.findIndex((a) => a.id === assessment.id);

      if (index >= 0) {
        state.assessmentDashboard.assessments.splice(index, 1);
        this.setState({assessmentDashboard: state.assessmentDashboard}, AssessmentsStateActions.DeleteAssessment);
      }
    }

    this.assessmentDeleted.next();
  }

  clean() {
    this.setState({assessmentDashboard: initiateState}, 'clean_assessments');
  }

  handleError(error: any) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}

export enum AssessmentsStateActions {
  GetAssessments = 'get_assessments',
  AddAssessment = 'add_assessment',
  EditAssessments = 'edit_assessments',
  DeleteAssessment = 'delete_assessment',
}
