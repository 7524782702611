import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

const API = {
  authStr: `${environment.apiAuthUrl}/auth/realms/CuraPatient-Dev/protocol/openid-connect/token`,
  ssoAuthURL: `${environment.apiAuthUrl}/auth/realms/CuraPatient-Dev/protocol/openid-connect/auth`,
  ssoTokenURL: `${environment.apiUrl}/api/sso/token`,
  passResetStr: '/api/accounts/{accountId}/{password}/{oldPassword}',
  invalidateStr: '', // use this to invalidate the current user's session
  userStr: '/api/accounts/currentUser',
  doctorSession: '/api/session/web', /// {{cp_url}}/api/session/doctor
  updateCareplan: '/api/carePlans/{careplanId}',
  metaData: '/api/meta/REPLACE_ME',
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  static getAuthorizationLogin(): string {
    return API.authStr;
  }

  static getSSOAuthUrl() {
    return API.ssoAuthURL
  }

  static getSSOTokenUrl() {
    return API.ssoTokenURL;
  }

  // public invalidateSession(): string {
  //   localStorage.clear();
  //   return API.invalidateStr;
  // }

  static fetchSession(): string {
    return API.doctorSession;
  }

  static updateCareplans(id: string): string {
    return API.updateCareplan.replace('{careplanId}', id);
  }

  static addMetaData(meta: string): string {
    return API.metaData.replace('REPLACE_ME', meta);
  }

  // public updateMetaData(meta: string, id: string): string {
  //   return API.metaData.replace('REPLACE_ME', meta) + '/' + id;
  // }
}
