import {EventEmitter, Injectable, Output} from '@angular/core';
import {NetworkService} from '../network/network.service';
import {
  CarePlanGoal,
  CarePlansResponse,
  CarePlanTask,
  CarePlanTaskGroup,
  CarePlanTemplates,
  NewCarePlan,
  PlanTemplate,
} from '../../models/careplan.model';

import {LinkUtil} from '../../utils/link-util/link-util.service';
import {DateUtility} from '../../utils/date-utility';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {ImageService} from '../image/image.service';
import {TaskTypeModel} from '../../models/task-type';
import {ResponseWithLinks} from '../../models/common.model';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

interface CarePlansModel {
  carePlanList: ResponseWithLinks<CarePlanTemplates>;
  carePlanTypeFilters: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CarePlanService {
  @Output() patientBioData: EventEmitter<string> = new EventEmitter();
  @Output() patientComplianceData: EventEmitter<any> = new EventEmitter();
  @Output() carePlanProgressData: EventEmitter<any> = new EventEmitter();
  @Output() apptListIndex: EventEmitter<number> = new EventEmitter<any>();

  private carePlanOverviewData: NewCarePlan;
  chartData: any;

  constructor(
    private network: NetworkService,
    private linkUtil: LinkUtil,
    private dateUtil: DateUtility,
    private data: DataBrokerService,
    private imageService: ImageService
  ) {}

  static populateGroupedTasks(carePlan: NewCarePlan): NewCarePlan {
    const {goals} = carePlan;

    goals.map((goal: CarePlanGoal) => {
      const {tasks} = goal;
      const groupedTasks = [];

      tasks.forEach((task) => {
        const {taskType, taskTypeId}: CarePlanTask = task;
        const groupIndex = groupedTasks.findIndex((group) => group.name === taskType);

        if (groupIndex >= 0) {
          groupedTasks[groupIndex].tasks.push(task);
        } else {
          let oldGroupedTasks;
          if (goal.groupedTasks) {
            oldGroupedTasks = goal.groupedTasks.find((gt) => gt.id === taskTypeId);
          }

          const taskGroup: CarePlanTaskGroup = {
            name: taskType,
            id: taskTypeId,
            tasks: [task],
            isDisplay: oldGroupedTasks ? oldGroupedTasks.isDisplay : null,
          };

          groupedTasks.push(taskGroup);
        }
      });
      goal.groupedTasks = groupedTasks;

      return goal;
    });

    return carePlan;
  }

  public setCarePlanOverview(careplan: NewCarePlan) {
    this.carePlanOverviewData = careplan;
  }

  public getCarePlanOverview<T>(carePlanUrl: string): Observable<T> {
    this.setCarePlanOverview(null);

    return this.network.fetchResource<T>(carePlanUrl).pipe(
      mergeMap((carePlan) => {
        return this.imageService.addImage(carePlan, 'carePlanImage', 100);
      })
    );
  }

  public removeCarePlan(carePlanId: string) {
    const link = `/api/carePlans/${carePlanId}`;

    return this.network.deleteResource(link);
  }

  public deleteCarePlanFromPatientByTaskId(taskId: string){
    const url = '/api/carePlans/deletePatientCarePlanFromTaskId'
    let params = new HttpParams()
    .set('taskId', taskId)
    return this.network.deleteResource<void>(url,{params});
  }

  public createTask(task: CarePlanTask): Observable<CarePlanTask> {
    const link = '/api/carePlans/task';

    return this.network
      .postResource<CarePlanTask>(link, task)
      .pipe(mergeMap((res) => this.imageService.addImage(res, 'image', 100)));
  }

  public completeTeamTask(taskId: string): Observable<CarePlanTask> {
    const link = `/api/tasks/${taskId}/complete`;

    return this.network.putResource(link, {});
  }

  public activateTeamTask(taskId: string): Observable<CarePlanTask> {
    const link = `/api/tasks/${taskId}/activate`;

    return this.network.putResource(link, {});
  }

  public updateTask(task: CarePlanTask): Observable<CarePlanTask> {
    const link = `/api/carePlans/task/${task.id}`;

    return this.network.putResource(link, task);
  }

  public removeTask(careplanId: string, taskId: string): Observable<CarePlanTask> {
    const link = `/api/carePlans/${careplanId}/task/${taskId}`;

    return this.network.deleteResource(link);
  }

  public getCarePlanTypes() {
    return this.network.fetchResource<any>('/api/carePlans/carePlanTypes');
  }

  public preparePlanForSaving(careplan) {
    for (const goal of careplan.goals) {
      if (goal.id && goal.id.startsWith('temp_id_')) {
        delete goal.id;
      }

      for (const task of goal.tasks) {
        delete task.goalId;
      }

      delete goal.groupedTasks;
    }
  }

  public populateUngroupedTasks(careplan) {
    // also save tasks into ungrouped tasks
    const isCreateCarePlan = !careplan.id && careplan.isTemplate && !careplan.usedTemplateId;
    const isOnboarding = careplan.id && careplan.isTemplate && careplan.usedTemplateId;

    for (const goal of careplan.goals) {
      if (isCreateCarePlan || isOnboarding) {
        delete goal.id; // necessary
      }

      // start fresh
      goal.tasks = [];
      let tasks = [];

      // grab all tasks from each grouped tasks
      if (goal.groupedTasks) {
        for (const group of goal.groupedTasks) {
          tasks = tasks.concat(group.tasks.filter((t) => t.name));
        }
      }

      // add grabbed tasks to list
      goal.tasks = goal.tasks.concat(tasks);
    }

    // go through all tasks and edit them for saving
    for (const goal of careplan.goals) {
      if (goal.tasks && goal.tasks.length > 0) {
        for (const task of goal.tasks) {
          delete task.image;

          if (isCreateCarePlan || isOnboarding) {
            delete task.id; // necessary
          }

          if (isOnboarding && task.therapyType) {
            // For the therapies that we didn't modify, we need to set the used template id
            if (!task.therapyType.usedTemplateId) {
              task.therapyType.usedTemplateId = task.therapyTypeId;

              delete task.therapyTypeId;
            } else {
              console.log('need an alternate case');
            }
            task.therapyType.id = null; // don't send therapyId when onboarding patient
          }

          if (task.location) {
            task.locationId = task.location.id;
          }

          if (task.observationGoals && task.observationGoals.length > 0) {
            for (const observation of task.observationGoals) {
              if (observation.id) {
                observation.observationTypeId = parseInt(observation.observationType.id, 10);
                if (isCreateCarePlan || isOnboarding) {
                  delete observation.id; // necessary
                }
              }
            }
          }
        }
      }
    }
  }

  public getTaskTypes(): Observable<TaskTypeModel[]> {
    return this.network.fetchResource<TaskTypeModel[]>('/api/meta/task');
  }

  public getTherapyTypesImage(therapyTypes: any[]) {
    const therapyTypesWithImage = therapyTypes.map((tT) => {
      const link = this.linkUtil.parseLink(tT.links, 'image');

      if (link) {
        return this.imageService.getImageNew(link, 100).pipe(
          map((image) => ({...tT, image: image ? image : ''})),
          catchError(() => of({...tT, image: ''}))
        );
      } else {
        return of({...tT, image: ''});
      }
    });

    return forkJoin(therapyTypesWithImage);
  }

  public getCarePlanList(performerId: string): Observable<CarePlansModel> {
    const link = `/api/performers/${performerId}/care-plans`;
    return this.network.fetchResource(link);
  }

  public getCarePlanByUrl(link): Observable<CarePlansResponse> {
    return this.network.fetchResource(link);
  }

  public getCarePlan(carePlanId: string, withoutImage?: boolean) {
    const link = `/api/carePlans/${carePlanId}`;

    if(withoutImage){
      return this.network.fetchResource(link);
    }

    return this.network
      .fetchResource(link)
      .pipe(mergeMap((carePlan) => this.imageService.addImage(carePlan, 'carePlanImage', 100)));
  }

  // NEW
  getCarePlanWithImage(patientId: string = null): Observable<NewCarePlan> {
    const link = `/api/carePlans/${patientId}`;

    return this.network.fetchResource(link);
  }

  getCarePlanTemplates(): Observable<ResponseWithLinks<PlanTemplate | {name: string}>> {
    const link = `/api/carePlans/templates`;

    return this.network.fetchResource(link);
  }

  patientCarePlanFromTemplate(body): Observable<NewCarePlan> {
    const url = `/api/carePlans/patient-care-plan-from-template`;

    return this.network.postResource(url, body);
  }

  setCarePlanStatus(carePlanId: string, body): Observable<string> {
    const url = `/api/carePlans/${carePlanId}/care-plan-status`;

    return this.network.postResource(url, body);
  }

  addObservation(body) {
    const url = `/api/observations`;

    return this.network.postResource(url, body);
  }

  getPublicTemplateCarePlans({
    stats = true,
    page = 0,
    size = 20,
    orderType = 'name',
    asc = true,
    name = '',
    carePlanType = '',
  }): Observable<any> {
    const url = '/api/carePlans/public-templates';
    let params = new HttpParams()
      .set('stats', stats.toString())
      .set('page', page.toString())
      .set('size', size.toString())
      .set('orderType', orderType.toString())
      .set('asc', asc.toString())
      .set('name', name.toString())
      .set('carePlanType', carePlanType.toString());

    return this.network.fetchResource(url, {params});
  }

  getCarePlansByScore(score: number): {name: string; goalName?: string}[] {
    let result: {name: string; goalName?: string}[] = [];
    const vaccine = environment.carePlans.find((cP) => cP.type === 'vaccine');
    const thirdDose = environment.carePlans.find((cP) => cP.type === 'thirdDose');
    const fluShot = environment.carePlans.find((cP) => cP.type === 'fluShot');
    const boosterDose = environment.carePlans.find((cP) => cP.type === 'boosterDose');

    if (score) {
      if (score >= 100) {
        result.push(vaccine);
        score -= 100;
      }
      if (score >= 60) {
        result.push(fluShot);
        score -= 60;
      }
      if (score == 20) {
        result.push(thirdDose);
      } else if (score == 10) {
        result.push(boosterDose);
      }

      return result;
    }

    return [];
  }

  getEnvironmentCarePlansByType(type: string[]): any[] {
    const carePlans = environment.carePlans.filter((cP) => type.includes(cP.type));

    if (carePlans.length) {
      return carePlans;
    } else {
      throw new Error(`Can't find care plan with type ${type}`);
    }
  }
}
