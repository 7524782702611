import {Injectable} from '@angular/core';
import {LanguageIntr} from '../../classes/language/language.interface';
import {EnglishLanguageModel} from '../../classes/language/english.model';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {ChineseLanguageModel} from '../../classes/language/chinese.model';
import {LanguageType} from '../../classes/language/language.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public language: LanguageIntr;

  constructor(private data: DataBrokerService) {
    // this.language = new EnglishLanguageModel();
    this.language = new EnglishLanguageModel(); // Forced chinese for initialization purposes
  }

  public setLanguage(language: LanguageType) {
    switch (language) {
      case LanguageType.english:
        this.language = new EnglishLanguageModel();
        break;
      case LanguageType.chinese:
        this.language = new ChineseLanguageModel();
        break;
      default:
        this.language = new EnglishLanguageModel();
    }

    this.data.publish<any>(this.language, 'language-update');
  }

  public getCurrLanguage(): string {
    return this.language.language_type;
  }
}
