import {Injectable} from '@angular/core';
import {NetworkService} from '../network/network.service';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {forkJoin, Observable, Observer, of} from 'rxjs';
import {LinkUtil} from '../../utils/link-util/link-util.service';
import {catchError, defaultIfEmpty, map, mergeMap, publishReplay, refCount} from 'rxjs/operators';
import {ImageService} from '../image/image.service';
import {UserSessionService} from '../user-session/user-session.service';
import {UserSession} from '../../models/user-session.model';
import {DateUtility} from '../../utils/date-utility';
import {Appointment} from '../../models/appointment.model';
import {Provider, ProviderResponse} from '../../models/provider.model';
import {ORGANIZATION_ID} from '../../../modules/organizations/services/org-overview.service';
import global from '../../../../config';

const TIMEOUT_API = global.TIMEOUT_API;

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  apiUrl = '/api/performers';
  private providerListLink: string;
  private provider$: Observable<Provider>;
  private resetTimeProvider = 0;

  constructor(
    private network: NetworkService,
    private linkUtil: LinkUtil,
    private imageService: ImageService,
    private data: DataBrokerService,
    private userSession: UserSessionService
  ) {}

  setProviderListUrl(link: string) {
    this.providerListLink = link;
  }

  public getAvailableLocations(organizationId: string) {
    const link = `/api/organizations/${organizationId}/locations`;

    return this.network.fetchResource(link);
  }

  public getProviderNotifications(link) {
    return this.network
      .fetchResource(link)
      .pipe(
        mergeMap(({content, links}) => {
          const notifications: any[] = content.map((c) => {
            const actorImgLink = this.linkUtil.parseLink(c.links, 'actorImg');

            if (actorImgLink) {
              return this.imageService.getImageNew(actorImgLink, 100).pipe(
                map((actorImg) => ({...c, actorImg})),
                catchError(() => of({...c, actorImg: ''}))
              );
            }
            return of(c);
          });

          if (content.length) {
            return forkJoin(notifications).pipe(map((iContent: any[]) => ({content: iContent, links})));
          }
          return of({content, links});
        }),
        defaultIfEmpty({content: [], links: null})
      )
      .pipe(
        mergeMap(({content, links}) => {
          const notifications = content.map((c) => {
            const relatedAccountImgLink = this.linkUtil.parseLink(c.links, 'relatedAccountImg');

            if (relatedAccountImgLink) {
              return this.imageService.getImageNew(relatedAccountImgLink, 100).pipe(
                map((relatedAccountImg) => ({...c, relatedAccountImg})),
                catchError(() => of({...c, relatedAccountImg: ''}))
              );
            }
            return of(c);
          });

          if (content.length) {
            return forkJoin(notifications).pipe(map((iContent: any) => ({content: iContent, links})));
          }
          return of({content, links});
        }),
         defaultIfEmpty({content: [], links: null})
      );
  }

  public replyToRequest({activity, ifAccept}) {
    const replyToRequestUrl = this.linkUtil.parseLink(activity.links, 'replyToRequest');

    this.network
      .putResource<any>(replyToRequestUrl, {ifAccept, activityId: activity.id})
      .subscribe(() => {
        this.data.publish(activity, 'reply-to-appointment-request');
      });
  }

  public getProviders(serviceTypeId) {
    // const providerLink = this.linkUtil.parseLink(links, 'serviceProviders');
    // this.getUrl(this.session.sessionAccount.links, 'serviceProviders'); // TODO: Fetch url from session
    const serviceLink = '/api/performers/serviceProviders';
    const serviceUrl = `${serviceLink}?serviceTypeId=${serviceTypeId}`;
    return this.network.fetchResource(serviceUrl).pipe(
      mergeMap((response: any[]) => {
        const providers = response.map((provider) => {
          const providerImageLink = this.linkUtil.parseLink(provider.links, 'providerImage');

          return this.imageService.getImageNew(providerImageLink, 100).pipe(
            map((providerImage) => ({...provider, providerImage})),
            catchError(() => of({...provider, providerImage: ''}))
          );
        });
        return forkJoin(providers);
      }),
      defaultIfEmpty([])
    );
  }

  public getSelectedProvider(link?: string): Observable<Provider> {
    const currentProviderLink = link ? link : localStorage.getItem('current-provider');

    return this.provider(currentProviderLink);
  }

  getProviderById(id: string): Observable<Provider> {
    return this.network.fetchResource<Provider>(`${this.apiUrl}/${id}`);
  }

  public createProvider(provider): Observable<Provider> {
    const link = '/api/performers';

    return this.provider(link, 'postResource', provider);
  }

  public updateProvider(provider): Observable<Provider> {
    const link = `/api/performers/profile/${provider.id}`;

    delete provider.id;
    delete provider.links;

    return this.provider(link, 'putResource', provider);
  }

  public deleteProvider(provider): Observable<any> {
    const link = `/api/performers/${provider.id}`;

    return this.network.deleteResource(link);
  }

  private provider(link, type = 'fetchResource', provider?: any): Observable<Provider> {
    const timeExpired = this.resetTimeProvider + TIMEOUT_API < +new Date();

    if (timeExpired) this.clearProvider();

    if (!this.provider$) {
      this.provider$ = this.network[type](link, provider).pipe(
        map((iProvider: any) => {
          provider = iProvider || {};
          provider.specialtiesObj = iProvider.specialties && iProvider.specialties.length ? iProvider.specialties : [];

          return iProvider;
        }),
        mergeMap((iProvider: any) => {
          let url = this.linkUtil.parseLink(iProvider.links, 'providerImage');
          if (url === '') {
            url = this.linkUtil.parseLink(iProvider.links, 'image');
          }

          return this.imageService.getImageNew(url, 300).pipe(
            map((profileImage) => {
              return {...iProvider, profileImage};
            }),
            catchError(() => of({...iProvider, profileImage: ''}))
          );
        }),
        publishReplay(1),
        refCount()
      );
    }

    return this.provider$;
  }

  public clearProvider() {
    this.provider$ = null;
  }

  isOwner(ownerId?: string): Observable<boolean> {
    return new Observable<UserSession>((observer: Observer<any>) => {
      if (this.userSession.sessionAccount) {
        observer.next(this.userSession.sessionAccount);
      } else {
        observer.next(null);
      }
    }).pipe(mergeMap((sessionAccount: UserSession) => of(sessionAccount.performerId === ownerId)));
  }

  public getOrganizationTypes() {
    const link = `/api/organizations/all`;

    return this.network.fetchResource(link);
  }

  public getAppointmentsForMonth(performerId, date, isSuperAdmin: boolean): Observable<Appointment[]> {
    const {startOfMonth, endOfMonth} = DateUtility.getMonthByDate(date);
    const organizationId = localStorage.getItem(ORGANIZATION_ID);
    const link =
      isSuperAdmin && organizationId
        ? `/api/performers/organization/${organizationId}/appointments?dateFrom=${startOfMonth}&dateTo=${endOfMonth}`
        : `/api/performers/${performerId}/appointments?dateFrom=${startOfMonth}&dateTo=${endOfMonth}`;

    return this.network.fetchResource(link).pipe(
      mergeMap((appointments: Appointment[]) => {
        if (appointments.length) {
          const tasks = appointments.map((c) => {
            const patientImgLink = this.linkUtil.parseLink(c.links, 'patientImg');

            if (patientImgLink) {
              return this.imageService.getImageNew(patientImgLink).pipe(
                map((patientImg) => ({...c, patientImg})),
                catchError(() => of(c))
              );
            }
            return of(c);
          });

          return forkJoin(tasks);
        }
        return of([]);
      })
    );
  }

  public getProviderList(link): Observable<ProviderResponse> {
    return this.network.fetchResource(link);
  }
}
