import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Socket} from 'ngx-socket-io';
import {filter, map} from 'rxjs/operators';
import {ActivityFeedModel, ActivityResourceModel} from '../../models/activity.model';
import {PatientService} from '../../../modules/patients/services/patient.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

interface ActivityMessage {
  filter: string;
  performerId: string;
  token: string;
}

interface NotificationMessage {
  accountId: string;
  token: string;
}

export const ACTIVITIES_UPDATE = ['ASSESSMENT_REPORT', 'QUARANTINE_CONTACT', 'LOCATION', 'NOTE'];
const PATIENT = 'PATIENT';
const DOCTOR = 'DOCTOR';
const NOTE = 'NOTE';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private _activityTypes = new BehaviorSubject<string[]>(ACTIVITIES_UPDATE);

  _discard = false;

  activities$ = new BehaviorSubject<ActivityFeedModel[]>([]);
  newCovidContacts$ = new Subject<ActivityFeedModel>();
  newCovidLocations$ = new Subject<ActivityFeedModel>();
  newCovidActivity$ = new Subject<ActivityFeedModel>();
  newDailyAssessmentReport = new Subject<ActivityFeedModel>();

  set activityTypes(value) {
    this._activityTypes.next(value);
  }

  get activityTypes() {
    return this._activityTypes.getValue();
  }

  discard() {
    this._discard = true;
  }

  constructor(private socket: Socket, private patientService: PatientService) {}

  public connect() {
    this.socket.connect();
  }

  public sendActivities(message: ActivityMessage): void {
    if (!this._discard) {
      this.socket.emit('ACTIVITY', message);
    }
  }

  public sendLoad(message: {organizationId: string; token: string}): void {
    if (!this._discard) {
      this.socket.emit('CSV_IMPORT_PROGRESS', message);
    }
  }

  public getLoadedFilesInfo(): Observable<{
    totalRows: number;
    importedRows: number;
    skippedDuplicatedRows: number;
    failedRows: number;
    isFinished: boolean;
  }> {
    return this.socket.fromEvent<{
      totalRows: number;
      importedRows: number;
      skippedDuplicatedRows: number;
      failedRows: number;
      isFinished: boolean;
    }>('CSV_IMPORT_PROGRESS');
  }

  public removeListenerCVS() {
    this.socket.removeListener('CSV_IMPORT_PROGRESS');
  }

  public getActivities() {
    if (!this._discard) {
      this.socket
        .fromEvent('ACTIVITY')
        .pipe(
          untilDestroyed(this),
          map((activities: ActivityResourceModel[]) => {
            if (activities.length > 1) {
              this.activities$.next(activities); // activities for message drawer
            } else {
              // new activity
              this.addNewActivity(activities[0]);
            }
          })
        )
        .subscribe();
    }
  }

  addNewActivity(activityResource: ActivityResourceModel) {
    const covidActivity = this.selectCovidActivity(activityResource);

    // if (covidActivity) {
    //   this.patientService.getActivityFull(covidActivity.id).subscribe((activity: ActivityFeedModel) => {
    //     console.log("websocket");
    //     switch (covidActivity.entityType) {
    //       case 'QUARANTINE_CONTACT':
    //         this.newCovidContacts$.next(activity.quarantineContactResource);
    //         break;
    //       default:
    //         this.newCovidActivity$.next(activity);
    //     }
    //   });
    // }

    // this is a temporary fix for 404 error. Original code above
    setTimeout(() => {
      if (covidActivity) {
        this.patientService.getActivityFull(covidActivity.id).subscribe((activity: ActivityFeedModel) => {
          switch (covidActivity.entityType) {
            case 'ASSESSMENT_REPORT':
              if (activity.assessmentReportResource.assessmentName.includes('Daily')) {
                this.newDailyAssessmentReport.next(activity);
              } else {
                this.newCovidActivity$.next(activity);
              }
              break;
            // case 'TASK':
            //   this.newAppointmentTask$.next(activity.taskResource);
            //   break;
            case 'QUARANTINE_CONTACT':
              this.newCovidContacts$.next(activity);
              break;
            case 'LOCATION':
              this.newCovidLocations$.next(activity);
              break;
            default:
              this.newCovidActivity$.next(activity);
          }
        });
      }
    }, 2000);
  }

  selectCovidActivity(activityResource: ActivityResourceModel): ActivityResourceModel {

    if(!activityResource || typeof activityResource !== 'object') return null;

    const {actorAccountRole, entityType, entityName} = activityResource;
    const isDefinedType = this.activityTypes.includes(entityType);

    let isPatient = false;
    let isDoctor = false;

    if(actorAccountRole){
      isPatient = actorAccountRole === PATIENT;
      isDoctor = actorAccountRole === DOCTOR;
    }

    const isTypeNote = entityType === NOTE;
    const isTypeAssessmentReport = entityType === 'ASSESSMENT_REPORT';
    const isObservation = entityType === 'OBSERVATION';
    const isCovidLab19 = entityName === 'COVID19LAB';
    const isTypeMedRec = entityType === 'MEDICATION_REPORT';
    const isTypeTask = entityType === 'TASK';

    if (
      isDefinedType &&
      (isPatient ||
        (isDoctor &&
          (isTypeTask || isTypeMedRec || isTypeNote || isTypeAssessmentReport || (isObservation && isCovidLab19))))
    ) {
      return activityResource;
    }
    return null;
  }

  public getCovidActivity({activityTypes = ACTIVITIES_UPDATE} = {}): Observable<ActivityFeedModel> {
    return this.socket.fromEvent('ACTIVITY').pipe(
      filter(([{actorAccountRole, entityType, entityName}]: ActivityResourceModel[]) => {
        const isDefinedType = activityTypes.includes(entityType);
        const isPatient = actorAccountRole === PATIENT;
        const isDoctor = actorAccountRole === DOCTOR;
        const isTypeNote = entityType === NOTE;
        const isTypeAssessmentReport = entityType === 'ASSESSMENT_REPORT';
        const isObservation = entityType === 'OBSERVATION';
        const isCovidLab19 = entityName === 'COVID19LAB';
        const isTypeMedRec = entityType === 'MEDICATION_REPORT';
        const isTypeTask = entityType === 'TASK';

        return (
          isDefinedType &&
          (isPatient ||
            (isDoctor &&
              (isTypeTask || isTypeMedRec || isTypeNote || isTypeAssessmentReport || (isObservation && isCovidLab19))))
        );
      }),
      map(([activityResource]: ActivityResourceModel[]) => ({activityResource}))
    );
  }

  public sendNotifications(message: NotificationMessage): void {
    if (!this._discard) {
      this.socket.emit('NOTIFICATION', message);
    }
  }

  public getNotifications(): Observable<any> {
    if (!this._discard) {
      return this.socket.fromEvent('NOTIFICATION');
    }
  }

  public disconnect() {
    this.socket.disconnect();
  }

  public clearActivities() {
    this.activities$ = new BehaviorSubject<ActivityFeedModel[]>([]);
  }
}
