import {Injectable} from '@angular/core';
import {AdminInterface, Organization, OrganizationModel} from '../../models/organization.model';
import {NetworkService} from '../network/network.service';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, mergeMap, tap} from 'rxjs/operators';
import {LinkUtil} from '../../utils/link-util/link-util.service';
import {ImageService} from '../image/image.service';
import {DepartmentModel} from '../../models/provider.model';
import {LocationTypesModel, LocationTypesResponse} from '../../models/location-types.model';
import {Link} from '../../models/link.model';
import {BreadcrumbService} from '../breadcrumb/breadcrumb.service';
import {ResponseWithLinks} from '../../models/common.model';
import {PerformerModel} from '../../models/performer.model';
import {environment} from '../../../../environments/environment';
import {format} from 'date-fns';
import {Branch} from '../../classes/branch.class';
import {HttpUrlEncodingCodec} from '../authorization/http-url-encoding-codec';
import {AppointmentForClinic} from 'src/app/modules/organizations/org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';
import {ORGANIZATION_TAB_ID} from '../../../modules/organizations/organizations.component';
import {OrganizationTypes} from '../../../shared/enums/organization';

export class ExtendedClinic extends Branch {
  selected?: boolean;
}

export interface CheckOrgIdResponse {
  type: OrganizationTypes;
  reason: string;
  orgId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private organization: Organization;
  private patientGroups: Organization[];

  private departments: DepartmentModel[];
  private locationTypes: {content: LocationTypesModel[]; links: Link[]};

  public userSearchSource = new BehaviorSubject(null);

  // URLs
  private getOrganizationUrl: string;
  private getOrganizationsUrl: string;
  private _numberOfPatient = new Subject<number>();

  private organisationUpdated = new Subject<boolean>();
  private showOnlyTopBarLayout = new Subject<boolean>();

  private appointmentForClinic = new Subject<AppointmentForClinic[]>();
  private isAdminUser = new Subject<boolean>();
  private _setTabId = new BehaviorSubject<string>(null);

  notifyOrganisationUpdated = (info: any) => this.organisationUpdated.next(info);
  listenOrganisationUpdated = (): Observable<any> => this.organisationUpdated.asObservable();
  notifyshowOnlyTopBarLayout = (info: any) => this.showOnlyTopBarLayout.next(info);
  listenshowOnlyTopBarLayout = (): Observable<any> => this.showOnlyTopBarLayout.asObservable();
  notifyAppointmentForClinic = (info: any) => this.appointmentForClinic.next(info);
  listenAppointmentForClinic = (): Observable<any> => this.appointmentForClinic.asObservable();
  notifyIsAdminUser = (info: boolean) => this.isAdminUser.next(info);
  listenIsAdminUser = (): Observable<boolean> => this.isAdminUser.asObservable();
  setTabId = (info: string) => this._setTabId.next(info);
  getTabId = (): Observable<string> => this._setTabId.asObservable();

  _checkOrgIdResponse = new BehaviorSubject<CheckOrgIdResponse>(null);
  checkOrgIdResponse$ = this._checkOrgIdResponse.asObservable();
  set checkOrgIdResponse(value) {
    this._checkOrgIdResponse.next(value);
  }
  get checkOrgIdResponse() {
    return this._checkOrgIdResponse.getValue();
  }

  constructor(
    private network: NetworkService,
    private http: HttpClient,
    private linkUtil: LinkUtil,
    private imageService: ImageService,
    private breadcrumbService: BreadcrumbService
  ) {}

  fetchCheckOrgId(orgId: string): Observable<CheckOrgIdResponse> {
    const url = `/api/organizations/${orgId}/checkOrgId`;

    return this.network.fetchResource<CheckOrgIdResponse>(url).pipe(tap((res) => (this.checkOrgIdResponse = {...res, orgId})));
  }

  getCheckOrgId(orgId: string): Observable<CheckOrgIdResponse> {
    if (this.checkOrgIdResponse?.orgId == orgId) {
      return this.checkOrgIdResponse$;
    }

    return this.fetchCheckOrgId(orgId);
  }

  setOrganization(organization: Organization) {
    localStorage.setItem(ORGANIZATION_TAB_ID, '');
    this.organization = organization;
  }

  clearOrganization() {
    this.organization = null;
    this.breadcrumbService.reset();
  }

  // url for GET single organization
  setGetOrganizationUrl(url: string) {
    this.getOrganizationUrl = url;
  }

  // REMOVE

  removeOrganization() {
    this.organization = null;
  }

  removeGetOrganizationUrl() {
    this.getOrganizationUrl = null;
  }

  removeGetOrganizationsUrl() {
    this.getOrganizationsUrl = null;
  }

  setPatientGroups(patientGroups: Organization[]) {
    this.patientGroups = patientGroups;
  }

  getPatientGroups(): Organization[] {
    return this.patientGroups;
  }

  getNumberOfPatient = (): Observable<number> => this._numberOfPatient.asObservable();

  reset() {
    this.removeOrganization();
    this.removeGetOrganizationUrl();
    this.removeGetOrganizationsUrl();
  }

  public createOrganization(organization: Organization): Observable<Organization> {
    const link = '/api/organizations';

    // It's possible below has some purpose I am unaware of, but commenting out for issue #2687.
    // if this causes problems just uncomment :)
    // delete organization.organizationImage;

    const organization$ = this.network.postResource<Organization>(link, organization);
    if (
      organization.organizationImage &&
      organization.organizationImage !== 'DELETE' &&
      organization.type !== OrganizationTypes.Facility
    ) {
      const {organizationImage} = organization;

      return organization$.pipe(
        mergeMap((res: Organization) => {
          return this.imageService
            .postImage('organizations', res.id, organizationImage)
            .pipe(map(() => this.organization));
        })
      );
    }
    return organization$;
  }

  fetchOrgOverview(orgId: string): Observable<OrganizationModel> {
    const url = `/api/organizations/${orgId}/overview`;
    return this.network.fetchResource<OrganizationModel>(url);
  }

  fetchOrgBasicOverview(orgId: string): Observable<OrganizationModel> {
    const url = `/api/organizations/${orgId}/basicInfo`;
    return this.network.fetchResource<OrganizationModel>(url);
  }

  public createOrganizationWithAdminWithoutCredentials(organization, admin): Observable<Organization> {
    const url = environment.apiUrl + `/api/organizations/withAdmin`;
    const body = {
      ...organization,
      admin,
    };

    return this.http.post<Organization>(url, body);
  }

  public updateOrganization(organization): Observable<Organization> {
    const orgId = organization.id;
    const link = `/api/organizations/${orgId}`;

    delete organization.id;

    const organization$ = this.network.putResource<Organization>(link, organization);

    if (organization.organizationImage && organization.organizationImage !== 'DELETE') {
      const {organizationImage} = organization;

      return organization$.pipe(
        mergeMap((res: Organization) => {
          return this.imageService
            .postImage('organizations', res.id, organizationImage)
            .pipe(map(() => res));
        })
      );
    }

    if (organization.organizationImage === 'DELETE') {
      return organization$.pipe(
        mergeMap((organization) =>
          this.network.deleteResource(`/api/organizations/${orgId}/picture`).pipe(map(() => organization))
        )
      );
    }

    return organization$;
  }

  public deleteOrganization(organizationId: string): any {
    const link = `/api/organizations/${organizationId}`;

    return this.network.deleteResource(link);
  }

  public getDepartmentsByOrganization(organizationId: string): Observable<{content: DepartmentModel[]; links: Link[]}> {
    const link = `/api/organizations/${organizationId}/departments`;

    return this.network.fetchResource(link);
  }

  public addDepartment(body): Observable<any> {
    const link = `/api/departments`;

    return this.network.postResource(link, body);
  }

  public updateDepartment(department: DepartmentModel): Observable<any> {
    const link = `/api/departments/${department.id}`;

    return this.network.putResource(link, department);
  }

  public removeDepartment(id: string): any {
    const link = `/api/departments/${id}`;

    return this.network.deleteResource(link);
  }

  private setDepartment(departments: DepartmentModel[]) {
    return (this.departments = departments);
  }

  public getDepartment() {
    if (this.departments) {
      return of(this.departments);
    }

    const link = '/api/departments/options';

    return this.network
      .fetchResource(link)
      .pipe(map((departments: DepartmentModel[]) => this.setDepartment(departments)));
  }

  private setLocationTypes(locationTypesResponse: LocationTypesResponse) {
    this.locationTypes = locationTypesResponse;
    return locationTypesResponse;
  }

  public getLocationTypes(): Observable<LocationTypesResponse> {
    if (this.locationTypes) {
      return of(this.locationTypes);
    }

    const link = `/api/locations/locationTypes`;

    return this.network
      .fetchResource(link)
      .pipe(map((locationTypesResponse: LocationTypesResponse) => this.setLocationTypes(locationTypesResponse)));
  }

  public getOrganizationPatients<T>(
    id: string,
    {
      page = 0,
      size = 20,
      carePlanStatus = '',
      occupation = '',
      priority = '',
      orderType = '',
      asc = '',
      name = '',
      ageGroups = '',
      ethnicity = '',
      postal_code = '',
      races = '',
      patientCustomIdentifiers = '',
      gender = '',
      globalSearch = false,
      eventId = '',
      isPatientGroup = false,
      clinicId = ''
    } = {}
  ): Observable<T> {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()});
    const link = `/api/organizations/${id}/patients`;

    if (page >= 0) httpParams = httpParams.append('page', page.toString());
    if (size >= 0) httpParams = httpParams.append('size', size.toString());
    if (carePlanStatus) httpParams = httpParams.append('carePlanStatus', carePlanStatus);
    if (occupation) httpParams = httpParams.append('occupation', occupation);
    if (priority) httpParams = httpParams.append('priority', priority);
    if (orderType) httpParams = httpParams.append('orderType', orderType);
    if (asc) httpParams = httpParams.append('asc', asc.toString());
    if (name) httpParams = httpParams.append('name', name);
    if (ageGroups) httpParams = httpParams.append('ageGroups', ageGroups);
    if (ethnicity) httpParams = httpParams.append('ethnicity', ethnicity);
    if (postal_code) httpParams = httpParams.append('postal_code', postal_code);
    if (races) httpParams = httpParams.append('races', races);
    if (patientCustomIdentifiers) httpParams = httpParams.append('patientCustomIdentifiers', patientCustomIdentifiers);
    if (gender) httpParams = httpParams.append('gender', gender);
    if (eventId) httpParams = httpParams.append('eventId', eventId);
    if (name && globalSearch) httpParams = httpParams.append('global-search', String(globalSearch));
    if (isPatientGroup) httpParams = httpParams.append('isPatientGroup', isPatientGroup);
    if (clinicId) httpParams = httpParams.append('clinicId', clinicId);
    
    return this.network.fetchResource<T>(link, {params: httpParams});
  }

  public getOrganizationDepartment(organizationId: string): Observable<ResponseWithLinks<DepartmentModel>> {
    const link = `/api/organizations/${organizationId}/departments`;

    return this.network.fetchResource(link);
  }

  public getOrganizationOverview(orgId: string): Observable<Organization> {
    const url = `/api/organizations/${orgId}/overview `;

    return this.network.fetchResource(url);
  }

  // public extendOrganizationOverview(
  //   selectedClinicIds: string[],
  //   parentId: string,
  //   stateForFilter: string
  // ): Observable<ExtendedClinic[]> {
  //   return this.getOrganizationOverview(parentId).pipe(
  //     map((res) => {
  //       const openPodClinic = {name: 'Open POD', type: OrganizationTypes.OpenPod} as BranchModel;
  //       const clinics = res.branches.content.filter(
  //         (b) => b.type === OrganizationTypes.Clinic && b.address?.state && stateForFilter == b.address.state
  //       );
  //       const extendedClinics: ExtendedClinic[] = clinics.map((c) => {
  //         const extendedClinic = new ExtendedClinic(c);
  //
  //         if (selectedClinicIds.includes(extendedClinic.id)) {
  //           extendedClinic.selected = true;
  //         }
  //         return extendedClinic;
  //       });
  //
  //       extendedClinics.unshift(new ExtendedClinic(openPodClinic));
  //
  //       return extendedClinics;
  //     })
  //   );
  // }

  public getVaccinationCounts(orgId: string, startDate: string, endDate: string): Observable<any> {
    const url = `/api/organizations/${orgId}/clinic-appointment-counts`;
    let httpParams = new HttpParams();
    if (startDate) httpParams = httpParams.append('startDate', startDate);
    if (endDate) httpParams = httpParams.append('endDate', endDate);
    return this.network.fetchResource(url, {params: httpParams});
  }

  public getOrganization(orgId: string): Observable<Organization> {
    const url = `/api/organizations/${orgId}`;

    return this.network.fetchResource(url);
  }

  // public getOrganizationQuarantineContacts(orgId: string): Observable<any[]> {
  //   const link = `/api/quarantineContact/organization/${orgId}`;
  //
  //   return this.network.fetchResource(link);
  // }

  public getOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
    const url = `/api/organizations/${orgId}/performers`;

    return this.network.fetchResource<ResponseWithLinks<PerformerModel>>(url);
  }

  public getOrgProvidersBrief(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
    const url = `/api/organizations/${orgId}/performers_brief`;

    return this.network.fetchResource<ResponseWithLinks<PerformerModel>>(url);
  }
  public getOrgAppointment<T>(
    locationId: string,
    {
      search = '',
      dobFrom = null,
      dobTo = null,
      time = null,
      status = '',
      page = 0,
      size = 20,
      dueDateFrom = null,
      dueDateTo = null,
      orderType = '',
      asc = true,
      eventId = '',
    }
  ): Observable<T> {
    const url = `/api/tasks/${locationId}/location`;
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('orderType', orderType)
      .set('asc', asc.toString());

    if (search) params = params.append('name', search);
    if (time) params = params.append('time', time);
    if (status) params = params.append('status', status);
    if (dobFrom) params = params.append('dobFrom', dobFrom);
    if (dobTo) params = params.append('dobTo', dobTo);
    if (dueDateFrom) params = params.append('dueDateFrom', dueDateFrom);
    if (dueDateTo) params = params.append('dueDateTo', dueDateTo);
    if (eventId) params = params.append('eventId', eventId);

    return this.network.fetchResource<T>(url, {params});
  }

  public getOrgPatientsInQueue<T>(
    organizationId: string,
    {page = 0, size = 20, orderType = '', asc = true, invitationStatus = '', priority = '', name = ''}
  ): Observable<T> {
    const url = `/api/organizations/${organizationId}/queue/patients`;
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('orderType', orderType)
      .set('asc', asc.toString());

    if (invitationStatus) params = params.append('invitationStatus', invitationStatus);
    if (priority) params = params.append('priority', priority);
    if (name) params = params.append('name', name);

    return this.network.fetchResource<T>(url, {params});
  }

  public getQueueStatsForParentOrg(organizationId: string): Observable<any> {
    // in progress
    const url = `/api/organizations/${organizationId}/queue-status-aggregation`;

    return this.network.fetchResource(url);
  }

  // public getParentOrgPatientGroups(parentId: string): Observable<any> {
  //   // in progress
  //   const url = `/api/organizations/organizationPatientGroups/${parentId}`;
  //
  //   return this.network.fetchResource(url);
  // }

  public planVaccinationForOrganization(
    organizationId,
    organizationAppointmentId,
    startDate,
    endDate
  ): Observable<any> {
    const link = `/api/organizations/${organizationId}/appointment/${organizationAppointmentId}`;

    const body = {
      status: 'ACTIVE',
      startDate: startDate,
      endDate: endDate,
    };
    return this.network.putResource(link, body);
  }

  public addOrganizationAdmin(organizationId: string, admin: AdminInterface, privilegeId: number): Observable<any> {
    const url = `/api/accounts`;
    const body = {
      organizationId,
      privilegeId,
      ...admin,
    };

    return this.network.postResource(url, body);
  }

  public deleteFacilityAppointment({orgId, clinicId}: {orgId: string; clinicId: string}) {
    const url = `/api/organizations/${orgId}/appointment/${clinicId}`;
    return this.network.deleteResource(url);
  }

  public addFacilityAppointment({
    orgId,
    clinicId,
    startDate,
    endDate,
  }: {
    orgId: string;
    clinicId: string;
    startDate?: number;
    endDate?: number;
  }): Observable<any> {
    const url = `/api/organizations/${orgId}/appointment`;
    const body = {
      status: 'ACTIVE',
      startDate: startDate,
      endDate: endDate,
      clinicId,
    };

    return this.network.postResource(url, body);
  }

  public getAllOrganizationClinics(orgId) {
    // endpoint in progress
    const url = `/api/organizations/${orgId}/getAllOrganizationClinic`;

    return this.network.fetchResource(url);
  }
  public getAllOrganizationClinicsBrief(orgId): Observable<Organization[]> {
    // endpoint in progress
    const url = `/api/organizations/${orgId}/getAllOrganizationClinic_brief`;

    return this.network.fetchResource(url);
  }

  public getAllOrganizationStaff(orgId) {
    const url = `/api/organizations/${orgId}/allStaff`;
    // let params = new HttpParams()
    //   .set('page', page.toString())
    //   .set('size', size.toString())
    //   .set('orderType', orderType.toString())
    //   .set('asc', asc.toString());

    return this.network.fetchResource(url);
  }

  public getOpenDaysForOrganizationOld({
    organizationId,
    patientId,
    taskId,
  }: {
    organizationId: string;
    patientId?: string;
    taskId?: string;
  }): Observable<string[]> {
    const url = `/api/organizations/${organizationId}/clinic-appointments/open-days`;
    let httpParams = new HttpParams();

    if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
    if (taskId) httpParams = httpParams.set('taskId', taskId);

    return this.network.fetchResource(url, {params: httpParams});
  }

  public getOpenDaysForOrganization(organizationId, patientId: string = null, vaccineTypes: string[] = [],contentType?: string): Observable<any> {
    const url = `/api/organizations/${organizationId}/clinic-appointments/open-days`;
    let httpParams = new HttpParams();

    if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
    if(vaccineTypes?.length){
      const vaccinesEncoded = encodeURIComponent(vaccineTypes.join());
      httpParams = httpParams.set('vaccineType', vaccinesEncoded);
    }

    if(contentType){
      const contentTypeEncoded = encodeURIComponent(vaccineTypes.join());
      httpParams = httpParams.set('contentType', contentTypeEncoded);
    }

    return this.network.fetchResource(url, {params: httpParams});
  }

  public bookAppointmentForTask(locationId, dueDate, task, clinicAppointmentId = null): Observable<any> {
    if (
      task.dueDate != dueDate ||
      task.location.id != locationId ||
      task.clinicAppointmentResource.id != clinicAppointmentId
    ) {
      return this.bookAppointment(locationId, dueDate, task.id, clinicAppointmentId);
    } else {
      return of(task);
    }
  }

  public bookAppointment(locationId, dueDate, taskId, clinicAppointmentId = null): Observable<any> {
    const url = `/api/tasks/book-first-appointment-for-patient`;

    const body: {[key: string]: any} = {
      locationId,
      dueDate,
      id: taskId,
    };

    if (clinicAppointmentId) body.clinicAppointmentId = clinicAppointmentId;

    return this.network.putResource(url, body);
  }

  public bookAppointmentMini(body): Observable<any> {
    const url = `/api/tasks/bookAppointment/mini`;

    return this.network.putResource(url, body);
  }

  public cancelAppointment(patientId: string, taskId: string): Observable<any> {
    const url = `/api/tasks/bookAppointment/cancel?patientId=${patientId}&taskId=${taskId}`;

    return this.network.putResource(url, {});
  }

  public getOrgPublicClinics(orgId, {date}: {date: Date}, patientId: string = null, taskId: string = null): Observable<any> {
    const formattedDate = format(date, 'MM/dd/yyyy');
    const url = `/api/organizations/organizationublicClinics/${orgId}/web`;
    let params = new HttpParams().set('date', formattedDate);
    if (taskId) {
      params = params.set('taskId', taskId);
    }
    params = params.set('age', 0);
    if (patientId) params = params.set('targetPatientId', patientId);
    return this.network.fetchResource(url, {params});
  }

  // clinicSelected(clinic: Organization[]) {
  //   this._clinicSelected.next(clinic);
  // }

  getSuggestingClinics(orgId: string): Observable<Organization[]> {
    const url = `/api/organizations/${orgId}/getClinicSuggestionsForPatientGroup`;

    return this.network.fetchResource(url);
  }

  getDirectBillingInsuranceTypes(orgId): Observable<any[]> {
    const url = `/api/organizations/${orgId}/has-insurance`;

    return this.network.fetchResource(url);
  }

  getCommunityAppointments(organizationId): Observable<any[]> {
    const url = `/api/organizations/clinic-patient-groups/${organizationId}/community-appointment`;
    return this.network.fetchResource(url);
  }

  refreshInviteCode(orgId: string) {
    const url = `/api/organizations/${orgId}/refresh-invitation-code`;
    return this.network.putResource(url, {});
  }

  exportPatientsPerLanesTemplate(orgId: string, laneIds: string[]): Observable<any> {
    const url = `/api/organizations/${orgId}/patient-per-lane-template/download`;

    let httpParams = new HttpParams();
    laneIds.forEach(l => httpParams = httpParams.append('clinicAppointmentId', l));

    return this.network.fetchResource<any>(url, {params: httpParams, responseType: 'blob'});
  }

  public  getOrgPublicClinicsNextAvailable(orgId, dates:string[]=[],vaccineType:string,  patientId: string = null) : Observable<any>{
    const url = `/api/organizations/organizationublicClinics/${orgId}/web/next`;
    let httpParams = new HttpParams();
    if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
    if(dates?.length){
      const _dates = encodeURIComponent(dates.join(","));
      httpParams = httpParams.set('date', _dates);
    }
    httpParams= httpParams.set('vaccineType', vaccineType);
    return this.network.fetchResource(url, {params: httpParams});
  }

  getPatientAssessment(patientId): Observable<any[]> {
    const url = `/api/tasks/${patientId}/assessment-request`;
    return this.network.fetchResource(url);
  }

}
