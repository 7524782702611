import {ModalService} from './modal/modal.service';
import {WebsocketService} from './websocket/websocket.service';
import {UserSessionService} from './user-session/user-session.service';
import {ProviderService} from './provider/provider.service';
import {PatientService} from '../../modules/patients/services/patient.service';
import {NoteService} from './note/note.service';
import {NetworkService} from './network/network.service';
import {MasterDataService} from './master-data/master-data.service';
import {LanguageService} from './language/language.service';
import {ImageService} from './image/image.service';
import {DataBrokerService} from './data-broker/data-broker.service';
import {CarePlanService} from './care-plan/care-plan.service';
import {BreadcrumbService} from './breadcrumb/breadcrumb.service';
import {AuthorizationService} from './authorization/authorization.service';
import {AssessmentService} from './assessment/assessment.service';
import {ApiService} from './api/api.service';
import {ScriptService} from "./script-loader/script.service";
import {
  ScheduleAppointmentService
} from "../../modules/patient-overview/schedule-appointment/schedule-appointment.service";
import {DatePipe} from "@angular/common";

export const SERVICES: any[] = [
  DatePipe,
  ModalService,
  WebsocketService,
  UserSessionService,
  ProviderService,
  PatientService,
  NoteService,
  NetworkService,
  MasterDataService,
  LanguageService,
  ImageService,
  DataBrokerService,
  CarePlanService,
  BreadcrumbService,
  AuthorizationService,
  AssessmentService,
  ApiService,
  ScriptService,
  ScheduleAppointmentService
];
