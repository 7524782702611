import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {IconListComponent} from './components/main/icon-list/icon-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'onboard',
    loadChildren: () =>
      import('./modules/onboard-organization/onboard-organization.module').then((m) => m.OnboardOrganizationModule),
  },
  {path: 'patient', loadChildren: () => import('./modules/patient/patient.module').then((m) => m.PatientModule)},
  {
    path: 'patient-screening',
    loadChildren: () =>
      import('./modules/patient-screening/patient-screening.module').then((m) => m.PatientScreeningModule),
  },
  {
    path: 'patient-registration',
    loadChildren: () =>
      import('./modules/patient-registration/patient-registration.module').then((m) => m.PatientRegistrationModule),
  },
  {
    path: 'curapatient',
    loadChildren: () => import('./modules/curapatient/curapatient.module').then((m) => m.CurapatientModule),
  },
  {
    path: 'timeline',
    loadChildren: () => import('./modules/mobile/mobile.module').then((m) => m.MobileModule),
  },
  {
    path: 'patient-var',
    loadChildren: () =>
      import('./modules/patient-var/patient-var.module').then((m) => m.PatientVarModule),
  },
  {
    path: 'icons',
    component: IconListComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

// TODO implement custom preload module
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' /*preloadingStrategy: PreloadAllModules*/, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
