import {Injectable} from '@angular/core';
import {ObservableStore} from '@codewithdan/observable-store';
import {SSAssessmentOverview, StoreState} from '../../../core/store/store-state';
import {Observable, of as observableOf} from 'rxjs';
import {NetworkService} from '../../../core/services/network/network.service';
import {HttpParams} from '@angular/common/http';
import config from '../../../../config';
import {map} from 'rxjs/operators';
import {Assessment} from '../../../core/models/assessment.model';
import {AssessmentService} from '../../../core/services/assessment/assessment.service';
import {TherapyModel} from '../../../core/models/therapy-type';

const initiateState: SSAssessmentOverview = {
  assessment: null,
  questions: null,
  therapy: null,
};

@Injectable({
  providedIn: 'root',
})
export class AssessmentOverviewService extends ObservableStore<StoreState> {
  constructor(private networkService: NetworkService, private assessmentService: AssessmentService) {
    super({});
    this.setState({assessmentOverview: initiateState}, AssessmentOverviewStateActions.InitState);
  }

  fetchAssessment(assessmentId?: string): Observable<Assessment> {
    if (!assessmentId) {
      assessmentId = localStorage.getItem('current-assessment-id');
    }

    return this.assessmentService.getAssessment(assessmentId).pipe(
      map((assessment) => {
        this.setState((prevState) => {
          return {assessmentOverview: {...prevState.assessmentOverview, assessment}};
        }, AssessmentOverviewStateActions.GetAssessment);
        return assessment;
      })
    );
  }

  getAssessment(assessmentId?: string): Observable<Assessment> {
    const {
      assessmentOverview: {assessment},
    } = this.getState();

    if (assessment) {
      return observableOf(assessment);
    }

    return this.fetchAssessment(assessmentId);
  }

  fetchTherapy(assessmentId: string): Observable<TherapyModel> {
    const url = `/api/meta/therapy`;
    const taskTypeId = config.TASKTYPES.ASSESSMENT;
    const httpParams = new HttpParams()
      .set('taskTypeId', taskTypeId.toString())
      .set('entityType', 'ASSESSMENT')
      .set('entityId', assessmentId);

    return this.networkService
      .fetchResource<TherapyModel[]>(url, {params: httpParams})
      .pipe(
        map(([therapy]) => {
          this.setState(
            (prevState) => ({
              assessmentOverview: {...prevState.assessmentOverview, therapy},
            }),
            AssessmentOverviewStateActions.GetTherapy,
            false
          );

          return therapy;
        })
      );
  }

  getTherapy(assessmentId?: string): Observable<TherapyModel> {
    const state = this.getState();

    if (!assessmentId) {
      assessmentId = localStorage.getItem('current-assessment-id');
    }

    if (state && state.assessmentOverview && state.assessmentOverview.therapy) {
      return observableOf(state.assessmentOverview.therapy);
    }

    return this.fetchTherapy(assessmentId);
  }

  updateAssessment(assessment: any): void {
    const state = this.getState();

    if (state && state.assessmentOverview && state.assessmentOverview.assessment) {
      this.setState(
        (prevState) => ({
          assessmentOverview: {
            ...prevState.assessmentOverview,
            assessment,
          },
        }),
        AssessmentOverviewStateActions.UpdateAssessment
      );
    }
  }

  clean() {
    this.setState({assessmentOverview: initiateState}, AssessmentOverviewStateActions.ClearData);
  }
}

export enum AssessmentOverviewStateActions {
  InitState = 'init_state',
  GetAssessment = 'get_assessment',
  UpdateAssessment = 'update_assessment',
  GetTherapy = 'get_therapy',
  ClearData = 'clear_data',
}
