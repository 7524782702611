import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {ObservableStore} from '@codewithdan/observable-store';
import {ReduxDevToolsExtension} from '@codewithdan/observable-store-extensions';
import * as Sentry from "@sentry/angular-ivy";

if(location.hostname != 'localhost'){
  Sentry.init({
    environment: "prod",
    dsn: "https://ac16566d03f929e833b55d6881e651bf@o4505999135539200.ingest.sentry.io/4505999137177600",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", new RegExp('^' + environment.apiUrl.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'))],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
if (environment.production) {
  enableProdMode();
} else {
  ObservableStore.globalSettings = {
    trackStateHistory: true,
  };
  ObservableStore.addExtension(new ReduxDevToolsExtension());
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
