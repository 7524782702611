import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import config from 'src/config';
import { OrganizationModel } from '../../models/organization.model';
import { NetworkService } from '../network/network.service';
import { UserSessionService } from '../user-session/user-session.service';

export interface Breadcrumb {
  label: string;
  url?: string;
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public _breadcrumbsUpdated = new BehaviorSubject<Breadcrumb[]>([]);
  public _breadcrumbsSet = new BehaviorSubject<any>({});

  public breadcrumbsUpdated$ = this._breadcrumbsUpdated.asObservable();
  public breadcrumbsSet$ = this._breadcrumbsSet.asObservable();

  constructor(private network: NetworkService,
    private session: UserSessionService) { }

  setBreadcrumbs(breadcrumbs: Breadcrumb[], index) {
    this._breadcrumbsSet.next({ breadcrumbs, index });
  }

  updateBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this._breadcrumbsUpdated.next(breadcrumbs);
  }

  reset() {
    this._breadcrumbsUpdated.next([]);
    this._breadcrumbsSet.next([]);
  }

  get breadcrumbs() {
    return this._breadcrumbsUpdated.getValue();
  }
  fetchOrganization(orgId: any) {
    if (orgId) {
      this.fetchOrgOverview(orgId).subscribe((resp) => {
        if (resp) {
          const { parents = null } = resp;
          const parentBreadcrumbs = [];
          const hierarchyParents = [];
          const url = '/curapatient/organizations/organization';
          const label = `${resp.name}'s Overview`;
          const { sessionAccount: { privilege: { id: privilegeId = null } = {}, organizationId } = {} } = this.session;
          if (parents?.content?.length && resp.id != organizationId) { //  check if the org and account org is different
            const allParents = parents;
            allParents.content.forEach((aParent) => {
              if (resp.parentId == aParent.id) {     // found immdediate parent
                hierarchyParents.push(aParent); // one Level
                if (aParent.id !== organizationId) {
                  allParents.content.forEach((bParent) => {
                    if (aParent.parentId == bParent.id) {
                      hierarchyParents.push(bParent); // Second Level
                    }
                  });
                }
              }
            });
          }
          if (
            [
              config.PRIVILEGEID.COORDINATOR,
              config.PRIVILEGEID.CLINIC_ADMINISTRATOR,
              config.PRIVILEGEID.ORGANIZATION_MANAGER,
              config.PRIVILEGEID.DISTRICT_ADMIN,
              config.PRIVILEGEID.SCHOOL_ADMIN,
              config.PRIVILEGEID.SCHOOL_MANAGER,
              config.PRIVILEGEID.TESTING_COORDINATOR,
            ].indexOf(+privilegeId) === -1 &&
            hierarchyParents?.length &&
            organizationId !== resp.id
          ) {
            hierarchyParents.forEach((parent) => {
              parentBreadcrumbs.unshift({
                url,
                label: parent.name,
                id: parent.id,
              });
            });
          }
          parentBreadcrumbs.push({ url, label });
          if (+privilegeId === config.PRIVILEGEID.SUPER_ADMIN) {
            this.setBreadcrumbs(parentBreadcrumbs, 1);
          } else {
            this.setBreadcrumbs(parentBreadcrumbs, 0);
          }
        }
      });
    }

  }

  fetchOrgOverview(orgId: string): Observable<OrganizationModel> {
    const url = `/api/organizations/${orgId}/overview`;
    return this.network.fetchResource<OrganizationModel>(url);
  }
}
