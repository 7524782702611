import {Config} from './app/core/models/config.model';
import {environment} from './environments/environment';

const config: Config = {
  APP_NAME: 'CURA Patient',
  LOCAL_API: 'http://curapatient.compositeapps.net/api',
  ROOT_URL: '',
  TOKEN: '',
  SKIP_HEADER: 'X-Skip-Interceptor',
  PASS_PATTERN: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*(),.?":{}|<>/\\\\=\\[\\]/+;_`~/-])[A-Za-z\\d!@#$%^&*(),.?":{}|<>/\\\\=\\[\\]/+;_`~/-]{7,}$',
  NAME_PATTERN: "^(?=.{1,40}$)\\s*[a-zA-Z-]+(?:[-' ][a-zA-Z]+)*\\s*$",
  TRANSLATION_LANGUAGES: ['en', 'es', 'vi', 'ko', 'zh-TW', 'zh-CN'],
  TUMOR_SIZE: {
    TX: "Tumors are too small for testing or can't be found with tests.",
    T0: 'No primary tumor has been found.',
    Tis: "There are abnormal or cancer cells in airways that haven't invaded lung tissue.",
    T1: 'Tumors are in the lungs only and are not larger than 3cm.',
    T1mi: 'Tumors grow only along the surface of lung tissue. Aras of invasion are no deeper than 0.5cm.',
    T1a: 'Tumors are 1cm or smaller and have invaded at least 0.5cm.',
    T1b: 'Tumors are larger than 1cm but not larger than 2cm. Areas of invasion are at least 0.5cm.',
    T1c: 'Tumors are more than 2cm but not larger than 3cm. Areas of invasion are at least 0.5cm.',
    T2:
      "Tumors are larger than 3cm but not larger than 5cm; have grown into the main bronchus, have grown into the lung's inner lining (visceral pleura), or have caused lung collapse (atelectasis) or swelling (pneumonitis).",
    T2a:
      'Tumors are 4cm or smaller. Other features may or may not be present. Smaller tumors with other features are also rated T2a.',
    T2b: 'Tumors are larger than 4cm. Other features may or may not be present.',
    T3:
      "Tumors are larger than 5cm but not larger than 7cm; have grown into the lung's outer lining (parietal pleura) or nearby sites including chest wall, phrenic nerve, or the heart's lining (pericardium), or there are primary and secondary tumors in the same lobe.",
    T4:
      'Tumors are larger than 7cm; have grown into the diaphragm, mediastinum, heart or its major blood vessels, windpipe (trachea), recurrent laryngeal nerve, carina, esophagus, or spine; or there are secondary tumors in the same lung but a different lobe than the primary tumor.',
  },
  LYMPH_NODE: {
    NX: 'Nearby lymph nodes have not been tested.',
    N0: 'No cancer in nearby lymph nodes.',
    N1:
      'The cancer has spread to the peribronchial nodes and/or to the hilar and intrapulmonary nodes of the lung with the primary tumor.',
    N2:
      'The cancer has spread to mediastinal lymph nodes, which include subcardinal nodes, near the lung with the primary tumor.',
    N3:
      'The cancer has spread to the mediastinal or hilar nodes near the lung without the primary tumor, or to any supraclavicular or scalene lymph nodes.',
  },
  NATIONALITIES: [
    'United States',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua & Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia & Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    'Cote D Ivoire',
    'Croatia',
    'Cruise Ship',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Polynesia',
    'French West Indies',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kuwait',
    'Kyrgyz Republic',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Pierre & Miquelon',
    'Samoa',
    'San Marino',
    'Satellite',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'St Kitts & Nevis',
    'St Lucia',
    'St Vincent',
    'St. Lucia',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    "Timor L'Este",
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks & Caicos',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
    'Vietnam',
    'Virgin Islands (US)',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  STATES: [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ],
  PRIVILEGEID: {
    SUPER_ADMIN: 1,
    ORGANIZATION_ADMIN: 2,
    ORGANIZATION_MANAGER: 3,
    USER: 4,
    COORDINATOR: 5,
    ANALYTICS_ADMIN: 6,
    CLINIC_ADMINISTRATOR: 7,
    COMMUNITY_POC_ADMIN: 8,
    NON_IMMUNIZER: 9,
    DISTRICT_ADMIN: 10,
    SCHOOL_ADMIN: 11,
    SCHOOL_MANAGER: 12,
    TESTING_COORDINATOR: 13,
  },
  TASKTYPES: {
    APPOINTMENT: 1,
    ASSESSMENT: 2,
    EDUCATION: 3,
    MEDICATION: 4,
    EXERCISE: 5,
    FOOD: 6,
    TEAM_TASK: 7,
  },
  SERVICE_TYPES: {
    DAILY_ASSESSMENT: 28,
  },
  DAYSOFWEEK: {
    SUN: 'S',
    MON: 'M',
    TUE: 'T',
    WED: 'W',
    THU: 'TH',
    FRI: 'F',
    SAT: 'SA',
  },
  VA: {
    orgName: 'Veteran Affairs Hospital',
  },
  images: {
    maleProfileIcon: './assets/main/male-profile-icon.svg',
    carePlanNoImageIcon: './assets/main/careplan/careplan-no-image.svg',
    assessmentNoImageIcon: './assets/main/assessment/assessment-no-image.svg',
    organizationNoImage: './assets/main/temp/profile-no-image-organization.svg',
    startDateIcon: './assets/main/calendar/start-date-icon.svg',
    closeIcon: '/assets/main/temp/close-icon.svg',
    filterIcon: '',
    questionNoImage: '',
    educationCenterNoImage: '/assets/main/Group1221512x.png',
  },
  TIMEOUT_API: 50000,
  TIMEOUT_VERSION_CHECK: 1, // in minutes,
  CARE_PLAN_STATUSES: [
    {
      name: 'Awaiting Testing',
      value: 'AwaitingTesting',
      observationValue: -1,
    },
    {
      name: 'Awaiting Test Results',
      value: 'AwaitingTestResults',
      observationValue: 2,
    },
    {
      name: 'Tested Positive',
      value: 'TestedPositive',
      observationValue: 1,
    },
    {
      name: 'Tested Negative',
      value: 'TestedNegative',
      observationValue: 0,
    },
    {
      name: 'Patient Closed Out',
      value: 'PatientClosedOut',
      observationValue: 3,
    },
  ],
  googleMap: {
    marker: {
      icons: {
        home: './assets/main/patient/ic-home.svg',
        inCompliance: './assets/main/patient/ic-in-compliance.svg',
        out: './assets/main/patient/ic-out.svg',
        circle: './assets/main/patient/ic-one-mile.svg',
        possiblyOut: './assets/main/patient/ic-possibly-out.svg',
        blue: './assets/main/patient/ic-blue.svg',
        green: './assets/main/patient/ic-green.svg',
        grey: './assets/main/patient/ic-grey.svg',
        orange: './assets/main/patient/ic-orange.svg',
      },
    },
  },
  webSocket: {
    url: environment.apiSocketUrl,
    options: {
      query: {
        EIO: '3',
      },
      path: `/socket.io`,
      transports: ['websocket'],
      reconnection: false,
    },
  },
  gender: {
    identity: [
      {value: 'F', translationKey: 'COMMON.FEMALE'},
      {value: 'M', translationKey: 'COMMON.MALE'},
      {value: 'transgender-transfeminine', translationKey: 'COMMON.TRANSGENDER_TRANSFEMININE'},
      {value: 'transgender-transmasculine', translationKey: 'COMMON.TRANSGENDER_TRANSMASCULINE'},
      {value: 'nonconforming', translationKey: 'COMMON.GENDER_NONCONFORMING'},
      {value: 'other', translationKey: 'COMMON.OTHER_GENDER'},
      {value: 'decline-to-state', translationKey: 'COMMON.DECLINE_TO_STATE'},
    ],
  },
  CONSTANTS: {
    TASKSTATUS: {
      PENDING: 'PENDING',
      SHCEDULED: 'SCHEDULED',
      MISSED: 'MISSED',
      COMPLETED: 'COMPLETED',
    },
    CAREPLANNAMES: {
      COVID19VACCINE: 'COVID-19 Vaccine',
      THIRDDOSEOPTION1: 'COVID-19 Vaccine 3rd Dose',
      THIRDDOSEOPTION2: 'COVID-19 Vaccine Third Dose',
      COVID19BOOSTERDOSE: 'COVID-19 Vaccine Booster Dose',
      FLUSHOT: 'Flu Shot Care Plan',
    },
    COMMUNITYGROUPTEXT: 'COMMUNITYGROUP',
  },
  CHAR_ONLY: "^[a-zA-Z]*$",
  NUMBER_ONLY:"^[0-9]*$",
  ALPHANUMERIC_ONLY:"^[a-zA-Z0-9]*$",
  ALPHANUMERIC_WITH_SPACE:"^[a-zA-Z0-9 ]*$",
  EMAIL_PATTERN:"^[a-zA-Z0-9@.]*$",
  EMAIL_VALIDATION:'^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$',
  EMAIL_VALIDATION_FULL: '^[A-Za-z0-9.! #$%&\'*+-/=?^_`{|,@]+@([a-zA-Z0-9.! #$%&\'*+-/=?^_`]+\\.)+[a-zA-Z]{2,}$'
};

export default config;
