import {Injectable} from '@angular/core';

export const IGNORE_SOURCE_LIST = ['Rating', 'Note', 'Sentiment'];

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {

  public parseExtraValue(extraValue: string): any[] {
    return extraValue
      ? JSON.parse(extraValue)
          .filter((eV) => ['Critical', 'Warning'].includes(eV.type))
          .sort(this.sortByType)
      : [];
  }

  sortByType = (a: any, b: any): number => (a.score >= b.score ? 1 : -1);

  public getConditionWarning(extraValue: string): {[key: string]: any} {
    let conditionWarning: {[key: string]: any} = {};
    const allWarnings = this.parseExtraValue(extraValue);

    if (!allWarnings) return {};

    conditionWarning = allWarnings
      .filter(({source = null, score: iScore = null}) => source && iScore && !IGNORE_SOURCE_LIST.includes(source))
      .sort((a, b) => a.score - b.score)[0];

    if (!conditionWarning) {
      if (allWarnings[0] === undefined || allWarnings[0] == null) {
        conditionWarning = {};
      } else {
        conditionWarning = allWarnings[0];
      }
      conditionWarning.text = 'No issues or concerns were detected';
      conditionWarning.source = null;
      conditionWarning.type = 'Check';
    }

    return conditionWarning;
  }
}
