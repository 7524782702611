import {LanguageIntr} from './language.interface';
import {LanguageType} from './language.enum';

export abstract class LanguageModel implements LanguageIntr {
  language_type: string;

  // common
  common_gender: string;
  common_dateOffBirth: string;
  common_firstName: string;
  common_lastName: string;

  // locals
  activity_card_allActivitiesLabel: string;
  activity_card_commentLabel: string;
  activity_card_declineLabel: string;
  activity_card_acceptLabel: string;
  activity_card_agoLabel: string;
  activity_card_cancelLabel: string;
  activity_card_sendLabel: string;
  activity_card_shareLabel: string;
  activity_card_messageCenter: string;

  area_spline_complianceLabel: string;
  area_spline_interventionLabel: string;

  line_chart_engagementChartLabel: string;
  line_chart_engagementScoreLabel: string;

  pie_chart_totalAppointmentsLabel: string;
  pie_chart_noAppointmentsLabel: string;

  progress_pie_completedLabel: string;
  progress_pie_weeksLabel: string;
  progress_pie_cyclesLabel: string;

  scatter_chart_femaleLabel: string;
  scatter_chart_maleLabel: string;
  scatter_chart_unplannedEncountersLabel: string;
  scatter_chart_dayLabel: string;

  spider_web_chart_titleLabel: string;
  spider_web_chart_allocatedBudgetLabel: string;

  login_form_appTitle: string;
  login_form_registerTitle: string;
  login_form_register: string;
  login_form_forgotPass: string;
  login_form_loginLabel: string;
  login_form_errorStrLabel: string;
  login_form_userNameLabel: string;
  login_form_userPasswordLabel: string;

  login_appTitleLabel: string;
  login_subtitleLabel: string;
  login_partnersTitleLabel: string;

  bio_card_todayLabel: string;

  performance_team_lungCancerPlanLabel: string;
  performance_team_engagementChartLabel: string;
  performance_team_yourTeamLabel: string;

  patient_appt_list_patientLabel: string;
  patient_appt_list_todaysTaskLabel: string;
  patient_appt_list_timeLabel: string;
  patient_appt_list_missingApptLabel: string;

  patient_bio_progressTitle: string;
  patient_bio_missingApptString: string;
  patient_bio_diagnoisLabel: string;
  patient_bio_ageLabel: string;
  patient_bio_todaysTaskLabel: string;
  patient_bio_atSignLabel: string;
  patient_bio_treatmentPlanLabel: string;
  patient_bio_lungCancerPlanLabel: string;
  patient_bio_onTrackLabel: string;
  patient_bio_viewPatientOverviewLabel: string;

  workload_todaysApptLabel: string;
  workload_appointmentsLabel: string;
  workload_nextPatientLabel: string;

  calendar_eventActionLabel: string;
  calendar_actionLabel: string;
  calendar_eventLabel: string;
  calendar_okLabel: string;
  calendar_monthLabel: string;
  calendar_weekLabel: string;
  calendar_dayLabel: string;
  calendar_calendarDateLabel: string;

  profile_dashboardLabel: string;
  profile_appointmentLabel: string;
  profile_providerLabel: string;

  side_bar_providerLabel: string;
  side_bar_patientListLabel: string;
  side_bar_carePlansLabel: string;
  side_bar_assessments: string;
  side_bar_organizations: string;
  side_bar_master_data: string;

  patients_allLabel: string;

  patients_dashboard_diagnosisFilterLabel: string;
  patients_dashboard_cancerTypeFilterLabel: string;
  patients_dashboard_stageFilterLabel: string;
  patients_dashboard_treatmentStatusFilterLabel: string;
  patients_dashboard_carePlanFilterLabel: string;
  patients_dashboard_profileCompletenessFilterLabel: string;
  patients_dashboard_patientLabel: string;
  patients_dashboard_diagnosisLabel: string;
  patients_dashboard_cancerTypeLabel: string;
  patients_dashboard_timeLineLabel: string;
  patients_dashboard_stageLabel: string;
  patients_dashboard_treatmentStatusLabel: string;

  patients_dashboard_patientListLabel: string;
  patients_dashboard_naLabel: string;
  patients_dashboard_offTrackLabel: string;
  patients_dashboard_onTrackLabel: string;

  stats_totalPatientsLabel: string;
  stats_totalCarePlansLabel: string;
  stats_unplannedLabel: string;
  stats_offTrackLabel: string;
  stats_availableTeamLabel: string;
  stats_numRescheduledLabel: string;

  patient_activity_card_agoLabel: string;
  patient_activity_card_commentLabel: string;
  patient_activity_card_shareLabel: string;

  patient_careplan_manager_cyclesLabel: string;
  patient_careplan_manager_changePlanLabel: string;
  patient_careplan_manager_durationLabel: string;
  patient_careplan_manager_initiatePlanLabel: string;
  patient_careplan_manager_ownerLabel: string;
  patient_careplan_manager_startDateLabel: string;
  patient_careplan_manager_weeksLabel: string;
  patient_careplan_manager_goalsLabel: string;

  missing_plan_missingTitleLabel: string;
  missing_plan_missingMessageLabel: string;
  missing_plan_btnLabel: string;

  plan_details_filter_addNewTaskLabel: string;
  plan_details_filter_saveLabel: string;

  grid_of_plan_inHomeLabel: string;
  grid_of_plan_medicationLabel: string;
  grid_of_plan_appointmentLabel: string;
  grid_of_plan_usedLabel: string;
  grid_of_plan_updatedLabel: string;
  grid_of_plan_choosePlanLabel: string;
  grid_of_plan_viewPreviewLabel: string;

  standard_care_plan_standardCpLabel: string;
  standard_care_plan_clinicalTrialLabel: string;

  patient_appt_planning_tempDoctorLabel: string;
  patient_appt_planning_apptDetailsLabel: string;
  patient_appt_planning_apptListLabel: string;
  patient_appt_planning_noUpcomingApptsLabel: string;

  patient_planning_treatmentPlanLabel: string;
  patient_planning_diagnosisLabel: string;
  patient_planning_appointmentLabel: string;
  patient_planning_teamLabel: string;
  patient_planning_actionLogLabel: string;
  patient_planning_progressTitle: string;
  patient_planning_treatmentTitle: string;
  patient_planning_careTeamTitle: string;
  patient_planning_cancerTitle: string;
  patient_planning_labOrderTitle: string;
  patient_planning_diagnosisTitle: string;
  patient_planning_imageOrderTitle: string;
  patient_planning_xRayLabel: string;
  patient_planning_updatedLabel: string;
  patient_planning_labResultsLabel: string;
  patient_planning_marginInfoLabel: string;
  patient_planning_metastasisLabel: string;
  patient_planning_lymphNodesLabel: string;
  patient_planning_tumorSizeLabel: string;
  patient_planning_cancerTypeLabel: string;
  patient_planning_stageLabel: string;

  treatment_plan_share_careTeamTitleLabel: string;
  treatment_plan_share_staticQRLabel: string;
  treatment_plan_share_printLabel: string;
  treatment_plan_share_sendToEmailLabel: string;
  treatment_plan_share_shareSentenceLabel: string;
  treatment_plan_share_recipientsLabel: string;
  treatment_plan_share_shareLabel: string;
  treatment_plan_chatBoxPlaceholderLabel: string;

  patient_overview_card_cancerType: string;
  patient_overview_card_cancerStage: string;
  patient_overview_card_tumorSize: string;
  patient_overview_card_carcinomaLabel: string;
  patient_overview_card_stageLabel: string;
  patient_overview_card_cancerTypeLabel: string;
  patient_overview_card_tumorSizeLabel: string;
  patient_overview_card_lymphNodesLabel: string;
  patient_overview_card_metastasisLabel: string;
  patient_overview_card_marginInfoLabel: string;
  patient_overview_card_patientInfoLabel: string;
  patient_overview_card_ageLabel: string;
  patient_overview_card_ethnicityLabel: string;
  patient_overview_card_phoneNumberLabel: string;
  patient_overview_card_emailLabel: string;
  patient_overview_card_addressLabel: string;

  patient_profile_overviewLabel: string;
  patient_profile_dashboardLabel: string;
  patient_profile_ehrLabel: string;

  patient_note_titleLabel: string;
  patient_note_newTitlePlaceholderLabel: string;
  patient_note_newNotePlaceholderLabel: string;
  patient_note_cancelButtonLabel: string;
  patient_note_saveButtonLabel: string;
  patient_note_privateLabel: string;
  patient_note_publicLabel: string;
  patient_note_allLabel: string;
  patient_note_byMeLabel: string;
  patient_note_seeAllLabel: string;
  patient_note_searchByProviderLabel: string;
  patient_note_createNoteLabel: string;
  patient_note_creatorLabel: string;
  patient_note_dateLabel: string;
  patient_note_onlyOrgProvidersLabel: string;

  task_list_therapiesLabel: string;
  task_list_inHomeActivitiesLabel: string;
  task_list_emptyTherapyTaskListLabel: string;
  task_list_emptyInHomeTaskListLabel: string;
  task_list_serviceTypeLabel: string;
  task_list_therapyTypeLabel: string;
  task_list_locationTypeLabel: string;
  task_list_providerLabel: string;
  task_list_numOfTimesLabel: string;

  care_plan_overviewLabel: string;
  care_plan_observationsLabel: string;
  care_plan_analyticsLabel: string;

  progress_titleLabel: string;
  progress_compliancyTitleLabel: string;
  progress_unplannedEncounterLabel: string;
  progress_totalLabel: string;
  progress_percentSuccessLabel: string;
  progress_title: string;
  progress_compliancyTitle: string;
  progress_complianceyViewLabel: string;
  progress_patientViewLabel: string;
  progress_numTreatmentTypesLabel: string;
  progress_dayNumLabel: string;
  progress_numOfftrackPatientsLabel: string;
  progress_numUnplannedEncountersLabel: string;
  progress_numPatients: string;
  progress_numPatientGoalsLabel: string;
  progress_numMedicationsLabel: string;
  progress_numTreatmentTypesDescLabel: string;
  progress_dayNumDescLabel: string;
  progress_numOfftrackPatientsDescLabel: string;
  progress_numUnplannedEncountersDescLabel: string;
  progress_numPatientsDescLabel: string;
  progress_numPatientGoalsDescLabel: string;
  progress_numMedicationsDescLabel: string;

  careplan_overview_patientLabel: string;
  careplan_overview_allLabel: string;
  careplan_overview_unplannedLabel: string;
  careplan_overview_offTrackLabel: string;

  careplan_create_create_new_care_plan: string;

  add_new_task_addNewTaskLabel: string;
  add_new_task_addLabel: string;
  add_new_task_selectTaskTypeLabel: string;
  add_new_task_selectServiceTypeLabel: string;
  add_new_task_selectTherapyTypeLabel: string;
  add_new_task_selectLocationLabel: string;
  add_new_task_selectProviderLabel: string;
  add_new_task_countLabel: string;

  task_row_selectServiceLabel: string;
  task_row_selectTherapyLabel: string;
  task_row_selectLocationLabel: string;
  task_row_selectProviderLabel: string;
  task_row_weekLabel: string;
  task_row_addressLabel: string;
  task_row_dateLabel: string;
  task_row_timeLabel: string;
  task_row_addNewLabel: string;
  task_row_successMsg: string;
  task_row_errorMsg: string;
  task_row_missingMsg: string;
  task_row_confirmLabel: string;
  task_row_cancelLabel: string;
  task_row_deleteTaskMsg: string;

  careplan_dashboard_careplansLabel: string;
  careplan_dashboard_carePlanNameLabel: string;
  careplan_dashboard_carePlanTypeLabel: string;
  careplan_dashboard_complianceScoreLabel: string;
  careplan_dashboard_enrolledPatientsLabel: string;
  careplan_dashboard_unplannedEncountersLabel: string;
  careplan_dashboard_planGoalsLabel: string;
  careplan_dashboard_planApptsLabel: string;

  patient_list_diagnosisLabel: string;
  patient_list_cancerTypeLabel: string;
  patient_list_stageLabel: string;
  patient_list_treatmentStatusLabel: string;

  filter_bar_resetFilterLabel: string;
  filter_bar_filterTitleLabel: string;

  calendar_onboard_title: string;
  calendar_onboard_startTodayMsg: string;
  calendar_onboard_setStartMsg: string;
  calendar_onboard_todayBtnLabel: string;
  calendar_onboard_startOnLabel: string;

  care_plan_summary_lungCancerPlanLabel: string;
  care_plan_summary_treatmentsLabel: string;
  care_plan_summary_appointmentsLabel: string;
  care_plan_summary_weekLabel: string;
  care_plan_summary_completeLabel: string;
  care_plan_summary_backButtonLabel: string;
  care_plan_summary_numInHomeActivitiesLabel: string;
  care_plan_summary_numAppointmentsLabel: string;
  care_plan_summary_totalDurationWeeksLabel: string;
  care_plan_summary_numCyclesLabel: string;

  qr_code_qrCodeTitleLabel: string;
  qr_code_printOutLabel: string;
  qr_code_sendToEmailLabel: string;
  qr_code_shareButtonLabel: string;
  qr_code_enterEmailLabel: string;

  under_construction_underConstructionLabel: string;
  under_construction_newStuffLabel: string;

  org_dashboard_organizationLabel: string;
  org_dashboard_organizationListLabel: string;
  org_dashboard_addressLabel: string;
  org_dashboard_complianceScoreLabel: string;
  org_dashboard_providersLabel: string;
  org_dashboard_patientsLabel: string;
  org_dashboard_allLabel: string;

  org_provider_list_reviewsLabel: string;
  org_provider_list_totalLabel: string;
  org_provider_list_engagementScoreLabel: string;
  org_provider_list_patientsLabel: string;
  org_provider_list_carePlansLabel: string;
  org_provider_list_providerLabel: string;
  org_provider_list_departmentLabel: string;
  org_provider_list_specialtiesLabel: string;

  org_branch_list_organizationLabel: string;
  org_branch_list_addressLabel: string;
  org_branch_list_complianceScoreLabel: string;
  org_branch_list_providersLabel: string;
  org_branch_list_patientsLabel: string;

  org_onboard_accountInfoLabel: string;

  org_edit_organizationDetailsLabel: string;
  org_edit_addressDetailsLabel: string;
  org_edit_orgPicLabel: string;
  org_edit_orgNameLabel: string;
  org_edit_taskIdNumberLabel: string;
  org_edit_taskIdNumberRequiredLabel: string;
  org_edit_businessLicenseNumberLabel: string;
  org_edit_businessLicenseNumberRequiredLabel: string;
  org_edit_countryLabel: string;
  org_edit_orgUnderLawLabel: string;
  org_edit_departmentsLabel: string;
  org_edit_addressTypeLabel: string;
  org_edit_address1Label: string;
  org_edit_address2Label: string;
  org_edit_cityLabel: string;
  org_edit_zipcodeLabel: string;
  org_edit_tempHeadquarterLabel: string;
  org_edit_submitBtnLabel: string;
  org_edit_createBtnLabel: string;
  org_edit_saveBtnLabel: string;
  org_edit_saveAlertSuccess: string;
  org_edit_saveAlertFail: string;
  org_edit_uploadImgLabel: string;
  org_edit_deptDropdownPlaceholderLabel: string;
  org_edit_mapErrorMessage: string;

  user_option_accountCompletenessLabel: string;
  user_option_accountInstructionLabel: string;
  user_option_emailAddressLabel: string;
  user_option_phoneNumberLabel: string;
  user_option_emailConfirmationLabel: string;
  user_option_passwordLabel: string;
  user_option_accountSettingLabel: string;
  user_option_logOutLabel: string;

  assessments_dashboard_allLabel: string;
  assessments_dashboard_assessmentOwnerLabel: string;
  assessments_dashboard_assessmentNameLabel: string;
  assessments_dashboard_assessmentTypeLabel: string;
  assessments_dashboard_ownerLabel: string;
  assessments_dashboard_lastEditedLabel: string;
  assessments_dashboard_numberOfQuestionsLabel: string;
  assessments_dashboard_numberOfPatientsLabel: string;

  assessment_dashboardLabel: string;

  assessment_overview_performanceLabel: string;
  assessment_overview_patientsAnsweredLabel: string;
  assessment_overview_malePatientsLabel: string;
  assessment_overview_femalePatientsLabel: string;

  assessment_overview_questionnaireLabel: string;
  assessment_overview_questionNumberLabel: string;
  assessment_overview_enterQuestionLabel: string;
  assessment_overview_duplicateLabel: string;
  assessment_overview_deleteLabel: string;
  assessment_overview_answerPlaceholderLabel: string;
  assessment_overview_gradePlaceholderLabel: string;
  assessment_overview_descriptionPlaceholderLabel: string;
  assessment_overview_trueLabel: string;
  assessment_overview_falseLabel: string;

  assessment_edit_generalInfoLabel: string;
  assessment_edit_createAssessmentLabel: string;
  assessment_edit_backButtonLabel: string;
  assessment_edit_completeButtonLabel: string;
  assessment_edit_startButtonLabel: string;
  assessment_edit_enterAssessmentNameLabel: string;
  assessment_edit_settingsLabel: string;
  assessment_edit_assessmentNameLabel: string;
  assessment_edit_assessmentTypeLabel: string;
  assessment_edit_carePlanIconLabel: string;
  assessment_edit_uploadImageLabel: string;
  assessment_edit_removeLabel: string;
  assessment_edit_saveLabel: string;
  assessment_edit_otherProvidersUseLabel: string;
  assessment_edit_makeMandatoryLabel: string;
  assessment_edit_notifyPatientsLabel: string;
  assessment_edit_notifyMeLabel: string;
  assessment_edit_questionTypeLabel: string;
  assessment_edit_addNewLabel: string;
  assessment_edit_questionLabel: string;

  master_data_typeLabel: string;
  master_data_descriptionLabel: string;

  widgets_qualityOfLifeLabel: string;
  widgets_pointsLabel: string;
  widgets_fromLastWeekLabel: string;

  widgets_caloriesIntakeLabel: string;
  widgets_kLabel: string;
  widgets_aDayLabel: string;
  widgets_aWeekLabel: string;

  widgets_dailyStepsLabel: string;
  widgets_dailyExerciseLabel: string;
  widgets_dailyStandLabel: string;
  widgets_stepsLabel: string;
  widgets_stepsCapitalLabel: string;
  widgets_ofLabel: string;
  widgets_takenLabel: string;
  widgets_hoursLabel: string;
  widgets_hoursCapitalLabel: string;
  widgets_doneLabel: string;
  widgets_kCalorieLabel: string;

  care_plan_progress_carePlanProgressLabel: string;
  care_plan_progress_completedLabel: string;
  care_plan_progress_missedLabel: string;
  care_plan_progress_tasksLabel: string;
  care_plan_progress_patientLabel: string;
  care_plan_progress_trendLabel: string;

  provider_dashboard_nameLabel: string;
  provider_dashboard_departmentLabel: string;
  provider_dashboard_specialtyLabel: string;
  provider_dashboard_patientsLabel: string;
  provider_dashboard_careplansLabel: string;
  provider_dashboard_reviewsLabel: string;
  provider_dashboard_providerListLabel: string;

  gantt_chart_byDays: string;
  gantt_chart_byWeeks: string;
  gantt_chart_byMonths: string;

  activity_feed_activity_feed_label: string;
  activity_feed_activity_label: string;

  protected type: LanguageType;

  protected constructor(json: any) {
    this.language_type = json.language_type;

    this.common_gender = json.common.gender;
    this.common_dateOffBirth = json.common.dateOfBirth;
    this.common_firstName = json.common.firstName;
    this.common_lastName = json.common.lastName;

    this.activity_card_allActivitiesLabel = json.activity_card.allActivitiesLabel;
    this.activity_card_shareLabel = json.activity_card.shareLabel;
    this.activity_card_sendLabel = json.activity_card.sendLabel;
    this.activity_card_cancelLabel = json.activity_card.cancelLabel;
    this.activity_card_agoLabel = json.activity_card.agoLabel;
    this.activity_card_acceptLabel = json.activity_card.acceptLabel;
    this.activity_card_declineLabel = json.activity_card.declineLabel;
    this.activity_card_commentLabel = json.activity_card.commentLabel;
    this.activity_card_messageCenter = json.activity_card.messageCenter;

    this.area_spline_complianceLabel = json.area_spline.complianceLabel;
    this.area_spline_interventionLabel = json.area_spline.interventionLabel;

    this.line_chart_engagementChartLabel = json.line_chart.engagementChartLabel;
    this.line_chart_engagementScoreLabel = json.line_chart.engagementScoreLabel;

    this.pie_chart_totalAppointmentsLabel = json.pie_chart.totalAppointmentsLabel;
    this.pie_chart_noAppointmentsLabel = json.pie_chart.noAppointmentsLabel;

    this.progress_pie_completedLabel = json.progress_pie.completedLabel;
    this.progress_pie_weeksLabel = json.progress_pie.weeksLabel;
    this.progress_pie_cyclesLabel = json.progress_pie.cyclesLabel;

    this.scatter_chart_femaleLabel = json.scatter_chart.femaleLabel;
    this.scatter_chart_maleLabel = json.scatter_chart.maleLabel;
    this.scatter_chart_unplannedEncountersLabel = json.scatter_chart.unplannedEncountersLabel;
    this.scatter_chart_dayLabel = json.scatter_chart.dayLabel;

    this.spider_web_chart_titleLabel = json.scatter_chart.titleLabel;
    this.spider_web_chart_allocatedBudgetLabel = json.scatter_chart.allocatedBudgetLabel;

    this.login_form_appTitle = json.login_form.appTitle;
    this.login_form_registerTitle = json.login_form.registerTitle;
    this.login_form_register = json.login_form.register;
    this.login_form_forgotPass = json.login_form.forgotPass;
    this.login_form_loginLabel = json.login_form.loginLabel;
    this.login_form_errorStrLabel = json.login_form.errorStrLabel;
    this.login_form_userNameLabel = json.login_form.userNameLabel;
    this.login_form_userPasswordLabel = json.login_form.userPasswordLabel;

    this.login_appTitleLabel = json.login.appTitleLabel;
    this.login_subtitleLabel = json.login.subtitleLabel;
    this.login_partnersTitleLabel = json.login.partnersTitleLabel;

    this.bio_card_todayLabel = json.bio_card.todayLabel;

    this.performance_team_lungCancerPlanLabel = json.performance_team.lungCancerPlanLabel;
    this.performance_team_engagementChartLabel = json.performance_team.engagementChartLabel;
    this.performance_team_yourTeamLabel = json.performance_team.yourTeamLabel;

    this.patient_appt_list_patientLabel = json.patient_appt_list.patientLabel;
    this.patient_appt_list_todaysTaskLabel = json.patient_appt_list.todaysTaskLabel;
    this.patient_appt_list_timeLabel = json.patient_appt_list.timeLabel;
    this.patient_appt_list_missingApptLabel = json.patient_appt_list.missingApptLabel;

    this.patient_bio_progressTitle = json.patient_bio.progressTitle;
    this.patient_bio_missingApptString = json.patient_bio.missingApptString;
    this.patient_bio_diagnoisLabel = json.patient_bio.diagnoisLabel;
    this.patient_bio_ageLabel = json.patient_bio.ageLabel;
    this.patient_bio_todaysTaskLabel = json.patient_bio.todaysTaskLabel;
    this.patient_bio_atSignLabel = json.patient_bio.atSignLabel;
    this.patient_bio_treatmentPlanLabel = json.patient_bio.treatmentPlanLabel;
    this.patient_bio_lungCancerPlanLabel = json.patient_bio.lungCancerPlanLabel;
    this.patient_bio_onTrackLabel = json.patient_bio.onTrackLabel;
    this.patient_bio_viewPatientOverviewLabel = json.patient_bio.viewPatientOverviewLabel;

    this.workload_todaysApptLabel = json.workload.todaysApptLabel;
    this.workload_appointmentsLabel = json.workload.appointmentsLabel;
    this.workload_nextPatientLabel = json.workload.nextPatientLabel;

    this.calendar_eventActionLabel = json.calendar.eventActionLabel;
    this.calendar_actionLabel = json.calendar.actionLabel;
    this.calendar_eventLabel = json.calendar.eventLabel;
    this.calendar_okLabel = json.calendar.okLabel;
    this.calendar_monthLabel = json.calendar.monthLabel;
    this.calendar_weekLabel = json.calendar.weekLabel;
    this.calendar_dayLabel = json.calendar.dayLabel;
    this.calendar_calendarDateLabel = json.calendar.calendarDateLabel;

    this.profile_dashboardLabel = json.profile.dashboardLabel;
    this.profile_appointmentLabel = json.profile.appointmentLabel;
    this.profile_providerLabel = json.profile.providerLabel;

    this.side_bar_providerLabel = json.side_bar.providerLabel;
    this.side_bar_patientListLabel = json.side_bar.patientListLabel;
    this.side_bar_carePlansLabel = json.side_bar.carePlansLabel;
    this.side_bar_assessments = json.side_bar.assessments;
    this.side_bar_organizations = json.side_bar.organizations;
    this.side_bar_master_data = json.side_bar.masterData;

    this.patients_allLabel = json.patients.allLabel;

    this.patients_dashboard_diagnosisFilterLabel = json.patients_dashboard.diagnosisFilterLabel;
    this.patients_dashboard_cancerTypeFilterLabel = json.patients_dashboard.cancerTypeFilterLabel;
    this.patients_dashboard_stageFilterLabel = json.patients_dashboard.stageFilterLabel;
    this.patients_dashboard_treatmentStatusFilterLabel = json.patients_dashboard.treatmentStatusFilterLabel;
    this.patients_dashboard_profileCompletenessFilterLabel = json.patients_dashboard.profileCompletenessFilterLabel;
    this.patients_dashboard_carePlanFilterLabel = json.patients_dashboard.carePlanFilterLabel;
    this.patients_dashboard_patientLabel = json.patients_dashboard.patientLabel;
    this.patients_dashboard_diagnosisLabel = json.patients_dashboard.diagnosisLabel;
    this.patients_dashboard_cancerTypeLabel = json.patients_dashboard.cancerTypeLabel;
    this.patients_dashboard_timeLineLabel = json.patients_dashboard.timeLineLabel;
    this.patients_dashboard_stageLabel = json.patients_dashboard.stageLabel;
    this.patients_dashboard_treatmentStatusLabel = json.patients_dashboard.treatmentStatusLabel;

    this.patients_dashboard_patientListLabel = json.patients_dashboard.patientListLabel;
    this.patients_dashboard_naLabel = json.patients_dashboard.naLabel;
    this.patients_dashboard_offTrackLabel = json.patients_dashboard.offTrackLabel;
    this.patients_dashboard_onTrackLabel = json.patients_dashboard.onTrackLabel;

    this.stats_totalPatientsLabel = json.stats.totalPatientsLabel;
    this.stats_totalCarePlansLabel = json.stats.totalCarePlansLabel;
    this.stats_unplannedLabel = json.stats.unplannedLabel;
    this.stats_offTrackLabel = json.stats.offTrackLabel;
    this.stats_availableTeamLabel = json.stats.availableTeamLabel;
    this.stats_numRescheduledLabel = json.stats.numRescheduledLabel;

    this.patient_activity_card_agoLabel = json.patient_activity_card.agoLabel;
    this.patient_activity_card_commentLabel = json.patient_activity_card.commentLabel;
    this.patient_activity_card_shareLabel = json.patient_activity_card.shareLabel;

    this.patient_careplan_manager_cyclesLabel = json.patient_careplan_manager.cyclesLabel;
    this.patient_careplan_manager_changePlanLabel = json.patient_careplan_manager.changePlanLabel;
    this.patient_careplan_manager_durationLabel = json.patient_careplan_manager.durationLabel;
    this.patient_careplan_manager_initiatePlanLabel = json.patient_careplan_manager.initiatePlanLabel;
    this.patient_careplan_manager_ownerLabel = json.patient_careplan_manager.ownerLabel;
    this.patient_careplan_manager_startDateLabel = json.patient_careplan_manager.startDateLabel;
    this.patient_careplan_manager_weeksLabel = json.patient_careplan_manager.weeksLabel;
    this.patient_careplan_manager_goalsLabel = json.patient_careplan_manager.goalsLabel;

    this.missing_plan_missingTitleLabel = json.missing_plan.missingTitleLabel;
    this.missing_plan_missingMessageLabel = json.missing_plan.missingMessageLabel;
    this.missing_plan_btnLabel = json.missing_plan.btnLabel;

    this.plan_details_filter_addNewTaskLabel = json.plan_details_filter.addNewTaskLabel;
    this.plan_details_filter_saveLabel = json.plan_details_filter.saveLabel;

    this.grid_of_plan_inHomeLabel = json.grid_of_plan.inHomeLabel;
    this.grid_of_plan_medicationLabel = json.grid_of_plan.medicationLabel;
    this.grid_of_plan_appointmentLabel = json.grid_of_plan.appointmentLabel;
    this.grid_of_plan_usedLabel = json.grid_of_plan.usedLabel;
    this.grid_of_plan_updatedLabel = json.grid_of_plan.updatedLabel;
    this.grid_of_plan_choosePlanLabel = json.grid_of_plan.choosePlanLabel;
    this.grid_of_plan_viewPreviewLabel = json.grid_of_plan.viewPreviewLabel;

    this.standard_care_plan_standardCpLabel = json.standard_care_plan.standardCpLabel;
    this.standard_care_plan_clinicalTrialLabel = json.standard_care_plan.clinicalTrialLabel;

    this.patient_appt_planning_tempDoctorLabel = json.patient_appt_planning.tempDoctorLabel;
    this.patient_appt_planning_apptDetailsLabel = json.patient_appt_planning.apptDetailsLabel;
    this.patient_appt_planning_apptListLabel = json.patient_appt_planning.apptListLabel;
    this.patient_appt_planning_noUpcomingApptsLabel = json.patient_appt_planning.noUpcomingApptsLabel;

    this.patient_planning_treatmentPlanLabel = json.patient_planning.treatmentPlanLabel;
    this.patient_planning_diagnosisLabel = json.patient_planning.diagnosisLabel;
    this.patient_planning_appointmentLabel = json.patient_planning.appointmentLabel;
    this.patient_planning_teamLabel = json.patient_planning.teamLabel;
    this.patient_planning_actionLogLabel = json.patient_planning.actionLogLabel;
    this.patient_planning_progressTitle = json.patient_planning.progressTitle;
    this.patient_planning_treatmentTitle = json.patient_planning.treatmentTitle;
    this.patient_planning_careTeamTitle = json.patient_planning.careTeamTitle;
    this.patient_planning_cancerTitle = json.patient_planning.cancerTitle;
    this.patient_planning_labOrderTitle = json.patient_planning.labOrderTitle;
    this.patient_planning_diagnosisTitle = json.patient_planning.diagnosisTitle;
    this.patient_planning_imageOrderTitle = json.patient_planning.imageOrderTitle;
    this.patient_planning_xRayLabel = json.patient_planning.xRayLabel;
    this.patient_planning_updatedLabel = json.patient_planning.updatedLabel;
    this.patient_planning_labResultsLabel = json.patient_planning.labResultsLabel;
    this.patient_planning_marginInfoLabel = json.patient_planning.marginInfoLabel;
    this.patient_planning_metastasisLabel = json.patient_planning.metastasisLabel;
    this.patient_planning_lymphNodesLabel = json.patient_planning.lymphNodesLabel;
    this.patient_planning_tumorSizeLabel = json.patient_planning.tumorSizeLabel;
    this.patient_planning_cancerTypeLabel = json.patient_planning.cancerTypeLabel;
    this.patient_planning_stageLabel = json.patient_planning.stageLabel;

    this.treatment_plan_share_careTeamTitleLabel = json.treatment_plan_share.careTeamTitleLabel;
    this.treatment_plan_share_staticQRLabel = json.treatment_plan_share.staticQRLabel;
    this.treatment_plan_share_printLabel = json.treatment_plan_share.printLabel;
    this.treatment_plan_share_sendToEmailLabel = json.treatment_plan_share.sendToEmailLabel;
    this.treatment_plan_share_shareSentenceLabel = json.treatment_plan_share.shareSentenceLabel;
    this.treatment_plan_share_recipientsLabel = json.treatment_plan_share.recipientsLabel;
    this.treatment_plan_share_shareLabel = json.treatment_plan_share.shareLabel;
    this.treatment_plan_chatBoxPlaceholderLabel = json.treatment_plan_share.chatBoxPlaceholderLabel;

    this.patient_overview_card_cancerType = json.patient_overview_card.cancerType;
    this.patient_overview_card_cancerStage = json.patient_overview_card.cancerStage;
    this.patient_overview_card_tumorSize = json.patient_overview_card.tumorSize;
    this.patient_overview_card_carcinomaLabel = json.patient_overview_card.carcinomaLabel;
    this.patient_overview_card_stageLabel = json.patient_overview_card.stageLabel;
    this.patient_overview_card_cancerTypeLabel = json.patient_overview_card.cancerTypeLabel;
    this.patient_overview_card_tumorSizeLabel = json.patient_overview_card.tumorSizeLabel;
    this.patient_overview_card_lymphNodesLabel = json.patient_overview_card.lymphNodesLabel;
    this.patient_overview_card_metastasisLabel = json.patient_overview_card.metastasisLabel;
    this.patient_overview_card_marginInfoLabel = json.patient_overview_card.marginInfoLabel;
    this.patient_overview_card_patientInfoLabel = json.patient_overview_card.patientInfoLabel;
    this.patient_overview_card_ageLabel = json.patient_overview_card.ageLabel;
    this.patient_overview_card_ethnicityLabel = json.patient_overview_card.ethnicityLabel;
    this.patient_overview_card_phoneNumberLabel = json.patient_overview_card.phoneNumberLabel;
    this.patient_overview_card_emailLabel = json.patient_overview_card.emailLabel;
    this.patient_overview_card_addressLabel = json.patient_overview_card.addressLabel;

    this.patient_profile_overviewLabel = json.patient_profile.overviewLabel;
    this.patient_profile_dashboardLabel = json.patient_profile.dashboardLabel;
    this.patient_profile_ehrLabel = json.patient_profile.ehrLabel;

    this.patient_note_titleLabel = json.patient_note.titleLabel;
    this.patient_note_newTitlePlaceholderLabel = json.patient_note.newTitlePlaceholderLabel;
    this.patient_note_newNotePlaceholderLabel = json.patient_note.newNotePlaceholderLabel;
    this.patient_note_cancelButtonLabel = json.patient_note.cancelButtonLabel;
    this.patient_note_saveButtonLabel = json.patient_note.saveButtonLabel;
    this.patient_note_privateLabel = json.patient_note.privateLabel;
    this.patient_note_publicLabel = json.patient_note.publicLabel;
    this.patient_note_allLabel = json.patient_note.allLabel;
    this.patient_note_byMeLabel = json.patient_note.byMeLabel;
    this.patient_note_seeAllLabel = json.patient_note.seeAllLabel;
    this.patient_note_searchByProviderLabel = json.patient_note.searchByProviderLabel;
    this.patient_note_createNoteLabel = json.patient_note.createNoteLabel;
    this.patient_note_creatorLabel = json.patient_note.creatorLabel;
    this.patient_note_dateLabel = json.patient_note.dateLabel;
    this.patient_note_onlyOrgProvidersLabel = json.patient_note.onlyOrgProvidersLabel;

    this.task_list_therapiesLabel = json.task_list.therapiesLabel;
    this.task_list_inHomeActivitiesLabel = json.task_list.inHomeActivitiesLabel;
    this.task_list_emptyTherapyTaskListLabel = json.task_list.emptyTherapyTaskListLabel;
    this.task_list_emptyInHomeTaskListLabel = json.task_list.emptyInHomeTaskListLabel;
    this.task_list_serviceTypeLabel = json.task_list.serviceTypeLabel;
    this.task_list_therapyTypeLabel = json.task_list.therapyTypeLabel;
    this.task_list_locationTypeLabel = json.task_list.locationTypeLabel;
    this.task_list_providerLabel = json.task_list.providerLabel;
    this.task_list_numOfTimesLabel = json.task_list.numOfTimesLabel;

    this.care_plan_overviewLabel = json.care_plan.overviewLabel;
    this.care_plan_observationsLabel = json.care_plan.observationsLabel;
    this.care_plan_analyticsLabel = json.care_plan.analyticsLabel;

    this.progress_titleLabel = json.progress.titleLabel;
    this.progress_compliancyTitleLabel = json.progress.compliancyTitleLabel;
    this.progress_unplannedEncounterLabel = json.progress.unplannedEncounterLabel;
    this.progress_totalLabel = json.progress.totalLabel;
    this.progress_percentSuccessLabel = json.progress.percentSuccessLabel;
    this.progress_title = json.progress.title;
    this.progress_compliancyTitle = json.progress.compliancyTitle;
    this.progress_complianceyViewLabel = json.progress.complianceyViewLabel;
    this.progress_patientViewLabel = json.progress.patientViewLabel;
    this.progress_numTreatmentTypesLabel = json.progress.numTreatmentTypesLabel;
    this.progress_dayNumLabel = json.progress.dayNumLabel;
    this.progress_numOfftrackPatientsLabel = json.progress.numOfftrackPatientsLabel;
    this.progress_numUnplannedEncountersLabel = json.progress.numUnplannedEncountersLabel;
    this.progress_numPatients = json.progress.numPatients;
    this.progress_numPatientGoalsLabel = json.progress.numPatientGoalsLabel;
    this.progress_numMedicationsLabel = json.progress.numMedicationsLabel;
    this.progress_numTreatmentTypesDescLabel = json.progress.numTreatmentTypesDescLabel;
    this.progress_dayNumDescLabel = json.progress.dayNumDescLabel;
    this.progress_numOfftrackPatientsDescLabel = json.progress.numOfftrackPatientsDescLabel;
    this.progress_numUnplannedEncountersDescLabel = json.progress.numUnplannedEncountersDescLabel;
    this.progress_numPatientsDescLabel = json.progress.numPatientsDescLabel;
    this.progress_numPatientGoalsDescLabel = json.progress.numPatientGoalsDescLabel;
    this.progress_numMedicationsDescLabel = json.progress.numMedicationsDescLabel;

    this.careplan_overview_patientLabel = json.careplan_overview.patientLabel;
    this.careplan_overview_allLabel = json.careplan_overview.allLabel;
    this.careplan_overview_unplannedLabel = json.careplan_overview.unplannedLabel;
    this.careplan_overview_offTrackLabel = json.careplan_overview.offTrackLabel;

    this.careplan_create_create_new_care_plan = json.careplan_create.create_new_care_plan;

    this.add_new_task_addNewTaskLabel = json.add_new_task.addNewTaskLabel;
    this.add_new_task_addLabel = json.add_new_task.addLabel;
    this.add_new_task_selectTaskTypeLabel = json.add_new_task.selectTaskTypeLabel;
    this.add_new_task_selectServiceTypeLabel = json.add_new_task.selectServiceTypeLabel;
    this.add_new_task_selectTherapyTypeLabel = json.add_new_task.selectTherapyTypeLabel;
    this.add_new_task_selectLocationLabel = json.add_new_task.selectLocationLabel;
    this.add_new_task_selectProviderLabel = json.add_new_task.selectProviderLabel;
    this.add_new_task_countLabel = json.add_new_task.countLabel;

    this.task_row_selectServiceLabel = json.task_row.selectServiceLabel;
    this.task_row_selectTherapyLabel = json.task_row.selectTherapyLabel;
    this.task_row_selectLocationLabel = json.task_row.selectLocationLabel;
    this.task_row_selectProviderLabel = json.task_row.selectProviderLabel;
    this.task_row_weekLabel = json.task_row.weekLabel;
    this.task_row_addressLabel = json.task_row.addressLabel;
    this.task_row_dateLabel = json.task_row.dateLabel;
    this.task_row_timeLabel = json.task_row.timeLabel;
    this.task_row_addNewLabel = json.task_row.addNewLabel;
    this.task_row_successMsg = json.task_row.successMsg;
    this.task_row_errorMsg = json.task_row.errorMsg;
    this.task_row_missingMsg = json.task_row.missingMsg;
    this.task_row_confirmLabel = json.task_row.confirmLabel;
    this.task_row_cancelLabel = json.task_row.cancelLabel;
    this.task_row_deleteTaskMsg = json.task_row.deleteTaskMsg;

    this.careplan_dashboard_careplansLabel = json.careplan_dashboard.careplansLabel;
    this.careplan_dashboard_carePlanNameLabel = json.careplan_dashboard.carePlanNameLabel;
    this.careplan_dashboard_carePlanTypeLabel = json.careplan_dashboard.carePlanTypeLabel;
    this.careplan_dashboard_complianceScoreLabel = json.careplan_dashboard.complianceScoreLabel;
    this.careplan_dashboard_enrolledPatientsLabel = json.careplan_dashboard.enrolledPatientsLabel;
    this.careplan_dashboard_unplannedEncountersLabel = json.careplan_dashboard.unplannedEncountersLabel;
    this.careplan_dashboard_planGoalsLabel = json.careplan_dashboard.planGoalsLabel;
    this.careplan_dashboard_planApptsLabel = json.careplan_dashboard.planApptsLabel;

    this.patient_list_diagnosisLabel = json.patient_list.diagnosisLabel;
    this.patient_list_cancerTypeLabel = json.patient_list.cancerTypeLabel;
    this.patient_list_stageLabel = json.patient_list.stageLabel;
    this.patient_list_treatmentStatusLabel = json.patient_list.treatmentStatusLabel;

    this.filter_bar_resetFilterLabel = json.filter_bar.resetFilterLabel;
    this.filter_bar_filterTitleLabel = json.filter_bar.filterTitleLabel;

    this.calendar_onboard_title = json.calendar_onboard.title;
    this.calendar_onboard_startTodayMsg = json.calendar_onboard.startTodayMsg;
    this.calendar_onboard_setStartMsg = json.calendar_onboard.setStartMsg;
    this.calendar_onboard_todayBtnLabel = json.calendar_onboard.todayBtnLabel;
    this.calendar_onboard_startOnLabel = json.calendar_onboard.startOnLabel;

    this.care_plan_summary_lungCancerPlanLabel = json.care_plan_summary.lungCancerPlanLabel;
    this.care_plan_summary_treatmentsLabel = json.care_plan_summary.treatmentsLabel;
    this.care_plan_summary_appointmentsLabel = json.care_plan_summary.appointmentsLabel;
    this.care_plan_summary_weekLabel = json.care_plan_summary.weekLabel;
    this.care_plan_summary_completeLabel = json.care_plan_summary.completeLabel;
    this.care_plan_summary_backButtonLabel = json.care_plan_summary.backButtonLabel;
    this.care_plan_summary_numInHomeActivitiesLabel = json.care_plan_summary.numInHomeActivitiesLabel;
    this.care_plan_summary_numAppointmentsLabel = json.care_plan_summary.numAppointmentsLabel;
    this.care_plan_summary_totalDurationWeeksLabel = json.care_plan_summary.totalDurationWeeksLabel;
    this.care_plan_summary_numCyclesLabel = json.care_plan_summary.numCyclesLabel;

    this.qr_code_qrCodeTitleLabel = json.qr_code.qrCodeTitleLabel;
    this.qr_code_printOutLabel = json.qr_code.printOutLabel;
    this.qr_code_sendToEmailLabel = json.qr_code.sendToEmailLabel;
    this.qr_code_shareButtonLabel = json.qr_code.shareButtonLabel;
    this.qr_code_enterEmailLabel = json.qr_code.enterEmailLabel;

    this.under_construction_underConstructionLabel = json.under_construction.underConstructionLabel;
    this.under_construction_newStuffLabel = json.under_construction.newStuffLabel;

    this.org_dashboard_organizationLabel = json.org_dashboard.organizationLabel;
    this.org_dashboard_organizationListLabel = json.org_dashboard.organizationListLabel;
    this.org_dashboard_addressLabel = json.org_dashboard.addressLabel;
    this.org_dashboard_complianceScoreLabel = json.org_dashboard.complianceScoreLabel;
    this.org_dashboard_providersLabel = json.org_dashboard.providersLabel;
    this.org_dashboard_patientsLabel = json.org_dashboard.patientsLabel;
    this.org_dashboard_allLabel = json.org_dashboard.allLabel;

    this.org_provider_list_reviewsLabel = json.org_provider_list.reviewsLabel;
    this.org_provider_list_totalLabel = json.org_provider_list.totalLabel;
    this.org_provider_list_engagementScoreLabel = json.org_provider_list.engagementScoreLabel;
    this.org_provider_list_patientsLabel = json.org_provider_list.patientsLabel;
    this.org_provider_list_carePlansLabel = json.org_provider_list.carePlansLabel;
    this.org_provider_list_providerLabel = json.org_provider_list.providerLabel;
    this.org_provider_list_departmentLabel = json.org_provider_list.departmentLabel;
    this.org_provider_list_specialtiesLabel = json.org_provider_list.specialtiesLabel;

    this.org_branch_list_organizationLabel = json.org_branch_list.organizationLabel;
    this.org_branch_list_addressLabel = json.org_branch_list.addressLabel;
    this.org_branch_list_complianceScoreLabel = json.org_branch_list.complianceScoreLabel;
    this.org_branch_list_providersLabel = json.org_branch_list.providersLabel;
    this.org_branch_list_patientsLabel = json.org_branch_list.patientsLabel;

    this.org_onboard_accountInfoLabel = json.org_onboard.accountInfoLabel;

    this.org_edit_organizationDetailsLabel = json.org_edit.organizationDetailsLabel;
    this.org_edit_addressDetailsLabel = json.org_edit.addressDetailsLabel;
    this.org_edit_orgPicLabel = json.org_edit.orgPicLabel;
    this.org_edit_orgNameLabel = json.org_edit.orgNameLabel;
    this.org_edit_taskIdNumberLabel = json.org_edit.taskIdNumberLabel;
    this.org_edit_taskIdNumberRequiredLabel = json.org_edit.taskIdNumberRequiredLabel;
    this.org_edit_businessLicenseNumberLabel = json.org_edit.businessLicenseNumberLabel;
    this.org_edit_businessLicenseNumberRequiredLabel = json.org_edit.businessLicenseNumberRequiredLabel;
    this.org_edit_countryLabel = json.org_edit.countryLabel;
    this.org_edit_orgUnderLawLabel = json.org_edit.orgUnderLawLabel;
    this.org_edit_departmentsLabel = json.org_edit.departmentsLabel;
    this.org_edit_addressTypeLabel = json.org_edit.addressTypeLabel;
    this.org_edit_address1Label = json.org_edit.address1Label;
    this.org_edit_address2Label = json.org_edit.address2Label;
    this.org_edit_cityLabel = json.org_edit.cityLabel;
    this.org_edit_zipcodeLabel = json.org_edit.zipcodeLabel;
    this.org_edit_tempHeadquarterLabel = json.org_edit.tempHeadquarterLabel;
    this.org_edit_submitBtnLabel = json.org_edit.submitBtnLabel;
    this.org_edit_createBtnLabel = json.org_edit.createBtnLabel;
    this.org_edit_saveBtnLabel = json.org_edit.saveBtnLabel;
    this.org_edit_saveAlertSuccess = json.org_edit.saveAlertSuccess;
    this.org_edit_saveAlertFail = json.org_edit.saveAlertFail;
    this.org_edit_uploadImgLabel = json.org_edit.uploadImgLabel;
    this.org_edit_deptDropdownPlaceholderLabel = json.org_edit.deptDropdownPlaceholderLabel;
    this.org_edit_mapErrorMessage = json.org_edit.mapErrorMessage;

    this.user_option_accountCompletenessLabel = json.user_option.accountCompletenessLabel;
    this.user_option_accountInstructionLabel = json.user_option.accountInstructionLabel;
    this.user_option_emailAddressLabel = json.user_option.emailAddressLabel;
    this.user_option_phoneNumberLabel = json.user_option.phoneNumberLabel;
    this.user_option_emailConfirmationLabel = json.user_option.emailConfirmationLabel;
    this.user_option_passwordLabel = json.user_option.passwordLabel;
    this.user_option_accountSettingLabel = json.user_option.accountSettingLabel;
    this.user_option_logOutLabel = json.user_option.logOutLabel;

    this.assessments_dashboard_allLabel = json.assessments_dashboard.allLabel;
    this.assessments_dashboard_assessmentOwnerLabel = json.assessments_dashboard.assessmentOwnerLabel;
    this.assessments_dashboard_assessmentNameLabel = json.assessments_dashboard.assessmentNameLabel;
    this.assessments_dashboard_assessmentTypeLabel = json.assessments_dashboard.assessmentTypeLabel;
    this.assessments_dashboard_ownerLabel = json.assessments_dashboard.ownerLabel;
    this.assessments_dashboard_lastEditedLabel = json.assessments_dashboard.lastEditedLabel;
    this.assessments_dashboard_numberOfQuestionsLabel = json.assessments_dashboard.numberOfQuestionsLabel;
    this.assessments_dashboard_numberOfPatientsLabel = json.assessments_dashboard.numberOfPatientsLabel;

    this.assessment_dashboardLabel = json.assessment.dashboardLabel;

    this.assessment_overview_performanceLabel = json.assessment_overview.performanceLabel;
    this.assessment_overview_patientsAnsweredLabel = json.assessment_overview.patientsAnsweredLabel;
    this.assessment_overview_malePatientsLabel = json.assessment_overview.malePatientsLabel;
    this.assessment_overview_femalePatientsLabel = json.assessment_overview.femalePatientsLabel;

    this.assessment_overview_questionnaireLabel = json.assessment_overview.questionnaireLabel;
    this.assessment_overview_questionNumberLabel = json.assessment_overview.questionNumberLabel;
    this.assessment_overview_enterQuestionLabel = json.assessment_overview.enterQuestionLabel;
    this.assessment_overview_duplicateLabel = json.assessment_overview.duplicateLabel;
    this.assessment_overview_deleteLabel = json.assessment_overview.deleteLabel;
    this.assessment_overview_answerPlaceholderLabel = json.assessment_overview.answerPlaceholderLabel;
    this.assessment_overview_gradePlaceholderLabel = json.assessment_overview.gradePlaceholderLabel;
    this.assessment_overview_descriptionPlaceholderLabel = json.assessment_overview.descriptionPlaceholderLabel;
    this.assessment_overview_trueLabel = json.assessment_overview.trueLabel;
    this.assessment_overview_falseLabel = json.assessment_overview.falseLabel;

    this.assessment_edit_generalInfoLabel = json.assessment_edit.generalInfoLabel;
    this.assessment_edit_createAssessmentLabel = json.assessment_edit.createAssessmentLabel;
    this.assessment_edit_backButtonLabel = json.assessment_edit.backButtonLabel;
    this.assessment_edit_completeButtonLabel = json.assessment_edit.completeButtonLabel;
    this.assessment_edit_startButtonLabel = json.assessment_edit.startButtonLabel;
    this.assessment_edit_enterAssessmentNameLabel = json.assessment_edit.enterAssessmentNameLabel;
    this.assessment_edit_settingsLabel = json.assessment_edit.settingsLabel;
    this.assessment_edit_assessmentNameLabel = json.assessment_edit.assessmentNameLabel;
    this.assessment_edit_assessmentTypeLabel = json.assessment_edit.assessmentTypeLabel;
    this.assessment_edit_carePlanIconLabel = json.assessment_edit.carePlanIconLabel;
    this.assessment_edit_uploadImageLabel = json.assessment_edit.uploadImageLabel;
    this.assessment_edit_removeLabel = json.assessment_edit.removeLabel;
    this.assessment_edit_saveLabel = json.assessment_edit.saveLabel;
    this.assessment_edit_otherProvidersUseLabel = json.assessment_edit.otherProvidersUseLabel;
    this.assessment_edit_makeMandatoryLabel = json.assessment_edit.makeMandatoryLabel;
    this.assessment_edit_notifyPatientsLabel = json.assessment_edit.notifyPatientsLabel;
    this.assessment_edit_notifyMeLabel = json.assessment_edit.notifyMeLabel;
    this.assessment_edit_questionTypeLabel = json.assessment_edit.questionTypeLabel;
    this.assessment_edit_addNewLabel = json.assessment_edit.addNewLabel;
    this.assessment_edit_questionLabel = json.assessment_edit.questionLabel;

    this.master_data_typeLabel = json.master_data.typeLabel;
    this.master_data_descriptionLabel = json.master_data.descriptionLabel;

    this.widgets_qualityOfLifeLabel = json.widgets.qualityOfLifeLabel;
    this.widgets_pointsLabel = json.widgets.pointsLabel;
    this.widgets_fromLastWeekLabel = json.widgets.fromLastWeekLabel;

    this.widgets_caloriesIntakeLabel = json.widgets.caloriesIntakeLabel;
    this.widgets_kLabel = json.widgets.kLabel;
    this.widgets_aDayLabel = json.widgets.aDayLabel;
    this.widgets_aWeekLabel = json.widgets.aWeekLabel;

    this.widgets_dailyStepsLabel = json.widgets.dailyStepsLabel;
    this.widgets_dailyExerciseLabel = json.widgets.dailyExerciseLabel;
    this.widgets_dailyStandLabel = json.widgets.dailyStandLabel;
    this.widgets_stepsLabel = json.widgets.stepsLabel;
    this.widgets_stepsCapitalLabel = json.widgets.stepsCapitalLabel;
    this.widgets_ofLabel = json.widgets.ofLabel;
    this.widgets_takenLabel = json.widgets.takenLabel;
    this.widgets_hoursLabel = json.widgets.hoursLabel;
    this.widgets_hoursCapitalLabel = json.widgets.hoursCapitalLabel;
    this.widgets_doneLabel = json.widgets.doneLabel;
    this.widgets_kCalorieLabel = json.widgets.kCalorieLabel;

    this.care_plan_progress_carePlanProgressLabel = json.care_plan_progress.carePlanProgressLabel;
    this.care_plan_progress_completedLabel = json.care_plan_progress.completedLabel;
    this.care_plan_progress_missedLabel = json.care_plan_progress.missedLabel;
    this.care_plan_progress_tasksLabel = json.care_plan_progress.tasksLabel;
    this.care_plan_progress_patientLabel = json.care_plan_progress.patientLabel;
    this.care_plan_progress_trendLabel = json.care_plan_progress.trendLabel;

    this.provider_dashboard_nameLabel = json.provider_dashboard.nameLabel;
    this.provider_dashboard_departmentLabel = json.provider_dashboard.departmentLabel;
    this.provider_dashboard_specialtyLabel = json.provider_dashboard.specialtyLabel;
    this.provider_dashboard_patientsLabel = json.provider_dashboard.patientsLabel;
    this.provider_dashboard_careplansLabel = json.provider_dashboard.careplansLabel;
    this.provider_dashboard_reviewsLabel = json.provider_dashboard.reviewsLabel;
    this.provider_dashboard_providerListLabel = json.provider_dashboard.providerListLabel;

    this.gantt_chart_byDays = json.gantt_chart.byDays;
    this.gantt_chart_byWeeks = json.gantt_chart.byWeeks;
    this.gantt_chart_byMonths = json.gantt_chart.byMonths;

    this.activity_feed_activity_feed_label = json.activity_feed.activityFeedLabel;
    this.activity_feed_activity_label = json.activity_feed.activityLabel;
  }

  public getLanguageType() {
    return this.type;
  }
}
